import React, { Component } from 'react'
import ErrorBoundary from '../UI-helper-components/Error-boundary'

const ErrorHandlerHOC = (ChildComponent) => {
  return class ErrorHandler extends React.Component {
    render () {
      return (
        <ErrorBoundary>
          <ChildComponent {...this.props} />
        </ErrorBoundary>
      )
    }
  }
}

export default ErrorHandlerHOC
