import axios from "axios";
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      // window.location.replace('/')
      console.error("Unauthorized", err);
      return Promise.reject(err);
    } else {
      return Promise.reject(err);
    }
  }
);

export default axios;