import axios from './axios'
import endpoints from '../config/endpoints'

export default async (patientId, imageFile, token) => {
  const imageUploadFormData = new FormData()
  imageUploadFormData.set('file', imageFile)
  const config = {
    headers: {
      token: token,
      patientId: patientId
    }
  }

  try {
    const response = await axios.post(endpoints.UPLOAD_CHAT_IMAGE, imageUploadFormData, config)
    return response
  } catch (error) {
    throw new Error(error)
  }
}
