let config = {  
  baseRoute: "/",
  ECZEMALESS_API_BASE_URL: "https://www.docapi.eczemaless.com/doc-api",
  PSORIASIS_API_BASE_URL: "https://docapi.beat-psoriasis.com/doc-api",
  ECZEMALESS_APP: "EczemaLess",
  PSORIASIS_APP: "Psoriasis",
  IDLE_TIME_OUT: 1000 * 60 * 5,
};

// if(window.location.hostname === 'localhost') {
//   config.API_ROOT = 'http://localhost:4000/doc-api'
// }

export default config;