import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ListGroup, ListGroupItem, Collapse } from 'reactstrap'
import SubCategoriesList from './Sub-category-list'
import { unconnectedAwareQuery } from './utils'
import Select from './Select-creatable'
import { create } from 'lodash'
import getProp from '../User/User-details-components/Utils/get'

const createOption = (label) => ({
  label: label,
  value: label
})

class Category extends Component {

  state = {
    isCollapseOpen: true,
    editTriggersRerenderKey: 1,
    categoryInstructions: [],
    instructionsLoading: false,
    selectedInstruction: undefined
  }

  constructor (props) {
    super(props)
  }

  load = async () => {
    try {
      if (this.props.type !== 'activities') return
      if (typeof this.props.getCategoryInstruction !== 'function') return
      const query = {
        token: this.props.token,
        userid: this.props.userId,
        careCategoryId: this.props.careCategoryId,
        treatmentPlan: this.props.treatmentPlan
      }
      const queryObj = unconnectedAwareQuery(query, this.props.mode, this.props.userEmail)
      const response = await this.props.getCategoryInstruction(queryObj)
      const instruction = getProp(response, 'data.data', {})
      if (instruction) {
        let categoryInstructions = []
        const isAlreadyThere = this.state.categoryInstructions.filter(instructionOption => instructionOption.value === instruction.instruction)[0]
        if (isAlreadyThere) {
          categoryInstructions = this.state.categoryInstructions
        } else {
          categoryInstructions = [...this.state.categoryInstructions, createOption(instruction.instruction)]
        }
        const instructionVal = instruction.instruction? instruction.instruction : undefined
        this.setState(() => ({
          selectedInstruction: instructionVal? createOption(instructionVal) : undefined,
          categoryInstructions: categoryInstructions.filter(instruction => instruction && instruction.instruction)
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  toggleCollapse = () => {
    this.setState(() => ({ isCollapseOpen: !this.state.isCollapseOpen }))
  }

  setCategoryInstructions = async (instructions) => {
    await this.load()
    this.setState(() => ({
      categoryInstructions: instructions.map(createOption)
    }))
  }

  addTriggerHandler = (e) => {
    // mode='unconnected'
    // careActivitiesListAPI={ getCareActivitiesListByUserUnconnected }
    // userIdentifierHeader='userEmail'
    // userIdentifierParam='userEmail'
    // postCareActivitiesListAPI

    e.stopPropagation()
    let triggerLabel = window.prompt('Please enter the activity name')
    if(!triggerLabel) return

    let queryObj = {
      token: this.props.token,
      userid: this.props.userId,
      careActivityLabel: triggerLabel,
      careCategoryId: this.props.careCategoryId,
      treatmentPlan: this.props.treatmentPlan
    }
    let queryObjUnconnectedAware = unconnectedAwareQuery(queryObj, this.props.mode, this.props.userEmail)
    this.props.postCareActivitiesListAPI(queryObjUnconnectedAware)
      .then(res => {
        this.setState(() => ({
          isCollapseOpen: true,
          editTriggersRerenderKey: Math.random()
        }))
      })
      .catch(err => {
        alert('Error. Please retry.')
        console.log(err)
      })
  }

  handleChange = (newValue, actionMeta ) => {
    this.setState({ selectedInstruction: newValue });
    if(newValue) {
      this.uploadChange(newValue.value)
    } else {
      this.uploadChange(null)
    }
  }

  handleCreate = (inputValue) => {
    this.setState({ instructionsLoading: true });
    const options = this.state.categoryInstructions;
    const newOption = createOption(inputValue);
    this.setState({
      instructionsLoading: false,
      categoryInstructions: [...options, newOption],
      selectedInstruction: newOption,
    })
    this.uploadChange(inputValue)
  }

  uploadChange = (instruction) => {
    const obj = {
      careCategoryId: this.props.careCategoryId,
      token: this.props.token,
      treatmentPlan: this.props.treatmentPlan,
      userid: this.props.userId,
      instruction: instruction
    }
    const uAwareObj = unconnectedAwareQuery(obj, this.props.mode, this.props.userEmail)
    this.props.updateCategoryInstruction(uAwareObj)
  }

  headingClickHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  render () {
    return (
      <div className='tracker-category'>
        <ListGroup>
          <ListGroupItem className='tibot-list-group-heading' style={{ cursor: 'pointer' }} onClick={this.toggleCollapse}>
            <strong>{this.props.heading}</strong>
            {this.props.type === 'activities' && <div className='category-row-dropdown' onClick={this.headingClickHandler}>
              <Select
                isLoading={this.state.instructionsLoading}
                placeholder={`Instructions about ${this.props.heading}`}
                options={this.state.categoryInstructions}
                value={this.state.selectedInstruction}
                onChange={this.handleChange}
                handleCreate={this.handleCreate}
              />
            </div>}
            <span className='list-group-arrow'>
              <i onClick={this.addTriggerHandler} className="fa fa-plus" style={{ marginRight: '15px'}} aria-hidden="true"></i>
              {this.state.isCollapseOpen ? <i className='fa fa-arrow-up' aria-hidden='true' /> : <i className='fa fa-arrow-down' aria-hidden='true' />}
            </span>
          </ListGroupItem>
          <Collapse className='collapse-wrapper' isOpen={this.state.isCollapseOpen}>
            <SubCategoriesList
              {...this.props}
              key={this.state.editTriggersRerenderKey}
              setCategoryInstructions={this.setCategoryInstructions}
            />
          </Collapse>
        </ListGroup>
      </div>
    )
  }
}

Category.propTypes = {
  heading: PropTypes.string,
  careCategoryId: PropTypes.string,
  editTriggers: PropTypes.bool,
  suggestedIdsByDoctor: PropTypes.array
}

export default Category
