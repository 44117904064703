import React, { useEffect, useState } from 'react'
import { getInsightsSummary } from '../../../Api/History-and-insights/history-and-insights-apis'
import './insight-cards.scss'
interface CardProps {
    title: string
    subTitle: string
    totleInfo: string
    icon: any,
    backgroundColor: string
}

const Card = (props: CardProps) => {
    return (
        <div className="insight-card" style={{
            backgroundColor: props.backgroundColor
        }}>
            <div className="insight-card-left">
                <div className="insight-card__title">{props.title}</div>
                <div className="insight-card__subtitle">{props.subTitle}</div>
                <div className="insight-card__totalInfo">{props.totleInfo}</div>
            </div>
            <div className="insight-card-right">
               <div className="insight-card__icon">{props.icon}</div>
            </div>
        </div>
    )
}

interface InsightCardsProps {
    token: string
    userid: string
}

interface InsightObj {
    adherence?: any
    effectiveness?: any
}

const insightInitState: InsightObj = {}

export const InsightCards = (props: InsightCardsProps) => {
    const [loading, setLoading] = useState(true)
    let [insightObj, setInsightObj] =  useState(insightInitState)
    useEffect(() => {
        getInsightsSummary(props.userid, props.token)
            .then(res => {
                setInsightObj(res.data.data)
                setLoading(false)
            })
    }, [])
    if (loading) {
        return null
    }
    return (
        <div className='insight-cards'>
            <Card
                title={`${insightObj.adherence.percent}%`}
                subTitle='Adherence'
                totleInfo={`Total logs: ${insightObj.adherence.totalLogged}`}
                icon={(
                    <i className="fa fa-address-book" aria-hidden="true"></i>
                )}
                backgroundColor='#006acb'
            />
            <Card
                title={`${insightObj.effectiveness.clearDays} days clear`}
                subTitle='Effectiveness'
                totleInfo={`Total Days: ${insightObj.effectiveness.totalDays}`}
                icon={(
                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                )}
                backgroundColor='#653aab'
            />
        </div>
    )
}

