import React from 'react'
import { useSelector } from 'react-redux'
import { check } from '../../config/roles'

const Can = props => {
  const userRole = useSelector((state) => {
    return state.authReducer.userRole
  })
  return check(userRole, props.action, props.data)
    ? props.yes()
    : props.no()
}

Can.defaultProps = {
  yes: () => null,
  no: () => null
}

export default Can
