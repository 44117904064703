import endpoints from '../../config/endpoints'
import axios from "../axios";

export const getCaseHistory = async (userid, token, dateFrom, dateTo, axis) => {
    const headers = {
        headers: {
            userid,
            token,
            dateFrom,
            dateTo,
            axis
        }
    }
    try {
        const response = await axios.get(endpoints.CASE_HISTORY, headers)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getWeatherHistory = async (userid, token, dateFrom, dateTo) => {
    const headers = {
        headers: {
            userid,
            token,
            dateFrom,
            dateTo
        }
    }
    try {
        const response = await axios.get(endpoints.WEATHER_HISTORY, headers)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getCareActivitiesHistory = async (userid, token, dateFrom, dateTo) => {
    const headers = {
        headers: {
            userid,
            token,
            dateFrom,
            dateTo,
        }
    }
    try {
        const response = await axios.get(endpoints.CARE_ACTIVITIES_HISTORY, headers)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getTriggerHistory = async (userid, token, dateFrom, dateTo) => {
    const headers = {
        headers: {
            userid,
            token,
            dateFrom,
            dateTo
        }
    }
    try {
        const response = await axios.get(endpoints.TRIGGER_HISTORY, headers)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getInsightsSummary = async (userid, token) => {
    const headers = {
        headers: {
            userid,
            token,
        }
    }
    try {
        const response = await axios.get(endpoints.INSIHGT_SUMMARY, headers)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}
