import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import caseDetailsById from "../../Api/get-case-details-by-id";
import loadingGif from "../../assets/loading.gif";
import endpoints from "../../config/endpoints";
import imageIdGenerator from "../../utilityFunctions/image-id-generator";
import ReactAuthImageZoom from "../UI-helper-components/react-auth-image-zoom";
import getProp from "./User-details-components/Utils/get";

class UserSingleCase extends Component {
  state = {
    caseDetails: {},
    isLoaded: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const caseDetails = await caseDetailsById(
        this.props.match.params.caseId,
        this.props.state.authReducer.token
      );
      this.setState(() => ({
        caseDetails: caseDetails.data.data,
        isLoaded: true,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  viewCaseArea = () => {
    let theCase = this.state.caseDetails;
    let discomforts =
      getProp(this.state, "caseDetails.finalReplyJson.discomfort") || [];
    let imageId = this.state.caseDetails.imagePath.reverse()[0];
    let imageIdHeader =
      imageId && imageId["path"] && imageIdGenerator(imageId["path"]);
    return (
      <div className="user-case-area">
        <div className="image-box-wrapper">
          <ReactAuthImageZoom
            targetUrl={endpoints.GET_IMAGE}
            headers={{
              imageId: imageIdHeader,
              token: this.props.state.authReducer.token,
              caseId: this.props.match.params.caseId,
            }}
            defaultImage={loadingGif}
            alt="test"
          />
        </div>
        <div className="user-case-date">
          <h2>{moment(theCase.date).format("LL")}</h2>
        </div>
        <div className="user-case-result-wrapper">
          <div className="case-result">
            <span>Result: </span> {getProp(theCase, "finalPredictions.result")}
          </div>
          <div className="case-probability">
            <span>Probability: </span>{" "}
            {getProp(theCase, "finalPredictions.probability")}
          </div>
          <div className="case-label">
            <span>Label: </span> {getProp(theCase, "finalPredictions.label")}
          </div>
          <div className="case-qol">
            <span>QoL Score: </span> {getProp(theCase, "poemScore")}
          </div>
        </div>
        <div className="user-case-discomfort-wrapper"></div>
        <h4>Discomfort</h4>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Value</th>
              <th scope="col">Label</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(discomforts).map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{item}</th>
                  <td>{discomforts[item].value}</td>
                  <td>{discomforts[item].label}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {this.state.isLoaded ? (
              this.viewCaseArea()
            ) : (
              <div className="square-spinner"></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(withRouter(UserSingleCase));