import axios from 'axios'

const availableMethods = ['get', 'post', 'delete']

export const makeCall = async ({
  url,
  method,
  body = {},
  headers = {}
}) => {
  method = method.toLowerCase()
  if (availableMethods.indexOf(method) < 0) {
    throw new Error('http method passed to makeApi is not valid')
  }
  try {
    let response = null
    if (method === 'post') {
      response = await axios.post(url, body, {
        headers: headers
      })
    } else if (method === 'get') {
      response = await axios.get(url, {
        headers: headers
      })
    } else if (method === 'delete') {
      response = await axios.delete(url, {
        headers: headers
      })
    } else {
      throw new Error('Method not valid')
    }
    return response
  } catch (error) {
    console.log('makecall error for url: ', url)
    console.log(error)
    return Promise.reject(error)
  }
}
