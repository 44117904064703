import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import getAsiCasesByUser from '../../Api/get-asi-cases-by-user'
import AsiCaseListHeader from './Asi-Case-list-header-user'
import utcToLocal from '../../utilityFunctions/utc-to-local'
import { Link } from "react-router-dom";


const AsiCasesDataList = ({
  token,
  userid
}) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  useEffect(() => {
    getAsiCasesByUser(userid, token)
      .then(res => {
        setData(res.data.data)
        setLoading(false)
      })
  }, [token,
    userid])

  if (loading) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  return (
    <div style={{
      marginTop: '27px'
    }}
    >
      {data.length > 0 ? <>
        <Table className="users-list-table-v2 user-case-list-table">
          <React.Fragment>
            <AsiCaseListHeader />
            <tbody>
              {
                data.map((singleCase, index) => {
                  return (
                    <tr className="content-list-group-item" key={singleCase.id}>
                     
                      <td className="align-middle">
                        {utcToLocal(singleCase.date)}
                      </td>
                    

                      <td className="align-middle text-center">
                        {singleCase.caseType}
                      </td>
                  
                      <td className="align-middle text-center">
                        {singleCase.conditionType}
                      </td>
                     
                      <td className="align-middle text-center">
                        <Link
                          to={`${window.location.pathname}?currTab=8&asiCaseId=${singleCase.id}`}
                          className="case-open-link"
                        >
                          {" "}
                          Click to view details{" "}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </React.Fragment>
        </Table>
      </> : <>
        <div className='text-center text-danger'>
          <p>No data found</p>
        </div>
      </>}

    </div>
  )
}

export default AsiCasesDataList