import axios from './axios'
import endpoints from '../config/endpoints'

export default async (patientName, patientEmail, patientDOB, token) => {
  const body = {
    patientName,
    patientEmail,
    token,
    patientDOB: patientDOB
  }
  try {
    const response = await axios.post(endpoints.INVITE_PATIENT, body)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}
