export const getAPIErrorMessageData = (err) => {
    if(err && err.response && err.response.data && err.response.data.message) {
        return err.response.data.message
    } else {
        return null
    }
}

export const getAPIErrorMessage = (err) => {
    if(err && err.response && err.response.message) {
        return err.response.message
    } else {
        return null
    }
}

