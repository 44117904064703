const pad = (numberString, size) => {
    let padded = numberString
    while (padded.length < size) padded = `0${padded}`
    return padded
}

const millisecondsToHuman = (ms) => {
    ms = !isNaN(ms) ? ms : 0
    const seconds = Math.floor((ms / 1000) % 60)
    const minutes = Math.floor((ms / 1000 / 60) % 60)
    const hours = Math.floor(ms / 1000 / 60 / 60)

    const humanized = [
        pad(hours.toString(), 2),
        pad(minutes.toString(), 2),
        pad(seconds.toString(), 2)
    ].join(':')

    return humanized
}

const renderElapsedString = (elapsed, runningSince) => {
    let totalElapsed = !isNaN(elapsed) ? elapsed : 0
    if (runningSince) {
        totalElapsed += Date.now() - runningSince
    }
    return millisecondsToHuman(totalElapsed)
}

export {
    millisecondsToHuman,
    renderElapsedString
}
