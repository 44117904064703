import axios from './axios'
import endpoints from '../config/endpoints'

export default async (userId, caseId, data, token) => {
    const body = {
        token,
        data
    }

    const headers = {
        userId, caseId  // Add caseId to the headers
    }

    try {
        const response = await axios.post(endpoints.UPDATE_CASE_BY_ID, body, { headers })
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}