import moment from 'moment'
import React, {useEffect, useState} from 'react'
import AddNotesModal from './AddNotesModal'
import DayGroup from './DayGroup'
import MessageNote from './MessageNote'
import NotificationNote from './NotificationNote'

const Notes = (props) => {
    const [isModalOn, setIsModalOn] = useState(false)
    const toggle = () => setIsModalOn(!isModalOn)
    const addNotesClickHandler = () => {
        toggle()
    }

    const noteOnSubmit = async (message: string) => {
        await props.submitDoctorNote('message', message)
        toggle()
    }

    const formatDayGroup = (input) => {
        let date = moment(input._id, 'MM-DD-YYYY').format('ll')
        return date
    }

    const formatTime = (note) => {
        if (note.type === 'message') {
            return moment.utc(note.createdAt, 'YYYY-MM-DD[T]HH:mm:ss').local().format('hh:mm:ss A Z, DD MMMM YYYY');
        } else if (note.type === 'notification') {
            const dateCreated = moment(note.createdAt, 'YYYY-MM-DD[T]HH:mm:ss').format('DD MMMM YYYY')
            const timeCreated = moment.utc(note.createdAt, 'YYYY-MM-DD[T]HH:mm:ss').local().format('hh:mm:ss A')
            if (note.updatedAt) {
                const timeUpdated = moment.utc(note.updatedAt, 'YYYY-MM-DD[T]HH:mm:ss').local().format('hh:mm:ss A Z')
                return `${timeCreated} - ${timeUpdated}, ${dateCreated}`
            } else {
                return `${timeCreated}, ${dateCreated}`
            }
        }
    }

    useEffect(() => {
        props.getDoctorNotes()
    }, [])

    const doctorNotes = props.doctorNotes

    return (
        <div className='doctor-notes-wrapper'>
            <div className="add-notes-link" onClick={addNotesClickHandler}>
                <i className="fa fa-sticky-note" aria-hidden="true"></i> Add Notes
            </div>
            {isModalOn && <AddNotesModal
                onSubmit={noteOnSubmit}
                isModalOn={isModalOn}
                toggle={toggle}
            />}
            {doctorNotes && doctorNotes.map((value, index) => {
                let notesRows = []
                return <DayGroup date={formatDayGroup(value)}>
                    {doctorNotes[index].notes.forEach(function (note) {
                        if (note.type === 'message') {
                            notesRows.push(<MessageNote
                                type={note.type}
                                message={note.content}
                                doctorName={doctorNotes[index].doctorName}
                                time={formatTime(note)}
                            />)
                        } else if (note.type === 'notification') {
                            notesRows.push(<NotificationNote
                                type={note.type}
                                suggested={note.suggestedContent}
                                removed={note.removedContent}
                                appliedPreset={note.appliedPreset}
                                doctorName={doctorNotes[index].doctorName}
                                time={formatTime(note)}
                            />)
                        }

                    })
                    }
                    <div>{notesRows}</div>
                </DayGroup>
            })}
        </div>
    )
}

export default Notes
