import { makeCall } from '../apiFactory'
import endpoints from '../../config/endpoints'

export const getMessageList = ({
    token
  }) => {
    return makeCall({
      url: endpoints.MESSAGE_LIST,
      method: 'get',
      headers: {
        token
      }
    })
}

export const getUnreadMessageCount = ({
  token
}) => {
  return makeCall({
    url: endpoints.MESSAGE_COUNT,
    method: 'get',
    headers: {
      token
    }
  })
}

