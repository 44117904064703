import React, {Component} from 'react'
import { connect } from 'react-redux'
import EffectivenessChart from "./Effectiveness-chart";
import {withRouter} from "react-router-dom";
import EczemaVsWeather from "./Eczema-vs-weather";
import CareRoutineVsTriggers from "./Care-routine-vs-triggers";
import { InsightCards } from './InsightCards'
import './Charts.scss'
import ScrollContainer from 'react-indiana-drag-scroll'
import RangeFilterTabs from './Range-filter-tabs';

export type RangeChangeSubscriber = (activeRange: number) => Promise<boolean>;
interface HistoryAndInsightsProps {
    loadCaseHistoryData?: any;
    caseHistory?: any;
    token?: string;
    userid?: string;
}

class HistoryAndInsights extends Component<HistoryAndInsightsProps> {
    state = {
        filterBtnList: [
            {
                id: 1,
                title: 'Week',
                isDefault: true,
            },
            {
                id: 2,
                title: 'Month',
                isDefault: false,
            },
            {
                id: 3,
                title: '3 Months',
                isDefault: false
            },
            {
                id: 4,
                title: 'Year',
                isDefault: false,
            },
        ],
        activeRangeFilter: 2,
        chartsLoading: false
    }

    
    rangeFilterClickHandler = (activeRange) => {
        this.setState(() => ({
            activeRangeFilter: activeRange,
            chartsLoading: true
        }), () => {
            setTimeout(async () => {
                const loaderPormises = []                
                this.rangeChangeSubscribers.forEach((func) => {
                    loaderPormises.push(func(activeRange))
                })
                await Promise.all(loaderPormises)
                this.setState(() => ({
                    chartsLoading: false
                }))
            }, 50);
        })
    }

    rangeChangeSubscribers: RangeChangeSubscriber[] = []

    subScribeToRangeChange = (func: RangeChangeSubscriber) => {
        this.rangeChangeSubscribers.push(func)
    }

    render() {
        return (
            <div className='history-insights-parent'>
                <InsightCards
                    token={this.props.token}
                    userid={this.props.userid}
                />
                <RangeFilterTabs
                    isLoading={this.state.chartsLoading}
                    btnList={this.state.filterBtnList}
                    activeButtonId={this.state.activeRangeFilter}
                    handleButtonClick={this.rangeFilterClickHandler}
                />
                <ScrollContainer
                    className="scroll-container"
                    ignoreElements=".compare-holder"
                >    
                    <div className="history-insights-wrapper">
                        <div className="left-block" style={{
                            marginRight: '70px'
                        }}>
                            <div className="single-chart" style={{ paddingTop: 0}}>
                                <EffectivenessChart
                                    filterBtnList={this.state.filterBtnList}
                                    token={this.props.token}
                                    userid={this.props.userid}
                                    subscribeToRangeChange={this.subScribeToRangeChange}
                                />
                            </div>
                            <div className="single-chart">
                                <EczemaVsWeather
                                    filterBtnList={this.state.filterBtnList}
                                    token={this.props.token}
                                    userid={this.props.userid}
                                    subscribeToRangeChange={this.subScribeToRangeChange}
                                />
                            </div>
                        </div>
                        <div className="right-block">
                            <CareRoutineVsTriggers
                                filterBtnList={this.state.filterBtnList}
                                token={this.props.token}
                                userid={this.props.userid}
                                subscribeToRangeChange={this.subScribeToRangeChange}
                            />
                        </div>
                    </div>
                </ScrollContainer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps)(withRouter(HistoryAndInsights))