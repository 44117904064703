import moment from 'moment'

interface Range {
  start: string;
  end: string;
}

export const getStartAndEndFromRange = (n: 1 | 2 | 3): Range => {
  let formatString =  'YYYY-MM-DD'
  let start
  let end = moment().endOf('day').format(formatString)
  if (n === 1) {
    start = moment(end).subtract(1, 'week').format(formatString)
  } else if (n === 2) {
    start = moment(end).subtract(1, 'month').format(formatString)
  } else if (n === 3) {
    start = moment(end).subtract(3, 'months').format(formatString)
  } else {
    start = moment(end).subtract(1, 'year').format(formatString)
  }
  return {
    start,
    end
  }
}

export const logAndReturn = (thing) => {
  console.log(thing)
  return thing
}
