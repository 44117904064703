import getProp from '../../Components/User/User-details-components/Utils/get'
import {
    getTriggerCategories,
    getTriggerTrackedByUser,
    getTriggerListByUser,
    postTriggerTracked,
    deleteTriggerTracked,
    postTriggerList
} from './manage-triggers'

import {
    getTriggerListByUserUnconnected,
    getTriggerTrackedByUserUnconnected,
    postTriggerTrackedUnconnected,
    deleteTriggerTrackedUnconnected,
    postTriggerListUnconnected
} from './manage-triggers'

// transformations [['fromKey', 'toKey']]
// the following function puts value of one key to another
const transformRequestObject = (obj, transformations = []) => {
    transformations.forEach(transform => {
        obj[transform[1]] = obj[transform[0]]
        delete obj[transform[0]]
    })
    return obj
}

const triggerToCareObjTransform = (trigger) => {
    return {
        ...trigger,
        careActivityId: trigger.triggerId || trigger.id,
        careCategoryId: trigger.triggerCategoryId,
        careActivityLabel: trigger.triggerLabel,
        careActivityName: trigger.triggerLabel
    }
}

class MockResponse {
    constructor(data) {
        this.data = data
    }
}

class ResponseFormatted extends MockResponse {
    constructor(data) {
        const formattedData = {
            data: data,
            message: '',
            error: false
        }
        super(formattedData)
    }
}

export const getCareCategories = async (obj) => {
    const response = await getTriggerCategories(obj)
    const triggerCategories = response.data.triggerCategories
    const careCategories = triggerCategories.map(triggerCategory => {
        return {
            "careCategoryId": triggerCategory.triggerCategoryId,
            "careCategoryName": triggerCategory.triggerCategoryLabel,
        }
    })
    return new MockResponse({ careCategories })
}

export const getCareActivitiesTrackedByUser = async (obj) => {
    const response = await getTriggerTrackedByUser(obj)
    const triggersTracked = getProp(response, 'data.data', [{}])
    const careActivitiesTracked = triggersTracked.map(trigger => {
        return {
            ...trigger,
            careActivityId: trigger.triggerId,
            careCategoryId: trigger.triggerCategoryId,
            careActivityLabel: trigger.triggerLabel
        }
    })
    return new ResponseFormatted(careActivitiesTracked)
}

export const getCareActivitiesListByUser = async (obj) => {
    obj = transformRequestObject(obj, [['careCategoryId', 'triggerCategoryId']])
    const response = await getTriggerListByUser(obj)
    const triggerList = getProp(response, 'data.data', [{}])
    const careActivitiesList = triggerList.map(trigger => triggerToCareObjTransform(trigger))
    return new ResponseFormatted(careActivitiesList)   
}

export const postCareActivitiesTracked = (obj) => {
    obj = transformRequestObject(obj, [['careCategoryId', 'triggerCategoryId'], ['careActivityId', 'triggerId']])
    return postTriggerTracked(obj)
}

export const deleteCareActivitiesTracked = (obj) => {
    obj = transformRequestObject(obj, [['careCategoryId', 'triggerCategoryId'], ['careActivityId', 'triggerId']])
    return deleteTriggerTracked(obj)
}

export const postCareActivitiesList = (obj) => {
    obj = transformRequestObject(obj, [['careCategoryId', 'triggerCategoryId'], ['careActivityLabel', 'triggerLabel']])
    return postTriggerList(obj)
}

/****** Unconnected APIs *****/
// careActivitiesListAPI={ getCareActivitiesListByUserUnconnected }
// careActivitiesTrackedAPI={ getCareActivitiesTrackedByUserUnconnected }
// postCareActivitiesTracked={postCareActivitiesTrackedUnconnected}
// deleteCareActivitiesTracked={deleteCareActivitiesTrackedUnconnected}
// postCareActivitiesListAPI={postCareActivitiesListUnconnected}

export const getCareActivitiesListByUserUnconnected = async (obj) => {
    obj = transformRequestObject(obj, [['careCategoryId', 'triggerCategoryId']])
    const response = await getTriggerListByUserUnconnected(obj)
    const triggerList = getProp(response, 'data.data', [{}])
    const careActivitiesList = triggerList.map(trigger => triggerToCareObjTransform(trigger))
    return new ResponseFormatted(careActivitiesList)   
}

export const getCareActivitiesTrackedByUserUnconnected = async (obj) => {
    const response = await getTriggerTrackedByUserUnconnected(obj)
    const triggersTracked = getProp(response, 'data.data', [{}])
    const careActivitiesTracked = triggersTracked.map(trigger => {
        return {
            ...trigger,
            careActivityId: trigger.triggerId,
            careCategoryId: trigger.triggerCategoryId,
            careActivityLabel: trigger.triggerLabel
        }
    })
    return new ResponseFormatted(careActivitiesTracked)
}

export const postCareActivitiesTrackedUnconnected = (obj) => {
    obj = transformRequestObject(obj, [['careCategoryId', 'triggerCategoryId'], ['careActivityId', 'triggerId']])
    return postTriggerTrackedUnconnected(obj)
}

export const deleteCareActivitiesTrackedUnconnected = (obj) => {
    obj = transformRequestObject(obj, [['careCategoryId', 'triggerCategoryId'], ['careActivityId', 'triggerId']])
    return deleteTriggerTrackedUnconnected(obj)
}

export const postCareActivitiesListUnconnected = (obj) => {
    obj = transformRequestObject(obj, [['careCategoryId', 'triggerCategoryId'], ['careActivityLabel', 'triggerLabel']])
    return postTriggerListUnconnected(obj)
}
