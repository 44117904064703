import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { openImageLightbox, closeImageLightbox } from '../../../actions/uiActions'

const styles = {
  wrapper: {
    position: 'relative'
  }
}

const UserImage = (props) => {
  useEffect(() => {
    return () => {
      props.dispatcher(closeImageLightbox())
    }
  }, [])
  const isPending = props.pendingUploads.indexOf(props.timeStamp) >= 0
  return (
    <div style={styles.wrapper}>
      {isPending && <div className='message-image-loader'/>}
      <img onClick={() => {
          props.dispatcher(openImageLightbox(props.src))
      }} className='message-image' alt='message' src={props.src} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher : (action) => {
      dispatch(action)
    }
  }
}

export default connect(null, mapDispatchToProps)(UserImage)
