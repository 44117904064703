import React from 'react'
import CareManagement from '../Care-management/Care-management'

import {
  getCareCategories,
  getCareActivitiesTrackedByUser,
  getCareActivitiesListByUser,
  postCareActivitiesTracked,
  deleteCareActivitiesTracked,
  postCareActivitiesList
} from '../../Api/Manage-tracking/trigger-response-transformers'


const TriggerTracker = (props) => {
  let backgroundStyle = { backgroundColor: 'white'}
  const disableWhiteBackground = props.disableWhiteBackground
  if (disableWhiteBackground) {
    backgroundStyle = {}
  }
  return (
    <div className="row">
      <div className='col-sm-12'>
          <div style={backgroundStyle} className='care-tracker-wrapper-div'>
            {!props.shouldHideHeading && <h4 className='suggest-care-activity-headline'>Track triggers</h4>}
            <CareManagement
              wrapperClass='care-tracker care-tracker--user'
              {...props}
              mode='connected'
              getCareCategoriesAPI={getCareCategories}
              careActivitiesListAPI={ getCareActivitiesListByUser }
              careActivitiesTrackedAPI={ getCareActivitiesTrackedByUser }
              postCareActivitiesTracked={ postCareActivitiesTracked }
              deleteCareActivitiesTracked={ deleteCareActivitiesTracked }
              postCareActivitiesListAPI={ postCareActivitiesList }
              userIdentifierHeader='userId'
              userIdentifierParam='userId'
            />        
          </div>
      </div>
    </div>
  )
}

export default TriggerTracker
