import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BuildUrl from 'build-url'
import loadable from 'react-loadable'
import qs from 'querystring'

import { MAX_CASES_COUNT } from '../../constants'
import LoaderButton from '../UI-helper-components/Loader-button'
import casesListApi from '../../Api/cases'
import PageNavigator from '../UI-helper-components/page-navigator'
import roles, { actions } from '../../config/roles'
import CasesListView from '../User/User-case-list-view'
import UserCaseListView from '../User/User-case-list-view'
import Can from '../UI-helper-components/Can'

const JsonEditor = loadable({
  loader: () => import('../UI-helper-components/Json-editor'),
  loading: () => <p>Loading...</p>
})

class OpenCases extends Component {
  
  constructor(props) {
    super(props)
    this.queryParamValue = this.queryParamValue.bind(this)
  }
  
  queryParamValue = (paramName) => {
    if(paramName[0] === '?') {
      return qs.parse(this.props.location.search)[paramName] || qs.parse(this.props.location.search)[paramName.replace('?', '')] || ''
    }
    return qs.parse(this.props.location.search)[paramName] || ''
  }
  
  state = {
    casesList: [],
    casesLoaded: false,
    isSpinnerOn: false,
    isFullScreenLoaderOn: false,
    isButtonLoaderOn: false,
    page: this.props.match.params.pageNumber - 1,
    totalCases: 0,
    navigatorIndex: 1,
    searchTerm: this.queryParamValue('?search') || '' ,
    activeSearchTerm: '',
    queryTerm: this.queryParamValue('query') && atob(this.queryParamValue('query')) || '',
    activeQueryTerm: ''
  }

  toggleSpinner = () => {
    this.setState(({isSpinnerOn}) => {
      return {
        isSpinnerOn: !isSpinnerOn
      }
    })
  }

  toggleFullScreenLoader = () => {
    this.setState(({isFullScreenLoaderOn}) => ({
      isFullScreenLoaderOn: !isFullScreenLoaderOn
    }))
  }

  componentDidMount () {
    this.toggleSpinner()
    let activeSearchTerm = this.state.searchTerm
    let activeQueryTerm = this.state.queryTerm
    casesListApi(this.props.state.authReducer.token, this.state.page * MAX_CASES_COUNT, this.state.searchTerm, this.state.queryTerm)
      .then(res => {
        let data = res.data && res.data.data
        this.toggleSpinner()
        this.setState(() => {
          return {
            casesList: data.careCasesData,
            casesLoaded: true,
            totalCases: data.careCasesCount,
            activeSearchTerm: activeSearchTerm,
            activeQueryTerm: activeQueryTerm
          }
        })
      })
      .catch(err => {
        console.log(err)
        this.toggleSpinner()
        alert('Error. Please retry')
      })
  }

  loaderButtonClickHandler = async (e) => {
    if(e) e.preventDefault()
    this.setState(() => ({
      isButtonLoaderOn: true
    }))
    try {
      let activeSearchTerm = this.state.searchTerm
      let activeQueryTerm = this.state.queryTerm
      const res = await casesListApi(this.props.state.authReducer.token, this.state.page * MAX_CASES_COUNT, this.state.searchTerm, this.state.queryTerm)
      this.setState((prevState) => {
        if(prevState.casesList !== res.data.data.careCasesData){
          return {
            isButtonLoaderOn: false,
            casesList: res.data.data.careCasesData,
            totalCases: res.data.data.careCasesCount,
            activeSearchTerm: activeSearchTerm,
            activeQueryTerm: activeQueryTerm
          }
        }
      })
    } catch (error) {
      this.setState(() => ({
        isButtonLoaderOn: false
      }))
      alert('Error. Please retry.')
    }
  }

  getReadablePageNumber = () => {
    return this.state.page + 1
  }

  getPageCountText = () => {
    if(this.state.casesList.length === 0 ) {
      return 'No cases found'
    }
    return `${this.getReadablePageNumber()} of ${Math.ceil(this.state.totalCases / MAX_CASES_COUNT)} pages (total: ${this.state.totalCases})`
  }

  goNextPage = () => {
    const urlQueryParams = {
      search: this.queryParamValue('?search'),
      query: this.queryParamValue('query')
    }

    const urlStr = BuildUrl('', {
      path: `/cases/page/${this.getReadablePageNumber() + 1}`,
      queryParams: urlQueryParams
    })
    this.props.history.replace(urlStr)
    this.setState((prevState) => ({
      page: prevState.page + 1
    }), this.loaderButtonClickHandler)
  }

  goPrevPage = () => {
    const urlQueryParams = {
      search: this.queryParamValue('?search'),
      query: this.queryParamValue('query')
    }
    const urlStr = BuildUrl('', {
      path: `/cases/page/${this.getReadablePageNumber() - 1}`,
      queryParams: urlQueryParams
    })
    this.props.history.replace(urlStr)
    this.setState((prevState) => ({
      page: prevState.page - 1
    }), this.loaderButtonClickHandler)
  }

  isNextDisabled = () => {
    return ((this.state.page + 1) * MAX_CASES_COUNT) >= this.state.totalCases
  }

  isPrevDisabled = () => {
    return this.state.page === 0
  }

  submitPageNumber = (pageNumber) => {
    this.props.history.replace(`/cases/page/${pageNumber}`)
    let actualPageNumber = pageNumber - 1
    this.setState(() => ({
      page: actualPageNumber
    }), this.loaderButtonClickHandler)
  }

  isButtonLoaderOn = () => {
    return this.state.isButtonLoaderOn
  }

  searchAction = () => {
    this.setState(() => {
      return {
        page: 0,
        totalCases: 0,
        navigatorIndex: 1
      }
    }, () => {
      this.props.history.replace(`/cases/page/1?search=${this.state.searchTerm}&query=${btoa(this.state.queryTerm)}`)
      this.loaderButtonClickHandler()
    })
  }

  searchOnSubmit = () => {
    this.setState(() => {
      return {
        queryTerm: this.state.queryTerm.trim()
      }
    }, this.searchAction)
  }

  searchOnChange = (e) => {
    const searchTerm = e.target.value
    this.setState(() => ({
      searchTerm
    }))
  }

  searchOnClear = () => {
    this.props.history.replace(`/cases/page/1`)
    this.setState(() => {
      return {
        casesList: [],
        page: 0,
        totalCases: 0,
        navigatorIndex: 1,
        searchTerm: '',
        activeSearchTerm: '',
        queryTerm: '',
        activeQueryTerm: ''
      }
    }, this.loaderButtonClickHandler)
  }

  getSearchResultText = () => {
    if(this.state.activeSearchTerm) {
      return <div className='search-term-result-text'>Search result for <span className='search-term-text'>{this.state.activeSearchTerm}</span></div>
    } else {
      return null
    }
  }

  searchOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.searchOnSubmit()
    }
  }

  queryOnChange = (text) => {
    const queryTerm = text
    this.setState(() => ({
      queryTerm
    }))
  }

  render () {
    return (
      <div className='container'>
        <div>
          {/* <div className='search-bar'>
            <div class="form-group has-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input onKeyDown={this.searchOnKeyDown} onChange={this.searchOnChange} value={this.state.searchTerm} type="text" class="form-control" placeholder="Search by email or country name" />
            </div>
          </div> */}
          <Can
            action={actions.raw_query}
            yes={() => (
              <div className='query-input form-group'>
                <JsonEditor
                  theme="ace/theme/github"
                  onChange={this.queryOnChange}
                  value={this.state.queryTerm}
                />
                <div className='search-area-buttons search-button-cases'>
                  <button onClick={this.searchOnSubmit} type="button" className="btn btn-primary search-btn">Search</button>
                  <button onClick={this.searchOnClear} type="button" className="btn btn-primary clear-btn">Clear</button>
                </div>
              </div>
            )}          
          />
        </div>
        <div>
          {this.getSearchResultText()}
        </div>
        {this.state.casesLoaded && <div className="row">
          <div className="col-md-12 list-page-heading-wrapper">
            <div className='users-screen-heading'>
              <h2 className='page-heading'>Cases</h2>
            </div>
            <p className='page-count-text-heading'>{this.getPageCountText()}</p>
            <LoaderButton
              clickHandler={this.loaderButtonClickHandler}
              isLoading={this.state.isButtonLoaderOn}
            />
          </div>
        </div>}
        {this.state.isFullScreenLoaderOn && <div className='tibot-full-screen-loader'></div>} 
        {this.state.isSpinnerOn && <div className='square-spinner'></div>}
        <UserCaseListView
          {...this.props}
          casesLoaded={this.state.casesLoaded}
          caseList={this.state.casesList}
          isCoverLoaderOn={this.isButtonLoaderOn()}
          fromQueryList={true}
        />
        {this.state.casesLoaded && this.state.casesList.length > 0 && <PageNavigator
          key={this.getReadablePageNumber()}
          currentPageNumber={this.getReadablePageNumber()}
          goNext={this.goNextPage}
          goPrevious={this.goPrevPage}
          isNextDisabled={this.isNextDisabled()}
          isPrevDisabled={this.isPrevDisabled()}
          submitPageNumber={this.submitPageNumber}
        />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state
  }
}

export default connect(mapStateToProps)(withRouter(OpenCases))
