import React from 'react'

const AssistantListHeading = (props) => {
    return <thead>
    <tr>
        <td className='align-middle text-center'>First Name</td>
        <td className='align-middle text-center'>Last Name</td>
        <td className='align-middle text-center'>Email</td>
        <td className='align-middle text-center'>Phone Number</td>
        <td className='align-middle text-center'>Address</td>
        <td></td>
        <td></td>
    </tr>
    </thead>
}

export default AssistantListHeading
