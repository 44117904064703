import axios from './axios'
import endpoints from '../config/endpoints'

export default async (patientId, token) => {
    const body = {
        patientId,
        token
    }
    try {
        const response = await axios.post(endpoints.TOGGLE_DOCTOR_CHAT_STATE, body)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}
