import React from 'react'
import moment from 'moment'
import { ListGroup, ListGroupItem, Table } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'

import UTCToLocal from '../../utilityFunctions/utc-to-local'
import ActivitiesListHeader from './Care-activities-list-header'
import config from '../../config/config'

const getFormattedDate = (dateStr) => {
  return moment(dateStr)
}

const CareActivitiesList = (props) => {
  return (
    <div className='case-list activities-list-query table-responsive'>
      { props.casesLoaded && props.caseList.length === 0 ? <div style={{ textAlign: 'center' }}>No activities to show</div> : <ListGroup>
        <Table>
          <ActivitiesListHeader
            {...props}
            fromQueryList={props.fromQueryList}
            listType={props.listType}
          />
          <tbody>
          {props.caseList && props.caseList.map((singleCase, index) => {
            return (<tr key={index}>
  
                <td className='case-date'>
                  {UTCToLocal(new Date(singleCase.careActivityDate), true)}
                </td>
                <td className="case-user">
                  <Link to={config.baseRoute + 'user/' + singleCase.user.id}> {singleCase.user && singleCase.user.email} </Link>
                </td>
                <td className='activity-label'>
                  {singleCase.careActivityLabel}
                </td>
                <td className='activity-category'>
                  {singleCase.careCategoryName}
                </td>
                <td className='activity-is-on'>
                  {singleCase.isOn? <span className='tick-activity'>✓</span> : <span className='cross-activity'>✕</span>}
                </td>
            </tr>)
          })}
          </tbody>
        </Table>
      </ListGroup> }
    </div>
  )
}

export default withRouter(CareActivitiesList)
