import React, { Component, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AddNotesModalProps } from './interfaces'

const  AddNotesModal = (props: AddNotesModalProps ) => {
  const [noteText, setNoteText] = useState('')
  return (
    <div>
      <Modal isOpen={props.isModalOn} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Add a note</ModalHeader>
        <ModalBody>
          <textarea style={{
            minHeight: '180px'
          }} className='form-control' value={noteText} onChange={(e) => {
            setNoteText(e.target.value)
          }} placeholder='Write your note here' />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            props.onSubmit(noteText)
          }}>Submit</Button>
          <Button color="secondary" onClick={props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AddNotesModal
