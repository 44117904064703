import React, { Component } from 'react'
import { connect } from 'react-redux'
import postInvitePatient from '../../Api/post-invite-patient'
import LoaderButton from '../UI-helper-components/Loader-button'
import { extractErrorMessage } from '../../utilityFunctions/common'
import DatePicker from 'react-date-picker';

const successMessage = (patientEmail) => {
  return <div className='success-message-invite'>Successfully sent invitation to <strong>{patientEmail}</strong>. Once accepted, the patient will appear in your user list.</div>
}

const errorMessage = (patientEmail, apiErrorMessage) => {
return <div className='error-message-invite'>
    <div>{apiErrorMessage}</div>
    <div>
      Error sending invitation to <strong>{patientEmail}</strong>. Please contact us at support@eczemaless.com for assitance regarding this issue.</div>
    </div>
}

const responseTypes = {
  'SUCCESS': 'SUCCESS',
  'ERROR': 'ERROR'
}

class InvitePatient extends Component {

  state = {
    isSpinnerOn: false,
    didGetResponse: false,
    lastSubmittedEmail: '',
    patientEmail: '',
    responseType: null,
    apiErrorMessage: '',
    patientName: '',
    patientDOB: ''
  }

  viewMessage = () => {
    if(!this.state.didGetResponse) {
      return null
    }
    if(this.state.responseType === responseTypes.SUCCESS) {
      return successMessage(this.state.lastSubmittedEmail)
    } else {
      return errorMessage(this.state.lastSubmittedEmail, this.state.apiErrorMessage)
    }
  }

  toggleSpinner = () => {
    this.setState(({isSpinnerOn}) => {
      return {
        isSpinnerOn: !isSpinnerOn
      }
    })
  }

  onPatientEmailChangeHandler = (e) => {
    const patientEmail = e.target.value
    this.setState(() => ({
      patientEmail
    }))
  }

  onPatientNameChange = (e) => {
    const patientName = e.target.value
    this.setState(() => ({
      patientName
    }))
  }

  onPatientDOBChange = (date) => {
    const patientDOB = date
    this.setState(() => ({
      patientDOB: patientDOB
    }))
  }

  onSubmitHandler = async (e) => {
    e.preventDefault()
    if(!this.state.patientEmail || !this.state.patientName) {
      return
    }
    try {
      this.toggleSpinner()
      this.setState(() => ({
        didGetResponse: false,
        lastSubmittedEmail: this.state.patientEmail,
        apiErrorMessage: ''
      }))
      await postInvitePatient(this.state.patientName, this.state.patientEmail, this.state.patientDOB, this.props.state.authReducer.token)
      this.setState(() => ({
        didGetResponse: true,
        responseType: responseTypes.SUCCESS,
        patientEmail: '',
        patientName: '',
        patientDOB: ''
      }))
    } catch (error) {
      console.log(error)
      this.setState(() => ({
        didGetResponse: true,
        responseType: responseTypes.ERROR,
        apiErrorMessage: extractErrorMessage(error)
      }))
    } finally {
      this.toggleSpinner()
    }
  }

  render () {
    return (
      <div className='container invite-patient-page'>
        <p style={{ fontSize: '25px' }}>Enter a patient's name, email and invite them to manager their care plan</p>
        <form onSubmit={this.onSubmitHandler}>
          <div className="form-group">
            <label htmlFor="patientName">Name *</label>
            <input required onChange={this.onPatientNameChange} type="text" className="form-control" id="patientName" placeholder="Patient Name" value={this.state.patientName} />
            <br />
            <label htmlFor="patientEmail">Email *</label>
            <input required onChange={this.onPatientEmailChangeHandler} type="email" className="form-control" id="patientEmail" placeholder="Patient Email" value={this.state.patientEmail} />
            <br />
            <label htmlFor="patientDOB">Date of Birth</label>
            <div>
              <DatePicker
                calendarAriaLabel="Toggle calendar"
                clearAriaLabel="Clear value"
                dayAriaLabel="Day"
                monthAriaLabel="Month"
                nativeInputAriaLabel="Date"
                onChange={this.onPatientDOBChange}
                value={this.state.patientDOB}
                yearAriaLabel="Year"
              />
            </div>
          </div>
          <div style={{
            display: 'inline-block'
          }}>
            <LoaderButton
              clickHandler={this.onSubmitHandler}
              buttonText='Invite'
              isLoading={this.state.isSpinnerOn}
              isSubmit={true}
            />
          </div>
        </form>
        {this.viewMessage()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state
  }
}

export default connect(mapStateToProps)(InvitePatient)
