import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import BuildUrl from 'build-url'
import loadable from 'react-loadable'
import qs from 'querystring'

import {MAX_USERS_COUNT} from '../../constants'
import assistantsListApi from '../../Api/get-doctor-assistants'
import AssistantList from './Assistant-list'
import PageNavigator from '../UI-helper-components/page-navigator'
import ScreenHeader from '../Common/Screen-header'
import CTAButton from '../Common/Cta-button'
import deleteDoctorAssistantApi from '../../Api/delete-doctor-assistant'
import {toast} from "react-toastify";

const JsonEditor = loadable({
    loader: () => import('../UI-helper-components/Json-editor'),
    loading: () => <p>Loading...</p>
})

class Assistants extends Component {

    constructor(props) {
        super(props)
        this.queryParamValue = this.queryParamValue.bind(this)
    }

    queryParamValue = (paramName) => {
        if (paramName[0] === '?') {
            return qs.parse(this.props.location.search)[paramName] || qs.parse(this.props.location.search)[paramName.replace('?', '')] || ''
        }
        return qs.parse(this.props.location.search)[paramName] || ''
    }

    state = {
        assistantList: [],
        assistantsLoaded: false,
        isSpinnerOn: false,
        isFullScreenLoaderOn: false,
        isButtonLoaderOn: false,
        page: this.props.match.params.pageNumber - 1,
        totalAssistants: 0,
        navigatorIndex: 1,
        searchTerm: this.queryParamValue('?search') || '',
        activeSearchTerm: '',
        queryTerm: this.queryParamValue('query') && atob(this.queryParamValue('query')) || '',
        activeQueryTerm: ''
    }

    toggleSpinner = () => {
        this.setState(({isSpinnerOn}) => {
            return {
                isSpinnerOn: !isSpinnerOn
            }
        })
    }

    componentDidMount() {
        this.toggleSpinner()
        this.getDoctorAssistants()
    }

    getDoctorAssistants() {
        assistantsListApi(this.props.state.authReducer.token)
            .then(res => {
                let data = res.data && res.data.data
                this.toggleSpinner()
                this.setState(() => {
                    return {
                        assistantList: data,
                        assistantsLoaded: true,
                        totalAssistants: data.length
                    }
                })
            })
            .catch(err => {
                console.log(err)
                this.toggleSpinner()
                alert('Error. Please retry')
            })
    }

    loaderButtonClickHandler = async (e) => {
        if (e) e.preventDefault()
        this.setState(() => ({
            isButtonLoaderOn: true
        }))
        try {
            const res = await assistantsListApi(this.props.state.authReducer.token)
            this.setState((prevState) => {
                if (prevState.assistantList !== res.data) {
                    return {
                        isButtonLoaderOn: false,
                        assistantList: res.data.data,
                        totalAssistants: res.data.data.length
                    }
                }
            })
        } catch (error) {
            this.setState(() => ({
                isButtonLoaderOn: false
            }))
            alert('Error. Please retry.')
        }
    }

    getReadablePageNumber = () => {
        return this.state.page + 1
    }

    goNextPage = () => {
        const urlQueryParams = {
            search: this.queryParamValue('?search'),
            query: this.queryParamValue('query')
        }

        const urlStr = BuildUrl('', {
            path: `/assistants/page/${this.getReadablePageNumber() + 1}`,
            queryParams: urlQueryParams
        })
        this.props.history.replace(urlStr)
        this.setState((prevState) => ({
            page: prevState.page + 1
        }), this.loaderButtonClickHandler)
    }

    goPrevPage = () => {
        const urlQueryParams = {
            search: this.queryParamValue('?search'),
            query: this.queryParamValue('query')
        }
        const urlStr = BuildUrl('', {
            path: `/assistants/page/${this.getReadablePageNumber() - 1}`,
            queryParams: urlQueryParams
        })
        this.props.history.replace(urlStr)
        this.setState((prevState) => ({
            page: prevState.page - 1
        }), this.loaderButtonClickHandler)
    }

    isNextDisabled = () => {
        return ((this.state.page + 1) * MAX_USERS_COUNT) >= this.state.totalAssistants
    }

    isPrevDisabled = () => {
        return this.state.page === 0
    }

    submitPageNumber = (pageNumber) => {
        this.props.history.replace(`/assistants/page/${pageNumber}`)
        let actualPageNumber = pageNumber - 1
        this.setState(() => ({
            page: actualPageNumber
        }), this.loaderButtonClickHandler)
    }

    isButtonLoaderOn = () => {
        return this.state.isButtonLoaderOn
    }

    ctaOnClick = () => {
        this.props.history.push(`/assistants/add`)
    }

    deleteAssistant = async (assistantId) => {
        if (!assistantId) {
            return
        }
        try {
            await deleteDoctorAssistantApi(this.props.state.authReducer.token, assistantId)
            toast('Assistant has been deleted successfully', {
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                type: 'success'
            })
            this.getDoctorAssistants()
            this.toggleSpinner()
        } catch (error) {
            console.log(error)
            toast('Error occurred. Please retry', {
                type: 'error',
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                autoClose: false
            })
        }
    }

    goToUpdateAssistant = (assistantId) => {
        this.props.history.push(`/assistants/update/${assistantId}`)
    }

    render() {
        return (
            <React.Fragment>
                <ScreenHeader heading='Assistants' button={(
                    <CTAButton
                        text='Add New Assistant'
                        onClick={this.ctaOnClick}
                        icon={(
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        )}
                    />
                )}/>
                <div className='screen-content users-screen'>
                    {this.state.isFullScreenLoaderOn && <div className='tibot-full-screen-loader'></div>}
                    {this.state.isSpinnerOn && <div className='square-spinner'></div>}
                    {this.state.assistantsLoaded && this.state.assistantList.length > 0 && <AssistantList
                        assistantList={this.state.assistantList}
                        isCoverLoaderOn={this.isButtonLoaderOn()}
                        deleteAssistant={this.deleteAssistant}
                        updateAssistant={this.goToUpdateAssistant}
                    />}
                </div>
                {this.state.assistantsLoaded && this.state.assistantList.length > 0 && <PageNavigator
                    key={this.getReadablePageNumber()}
                    currentPageNumber={this.getReadablePageNumber()}
                    goNext={this.goNextPage}
                    goPrevious={this.goPrevPage}
                    isNextDisabled={this.isNextDisabled()}
                    isPrevDisabled={this.isPrevDisabled()}
                    submitPageNumber={this.submitPageNumber}
                />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state,
        isAdmin: state.authReducer.isAdmin
    }
}

export default connect(mapStateToProps)(withRouter(Assistants))
