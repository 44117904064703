import React, { Component } from "react";
import "./TabMenu.css";

class TabMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  componentDidMount() {
    const { headings, activeTab } = this.props;
    const index = headings.indexOf(activeTab);
    this.setState({ activeTab: parseInt(index) });
  }

  switchToTab = (tabNumber) => {
    const { headings } = this.props;
    const selectedTabHeading = headings[tabNumber];

    this.props.onTabChange(selectedTabHeading);
    this.setState({ activeTab: tabNumber });
  };

  renderTab = (heading, index) => {
    return <div onClick={() => this.switchToTab(index)}>{heading}</div>;
  };

  render() {
    const { headings } = this.props;
    const { activeTab } = this.state;

    return (
      <div className="tab-container">
        <div className="tab-heading-wrapper">
          {headings.map((heading, index) => (
            <div
              className={`tabItem ${activeTab === index ? "active" : ""}`}
              key={index}
              onClick={() => this.switchToTab(index)}
            >
              {this.renderTab(heading, index)}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default TabMenu;