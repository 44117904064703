import React from 'react'
import chroma from 'chroma-js'
import SelectCreatable from '../Care-management/Select-creatable'
import Select from 'react-select'

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10
    }
})

const colourStyles = (props) => {
    return {
        control: styles => ({...styles, backgroundColor: 'white'}),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            const inputColor = data.color || '#36B37E'
            const color = chroma(inputColor)
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
                }
            }
        },
        input: styles => ({...styles, ...dot()}),
        placeholder: styles => ({...styles, ...dot()}),
        singleValue: (styles, {data}) => {
            let obj = {...styles, ...dot(data.color)}
            if (props.smallSingle) {
                obj = {
                    ...obj,
                    fontSize: '16px'
                }
            }
            return obj
        }
    }
}


export default (props) => (
    <div className={`color-selector-wrapper ${props.className}`}>
        {props.showCreatable ? <SelectCreatable
                onChange={props.onChange}
                options={props.treatmentPlans}
                styles={colourStyles(props)}
                value={props.value}
                handleCreate={props.handleCreate}
            /> :
            <Select
                onChange={props.onChange}
                options={props.treatmentPlans}
                styles={colourStyles(props)}
                value={props.value}
            />
        }
    </div>
)
