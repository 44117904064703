import React from 'react'
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import ChatLog from './ChatLog'

const ChatLogsModal = (props) => {
    const renderChatLog = (chatLogs) => {
        if (chatLogs) {
            return Object.keys(chatLogs).map((item, index) => {
                if (chatLogs[item] !== null && chatLogs[item].index !== 15) {
                    return (
                        <ChatLog
                            chatLog={chatLogs[item]}
                        />
                    )
                }
            })
        }
    }

    return (
        <div>
            <Modal isOpen={props.isModalOn} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}>Skin Analysis</ModalHeader>
                <ModalBody>
                    {props.chatLogs && renderChatLog(props.chatLogs)}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ChatLogsModal
