import React from 'react'
import { ListGroupItem } from 'reactstrap'
import { actions } from '../../config/roles'
import Can from '../UI-helper-components/Can'
import { checkFitzpatrickShow, checkIGAValueHide } from '../User/User-details-components/Utils/get'

const UserListHeading = (props) => {
  return <thead>
        <tr>
          <Can
            action={actions.signup_date}
            yes={() => (
              <td className='align-middle'>Signup Date</td>
            )}
            no={() => (
              <td className='align-middle'>Reg. Date</td>
            )}
          />
          <td className='align-middle text-center'>Name</td>
          <td className='align-middle'>Email</td>
          <td className='align-middle text-center'>Date of Birth</td>
          <td className='align-middle text-center'>Days Opened</td>
          {checkIGAValueHide(props.userRole, ['STUDY_USER']) && <td className='align-middle'>Last IGA Score</td>}          
          {checkFitzpatrickShow(props.userRole, ['STUDY_USER', 'STUDY']) && <td className='align-middle'>Fitzpatrick Skin Type</td>}
          <Can
            action={actions.view_user_list_country}
            yes={() => (
              <td className='align-middle'>Country</td>
            )}
          />
          <td className='align-middle text-center'>Cases</td>
          <td className='align-middle text-center'>Activities</td>
          <td className='align-middle text-center'>Triggers</td>
          <td></td>
        </tr>
  </thead>
}

export default UserListHeading
