import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ListGroup } from 'reactstrap'
import SubCategory from './Single-activity'
import ContentLoader from './React-content-loader'
import { connect } from 'react-redux'

class EditTriggerSubCategories extends Component {

  state = {
    subCategoriesList: [],
    subCategoriesListLoaded: false,
    trackedSubCategoriesList: []
  }

  componentDidMount () {
    // keys that represent the identification of a user shoud either be
    // userId or userEmail. This is for header fields or history params
    let queryObj = {
      token: this.props.state.authReducer.token,
      careCategoryId: this.props.careCategoryId,
      userid: this.props.userId,
      treatmentPlan: this.props.treatmentPlan
    }
    
    if (this.props.mode === 'unconnected') {
      delete queryObj.userid
      queryObj['userEmail'] = this.props.userEmail
    }
    
      this.props.careActivitiesListAPI(queryObj)
      .then(res => {
        this.setState(() => ({
            subCategoriesList: res.data.data,
            subCategoriesListLoaded: true
        }))
        if (this.props.type === 'activities') {
          this.props.setCategoryInstructions(res.data.instructions || [])
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    return (
        <div>             
          {this.state.subCategoriesListLoaded?
            <ListGroup>
              {this.state.subCategoriesList.map(item => {
                return <SubCategory
                  careActivityId={item.careActivityId}
                  careActivityLabel={item.careActivityLabel}
                  careCategoryId={this.props.careCategoryId}
                  categoryInstructions={this.state.categoryInstructions}
                  {...item}
                  {...this.props}
                />
            })} </ListGroup> : <ContentLoader /> }
          </div>
    )
  }
}

EditTriggerSubCategories.propTypes = {
  careCategoryId: PropTypes.string,
  suggestedIdsByDoctor: PropTypes.array
}

const mapStateToProps = (state) => {
  return {
    state
  }
}

export default connect(mapStateToProps)(EditTriggerSubCategories)
