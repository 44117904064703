import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    titles: Yup.string(),
    phone: Yup.string()
})

export default validationSchema
