import React, {useState} from 'react'
import ColorSelector from './Color-Selector'
import {toast} from 'react-toastify'
import {tokenSelector} from '../../selectors/auth-selectors'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const PresetSelector = (props) => {
    const {userEmail, userId} = useParams()
    const token = useSelector(tokenSelector)
    const [submitting, setSubmitting] = useState(false)
    const onSubmit = async () => {
        try {
            setSubmitting(true)
            await props.postApplyPreset({
                userid: userId,
                userEmail: userEmail,
                treatmentPlan: props.targetTreatmentPlan,
                targetTreatmentPlan: 0,
                token
            })
            toast(`${props.value.label} has been applied successfully`, {
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                type: 'success'
            })
            setSubmitting(false)
            if (props.onPresetChangeSuccess) {
                props.onPresetChangeSuccess()
            }
        } catch (error) {
            console.log(error)
            setSubmitting(false)
            toast('Error occured. Please retry', {
                type: 'error',
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                autoClose: false
            })
        }
    }

    return (
        <div className='preset-selector-wrapper'>
            <ColorSelector
                className='preset-dd'
                treatmentPlans={props.treatmentPlans}
                onChange={props.onChange}
                value={props.value}
                smallSingle={true}
                showCreatable={false}
            />
            <button onClick={onSubmit} disabled={submitting} type="submit"
                    className="btn btn-primary next-button preset-submit">
                {submitting && <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                {submitting ? <React.Fragment>Applying...</React.Fragment> : <React.Fragment>Apply</React.Fragment>}
            </button>
        </div>
    )
}

export default PresetSelector
