import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import loadingGif from "../../assets/loading.gif";
import endpoints from "../../config/endpoints";
import imageIdGenerator from "../../utilityFunctions/image-id-generator";
import Icon from "../Common/Icon";
import ReactAuthImageZoom from "../UI-helper-components/react-auth-image-zoom";
import ChatLogsModal from "./ChatLogsModal";

const UserSingleCaseTabbed = (props) => {
  const [isModalOn, setIsModalOn] = useState(false);
  const toggle = () => setIsModalOn(!isModalOn);

  const getIcon = (value) => {
    let icon;
    switch (value) {
      case 0:
        icon = "scoreOne";
        break;
      case 1:
        icon = "scoreTwo";
        break;
      case 2:
        icon = "scoreThree";
        break;
      case 3:
        icon = "scoreFour";
        break;
      case 4:
        icon = "scoreFive";
        break;
      default:
        icon = "";
    }
    return icon;
  };

  const getIGAScore = (finalPredictions) => {
    return finalPredictions && finalPredictions.value >= 0
      ? finalPredictions.value
      : "N/A";
  };

  const getItchScore = (input) => {
    const finalReplyJson = input["finalReplyJson"];
    const discomfort = finalReplyJson && finalReplyJson["discomfort"];
    const itch = discomfort && discomfort["itch"];
    return itch && itch.value >= 0 ? itch.value : "N/A";
  };

  const getQolScore = (input) => {
    const finalReplyJson = input["finalReplyJson"];
    const poem = finalReplyJson && finalReplyJson["poem"];
    return poem && poem.value >= 0 ? poem.value : "N/A";
  };

  const getCaseId = () => {
    const obj = queryString.parseUrl(window.location.href);
    return obj.query.caseId;
  };

  const chatLogClickHandler = () => {
    toggle();
  };

  useEffect(() => {
    props.loadCaseDetails(getCaseId());
    return props.singleCaseTabbedUnmount;
  }, []);

  const viewCaseArea = (caseDetails) => {
    let theCase = caseDetails ? caseDetails.data["data"] : [];
    let finalPredictions = theCase ? theCase.finalPredictions : [];
    let imageId = theCase ? theCase.imagePath.reverse()[0] : null;
    let imageIdHeader =
      imageId && imageId["path"] && imageIdGenerator(imageId["path"]);

    return (
      <div>
        <div className="user-case-date">
          <Link
            to={`${window.location.pathname}?currTab=1`}
            className="case-open-link"
          >
            <i className="fa fa-arrow-left"></i>{" "}
            {theCase && moment(theCase.date).format("LL")}
          </Link>
        </div>
        {isModalOn && theCase.chatLog && (
          <ChatLogsModal
            isModalOn={isModalOn}
            toggle={toggle}
            chatLogs={theCase.chatLog}
          />
        )}
        <div className="content-wrapper">
          <div className="user-stats single-case">
            <div className="stats-row border-left border-top border-right">
              <div className="case-single-element">
                <p className="user-stats-result">TIME</p>
                <h6 className="user-stats-title">
                  {moment
                    .utc(theCase.date, "YYYY-MM-DD[T]HH:mm:ss")
                    .local()
                    .format("hh:mm A")}
                </h6>
              </div>
              <div className="case-single-element border-left">
                <p className="user-stats-result">ALERT LEVEL</p>
                <h6 className="user-stats-title">{"N/A"}</h6>
              </div>
            </div>
            <div className="stats-row border-left border-top border-right">
              <div className="case-single-element">
                <p className="user-stats-result">IGA VALUE</p>
                <span className="score-icon user-stats-title">
                  <Icon name={getIcon(getIGAScore(finalPredictions))} />
                  <label className={getIcon(getIGAScore(finalPredictions))}>
                    {getIGAScore(finalPredictions)}
                  </label>
                </span>
              </div>
              <div className="case-single-element border-left">
                <p className="user-stats-result">POSSIBILITY</p>
                <h6 className="user-stats-title">
                  {finalPredictions && finalPredictions.probability.toFixed(5)}
                </h6>
              </div>
            </div>
            <div className="stats-row border-left border-top border-right border-bottom">
              <div className="case-single-element">
                <p className="user-stats-result">ITCH SCORE</p>
                <span className="score-icon user-stats-title">
                  <Icon name={getIcon(getItchScore(theCase))} />
                  <label className={getIcon(getItchScore(theCase))}>
                    {getItchScore(theCase)}
                  </label>
                </span>
              </div>
              <div className="case-single-element border-left">
                <p className="user-stats-result">
                  QOL SCORE{" "}
                  <Icon
                    className="chat-log-icon"
                    name="arrowDropDownCircle"
                    onClick={chatLogClickHandler}
                  />
                </p>
                <span className="score-icon user-stats-title">
                  <Icon name={getIcon(getQolScore(theCase))} />
                  <label className={getIcon(getQolScore(theCase))}>
                    {getQolScore(theCase)}
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div className="image-box-wrapper">
            <ReactAuthImageZoom
              targetUrl={endpoints.GET_IMAGE}
              headers={{
                imageId: imageIdHeader,
                token: props.state.authReducer.token,
                caseId: getCaseId(),
              }}
              defaultImage={loadingGif}
              alt="test"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {props.caseDetails === null ? (
            <div className="square-spinner"></div>
          ) : (
            viewCaseArea(props.caseDetails)
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(withRouter(UserSingleCaseTabbed));