import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import RequireLogin from './HOC/Require-login'
import WithMenu from '../Components/HOC/With-menu'
import ErrorHandler from '../Components/HOC/Error-handler'
import config from './../config/config'

import SignIn from './Sign-in'
import PendingCases from './Pending-cases'
import CompletedCases from './Completed-cases'
import OpenCases from './Open-cases'
import DoctorHistory from './Doctor-history'
import SingleCase from './Single-case'
import Message from './Message'
import Messages from './Messages'
import Dashboard from './Dashboard/Dashboard'
import Users from './Users/Users'
import User from './User/User'
import UserSingleCase from './User/User-single-case'
import Cases from './Cases/Cases'
import CareActivities from './Care-activities/Care-activities'
import Triggers from './Triggers/Triggers'
import InvitePatient from './Invite-patient/InvitePatient'
import UnconnectedUser from './Unconnected-user/Unconnected-user'
import TriggerTracker from './Trigger-tracker/Trigger-tracker'
import TriggerTrackerUnconnected from './Trigger-tracker/Trigger-tracker-unconnected'
import CompleteDoctorReg from './Complete-doctor-registration/Complete-doctor-registration'
import SetPreset from './Set-preset/Set-preset'
import Assistants from './Assistants/Assistants'
import AddAssistant from "./Add-assistant/AddAssistant";
import UpdateAssistant from "./Update-assistant/UpdateAssistant";
import CompleteAssistantRegistration from './Complete-assistant-registration/Complete-assistant-registration'
import CompleteResetPassword from './Complete-reset-password/Complete-reset-password'
import ConsultationPage from './Consultations/ConsultationsPage'
import MessagePresetsPage from './Message-presets/Message-presets'

class Main extends Component {
  render () {
    return (
        <div className='main-wrapper'>
          <Router>
            <Switch>
              <Route path={config.baseRoute} component={SignIn} exact />
              <Route path={`${config.baseRoute}user-case/:caseId`} component={RequireLogin(WithMenu(UserSingleCase))} exact />
              <Route path={`${config.baseRoute}users/page/:pageNumber`} component={RequireLogin(WithMenu(ErrorHandler(Users)))} exact />
              <Route path={`${config.baseRoute}cases/page/:pageNumber`} component={RequireLogin(WithMenu(ErrorHandler(Cases)))} exact />
              <Route path={`${config.baseRoute}activities/page/:pageNumber`} component={RequireLogin(WithMenu(ErrorHandler(CareActivities)))} exact />
              <Route path={`${config.baseRoute}triggers/page/:pageNumber`} component={RequireLogin(WithMenu(ErrorHandler(Triggers)))} exact />
              <Route path={`${config.baseRoute}user/:userId`} component={RequireLogin(WithMenu(ErrorHandler(User)))} exact />
              <Route path={`${config.baseRoute}dashboard`} component={RequireLogin(WithMenu(Dashboard))} exact />
              <Route path={`${config.baseRoute}pending-cases`} component={RequireLogin(WithMenu(PendingCases))} exact />
              <Route path={`${config.baseRoute}open-cases`} component={RequireLogin(WithMenu(OpenCases))} exact />
              <Route path={`${config.baseRoute}completed-cases`} component={RequireLogin(WithMenu(CompletedCases))} exact />
              <Route path={`${config.baseRoute}my-history`} component={RequireLogin(WithMenu(DoctorHistory))} exact />
              <Route path={`${config.baseRoute}single-case/:caseId`} component={RequireLogin(WithMenu(SingleCase))} exact />
              <Route path={`${config.baseRoute}message/:userId`} component={RequireLogin(WithMenu(Message))} exact />
              <Route path={`${config.baseRoute}messages`} component={RequireLogin(WithMenu(Messages))} exact />
              <Route path={`${config.baseRoute}invite-patient`} component={RequireLogin(WithMenu(InvitePatient))} exact />
              <Route path={`${config.baseRoute}unconnected-user/:userEmail`} component={RequireLogin(WithMenu(ErrorHandler(UnconnectedUser)))} exact />
              <Route path={`${config.baseRoute}unconnected-trigger-tracker/:userEmail`} component={RequireLogin(WithMenu(ErrorHandler(TriggerTrackerUnconnected)))} exact />
              <Route path={`${config.baseRoute}trigger-tracker/:userId`} component={RequireLogin(WithMenu(ErrorHandler(TriggerTracker)))} exact />
              <Route path={`${config.baseRoute}set-preset/:userId`} component={RequireLogin(WithMenu(ErrorHandler(SetPreset)))} exact />
              <Route path={`${config.baseRoute}complete-doctor-registration`} component={WithMenu(ErrorHandler(CompleteDoctorReg))} exact />
              <Route path={`${config.baseRoute}complete-assistant-registration`} component={WithMenu(ErrorHandler(CompleteAssistantRegistration))} exact />
              <Route path={`${config.baseRoute}reset-password`} component={WithMenu(ErrorHandler(CompleteResetPassword))} exact />
              <Route path={`${config.baseRoute}assistants/page/:pageNumber`} component={RequireLogin(WithMenu(ErrorHandler(Assistants)))} exact />
              <Route path={`${config.baseRoute}assistants/add`} component={RequireLogin(WithMenu(AddAssistant))} exact />
              <Route path={`${config.baseRoute}assistants/update/:assistantId`} component={RequireLogin(WithMenu(UpdateAssistant))} exact />
              <Route path={`${config.baseRoute}consultations`} component={RequireLogin(WithMenu(ConsultationPage))} exact />
              <Route path={`${config.baseRoute}message-presets`} component={RequireLogin(WithMenu(MessagePresetsPage))} exact />
              <Route component={SignIn} />
            </Switch>
          </Router>
        </div>
    )
  }
}

export default Main