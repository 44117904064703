import React from 'react'

const locationOptions = ["Not Available", "Face/Neck", "Upper Limbs", "Trunk", "Lower Limbs"]
const itchyOptions = ["Not Available", "Not present", "Mild", "Moderate", "Severe", "Unbearable"]
const poemMainOptions = ["Not Available", "Clear or almost clear", "Mild Eczema", "Moderate Eczema", "Severe Eczema", "Very severe Eczema"]
const poemSubOptions = ["Not Available", "No days", "1-2 days", "3-4 days", "5-6 days", "Every day"]
const poemOptions = ["Not Available", "Yes", "No"]
const locationQuestion = 'Region of the body for the analysis'

const ChatLog = (props) => {
    let question
    let answer
    if (props.chatLog.index === 0) {
        question = locationQuestion
        answer = locationOptions[props.chatLog.value]
    } else {
        question = props.chatLog.Q ? props.chatLog.Q : null
        if (props.chatLog.index === 1) {
            question = question.replace('rn ', '')
            answer = itchyOptions[props.chatLog.value]
        } else if (props.chatLog.index === 2) {
            answer = poemOptions[props.chatLog.value]
        } else {
            answer = poemSubOptions[props.chatLog.value]
        }
    }

    if(question) {
        return (
            <div className='chat-log'>
                <div className='chat-log__question'>
                    <span className="chat-log__qa">Q:</span>
                    {question}
                </div>
                <div className='chat-log__answer'>
                    <span className="chat-log__qa">A:</span>
                    {answer}
                </div>
            </div>
        )
    }
    else {
        return (<span></span>)
    }
}

export default ChatLog
