import axios from './axios'
import endpoints from '../config/endpoints'

export default async (body) => {
  try {
    const response = await axios.post(endpoints.DOCTOR_ASSISTANTS, body)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}
