import React from 'react'
import getProp from './User-details-components/Utils/get'

const UserStats = (props) => {
    return (
        <div className='user-stats details-block'>
                <div className='stats-row'>
                    <div className='stats-single-element'>
                        <p className='user-stats-result'>{props.actualCases}</p>
                        <h6 className='user-stats-title'>Total Cases</h6>
                    </div>
                    <div className='stats-single-element'>
                        <p className='user-stats-result'>{props.daysOpened}</p>
                        <h6 className='user-stats-title'>Total Opens</h6>
                    </div>
                    <div className='stats-single-element' style={{
                        borderRightColor: 'transparent'
                    }}>
                        <p className='user-stats-result'>{getProp(props, 'adherenceStats.daysSinceFirstTracked')}</p>
                        <h6 className='user-stats-title'>Total Days</h6>
                    </div>
                </div>
                <div className='stats-row stats-second-row'>
                    <div className='stats-single-element'>
                        <p className='user-stats-result'>{props.totalCareActivities}</p>
                        <h6 className='user-stats-title'>Total Care Logs</h6>
                    </div>
                    <div className='stats-single-element'>
                        <p className='user-stats-result'>{props.totalTriggers}</p>
                        <h6 className='user-stats-title'>Total Triggers</h6>
                    </div>
                    <div className='stats-single-element' style={{
                        borderRightColor: 'transparent'
                    }}>
                        <p className='user-stats-result'>{getProp(props, 'adherenceStats.loggedDays')} days</p>
                        <h6 className='user-stats-title'>Total Logged</h6>
                    </div>
                </div>  
        </div>
    )
}

export default UserStats