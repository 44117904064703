import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Chart from "react-apexcharts";
import RangeFilterTabs from './Range-filter-tabs';
import { getStartAndEndFromRange, logAndReturn } from './utils';
import { getCareActivitiesHistory, getCaseHistory, getTriggerHistory } from '../../../Api/History-and-insights/history-and-insights-apis';
import moment from 'moment';

class CareRoutineVsTriggers extends Component <any, any>{

    state = {
        isLoading: true,
        activeRangeFilter: 2,
        series: [{
            name: 'IGA',
            data: []
        }],
        options: {
            chart: {
                type: 'bar',
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '2%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2
            },
            xaxis: {
                show: true,
                type: "datetime",
                min: moment(getStartAndEndFromRange(2).start).toDate().getTime(),
                max: moment(getStartAndEndFromRange(2).end).toDate().getTime(),
                labels: {
                    show: true,
                    hideOverlappingLabels: true
                },
                tickPlacement: 'on'
            },
            yaxis: {
                floating: true,
                show: true,
                min: 0,
                max: 4,
                tickAmount: 4,
                labels: {
                    show: true,
                    formatter: (val) => {
                        return val.toFixed(0)
                    },
                    style: {
                        fontSize: '14px'
                    }
                },
                title: {
                    text: 'IGA'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        },
        activitiesRangeBarSeries: [
            {
                name: 'Activities',
                data: []
            },
        ],
        triggersRangeBarSeries: [
            {
                name: 'Triggers',
                data: [
                ]
            },
        ],
        activitiesRangeBarOptions: {
            chart: {
                type: 'rangeBar',
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            xaxis: {
                show: true,
                type: "datetime",
                min: moment(getStartAndEndFromRange(2).start).toDate().getTime(),
                max: moment(getStartAndEndFromRange(2).end).toDate().getTime(),
                labels: {
                    show: true
                }
            },
            yaxis: {
                show: true,
                floating: true,
                labels: {
                    formatter: (val) => {
                        return val
                    }
                }
            },
            stroke: {
                width: 1
            },
            fill: {
                type: 'solid',
                opacity: 0.6
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            }
        },
    }

    componentDidMount () {
        this.props.subscribeToRangeChange(this.RangeFilterClickHandler)
        this.loadData(this.state.activeRangeFilter)
    }

    careTriggerToSeries = (dataList = [], type, dateRange) => {
        const onDataList = dataList.filter(data => data.isOn && moment(`${data.year}-${data.month}-${data.day}`).isSameOrAfter(moment(dateRange.start)))
        const onDataListSorted = onDataList.sort((a, b) => {
            return Number(`${a.day}${a.month}${a.year}`) - Number(`${b.day}${b.month}${b.year}`)
        })
        let dataObjectStore = {}
        onDataListSorted.forEach(data => {
            let key = ''
            if (type === 'trigger') {
                key = 'triggerLabel'
            } else {
                key = 'careActivityLabel'
            }
            if(dataObjectStore[data[key]]) {
                dataObjectStore[data[key]].push(new Date(`${data.year}-${data.month}-${data.day}`))
            } else {
                dataObjectStore[data[key]] = [new Date(`${data.year}-${data.month}-${data.day}`)]
            }
        })
        let dataListRange = []
        Object.keys(dataObjectStore).forEach(objStoreKey => {
            let temp = {start: null}
            let careTriggerDates =  dataObjectStore[objStoreKey]
            let i = 0
            while (i < careTriggerDates.length) {
                let currDate = moment(careTriggerDates[i])
                let nextDate = moment(careTriggerDates[i+1]? careTriggerDates[i+1]: '2150-01-01')
                if(nextDate.diff(currDate, 'day') === 1) {
                    if(!temp.start) {
                        temp.start = currDate
                    }
                } else {
                    if (temp.start) {
                        dataListRange.push({
                            x: objStoreKey,
                            y: [temp.start.startOf('day').toDate().getTime(), currDate.endOf('day').toDate().getTime()]
                        })
                    } else {
                        dataListRange.push({
                            x: objStoreKey,
                            y: [currDate.startOf('day').toDate().getTime(), currDate.endOf('day').toDate().getTime()]
                        })
                    }
                    temp = {start: null}
                }
                i++
            }
        })
        return dataListRange
    }

    loadData = async (activeRange) => {
        this.setState(() => ({
            isLoading: true
        }))
        const range = getStartAndEndFromRange(activeRange)
        const caseHistory = await getCaseHistory(this.props.userid, this.props.token, range.start, range.end, 0)
        this.setState(() => ({
            series: [{
                name: 'Case History',
                data: this.caseHistoryToSeries(caseHistory.data.caseHistory)
            }]
        }))
        const activities = await getCareActivitiesHistory(this.props.userid, this.props.token, range.start, range.end)
        const triggers = await getTriggerHistory(this.props.userid, this.props.token, range.start, range.end)
        
        this.setState(() => ({
            triggersRangeBarSeries: [{
                name: 'Triggers',
                data: this.careTriggerToSeries(triggers.data.triggerHistory, 'trigger', range)
            }],
            activitiesRangeBarSeries: [{
                name: 'Care Activities',
                data: this.careTriggerToSeries(activities.data.careActivitiesHistory, 'activities', range)
            }]
        }))
        this.setState(() => ({
            isLoading: false
        }))
    }

    caseHistoryToSeries = (caseHistories = []) => {
        let datesData = {}
        caseHistories.forEach(history => {
            datesData[`${history.month}-${history.day}-${history.year} GMT`] = history.value
        })
        return Object.keys(datesData).map(dateKey => {
            return {
                x: dateKey,
                y: datesData[dateKey]
            }
        })
    }

    RangeFilterClickHandler = async (activeRange) => {
        this.setState(() => {
            const newState = {
                activeRangeFilter: activeRange,
                options: {
                    ...this.state.options,
                    xaxis: {
                        ...this.state.options.xaxis,
                        min: moment(getStartAndEndFromRange(activeRange).start).toDate().getTime(),
                        max: moment(getStartAndEndFromRange(activeRange).end).toDate().getTime(),
                    }
                },
                activitiesRangeBarOptions: {
                    ...this.state.activitiesRangeBarOptions,
                    xaxis: {
                        ...this.state.activitiesRangeBarOptions.xaxis,
                        min: moment(getStartAndEndFromRange(activeRange).start).toDate().getTime(),
                        max: moment(getStartAndEndFromRange(activeRange).end).toDate().getTime()
                    }
                }
            }
            if (activeRange === 0) {
                newState['options']['xaxis']['labels']['hideOverlappingLabels'] = false
            } else {
                newState['options']['xaxis']['labels']['hideOverlappingLabels'] = true
            }
            return newState
        })
        await this.loadData(activeRange)
        return true
    }

    getCharts = () => {
        return (
            <React.Fragment>
            <div className='single-chart'>
                {this.state.series[0].data.length > 0 && <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    />}
                {this.state.series[0].data.length <= 0 && !this.state.isLoading && 'No IGA Data'}
            </div>
            <div className="single-chart activities-range">
                {this.state.activitiesRangeBarSeries[0].data.length > 0 && (
                    <React.Fragment>
                        <h4 className='chart-heading'>Activities</h4>
                        <Chart
                            options={this.state.activitiesRangeBarOptions}
                            series={this.state.activitiesRangeBarSeries}
                            type="rangeBar"
                        />
                    </React.Fragment>
                )}
                {this.state.activitiesRangeBarSeries[0].data.length <= 0 && !this.state.isLoading && 'No activities'}
            </div>
            <div className="single-chart triggers-range">
                {this.state.triggersRangeBarSeries[0].data.length > 0 && (
                    <React.Fragment>
                        <h4 className="chart-heading">Triggers</h4>
                        <Chart
                            options={this.state.activitiesRangeBarOptions}
                            series={this.state.triggersRangeBarSeries}
                            type="rangeBar"
                        />
                    </React.Fragment>
                )}
                {this.state.triggersRangeBarSeries[0].data.length <= 0 && !this.state.isLoading && 'No activities'}
            </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <div id="chart" style={{
                width: '550px'
            }}>
                <div className="chart-header">
                    <div className="chart-title-holder">
                        Care Routine vs Triggers
                    </div>
                    {/* <RangeFilterTabs
                        btnList={this.props.filterBtnList}
                        activeButtonId={this.state.activeRangeFilter}
                        handleButtonClick={this.RangeFilterClickHandler}
                    /> */}
                </div>
                {this.getCharts()}
            </div>
        )
    }
}

export default withRouter(CareRoutineVsTriggers)
