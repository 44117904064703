import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { CARE_CATEGORIES } from './CurrentCareRoutine'

const categories: Category[] = Object.keys(CARE_CATEGORIES).map(objKey => {
  return {
    careCategoryId: objKey,
    careCategoryLabel: CARE_CATEGORIES[objKey]
  }
})

type Category = {
  careCategoryId: string,
  careCategoryLabel: string
}

interface CategoriesModalProps {
  toggle: () => void,
  isModalOn: boolean,
  onSubmit: (category: Category) => Promise<any>,
  customActivityLabel: string
}

const CategoriesModal = (props: CategoriesModalProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const getCategoryById = (categoryId: string): Category  => {
    return categories.find(category => category.careCategoryId === categoryId )
  }
  return (
    <div>
        <Modal isOpen={props.isModalOn} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>Category for {props.customActivityLabel}</ModalHeader>
            <ModalBody>
            <select
              value={selectedCategory}
              onChange={(e) => {
                const categoryId = e.target.value
                setSelectedCategory(categoryId)
              }} className='form-control' id='category'
            >
              <option disabled value=''>Select a category</option>
              {categories.map(category => {
                return <option value={category.careCategoryId}>{category.careCategoryLabel}</option>
              })}
            </select>
            </ModalBody>
            <ModalFooter>
            <Button color="primary" onClick={() => {
              if (!selectedCategory) {
                return 
              }
              const cateogry = getCategoryById(selectedCategory)
              props.onSubmit(cateogry)
            }}>Submit</Button>
            <Button color="secondary" onClick={props.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
    </div>
  )
}

export default CategoriesModal
