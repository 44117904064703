import React from "react";

const ImageModal = ({ show, imagePath, token, individualSeverityScores }) => {
    const showHideClassName = show ? "display-block" : "display-none";

    const { lichenification, redness, scratching, thickness } = individualSeverityScores

    const getClassBySeverityValue = (severity) => {
        if (severity && severity.value === 0) {
            return 'clear pill'
        } else if (severity && severity.value === 1) {
            return 'almost_Clear pill'
        } else if (severity && severity.value === 2) {
            return 'mild pill'
        } else if (severity && severity.value === 3) {
            return 'severe pill'
        } else if (severity && severity.value === 4) {
            return 'moderate pill'
        } else if (severity && severity.value === 5) {
            return 'very_severe pill'
        }
    }

    return (
        <div className={showHideClassName}>
            <div style={{ width: '100%' }}>
                <div className="img-box-modal">
                    {show && <img className="img-box-modal"
                        src={imagePath}
                        alt='_Image'
                    />}
                </div>
                <table className="thorough-table-modal">
                    <tr>
                        <td>Redness</td>
                        <td><span className={getClassBySeverityValue(redness)}>{redness && redness.label}</span></td>
                    </tr>
                    <tr>
                        <td>Thickness</td>
                        <td><span className={getClassBySeverityValue(thickness)}>{thickness && thickness.label}</span></td>
                    </tr>
                    <tr>
                        <td>Scratching</td>
                        <td><span className={getClassBySeverityValue(scratching)}>{scratching && scratching.label}</span></td>
                    </tr>
                    <tr>
                        <td>Lichenification</td>
                        <td><span className={getClassBySeverityValue(lichenification)}>{lichenification && lichenification.label}</span></td>
                    </tr>
                </table>
            </div>
        </div>
    );
};

export default ImageModal