import { makeCall } from '../apiFactory'
import endpoints from '../../config/endpoints'
import config from '../../config/config'

export const postConfirmAssistant = ({
  password,
  firstName,
  lastName,
  address,
  email,
  token,
  server,
  phone
}) => {
  return makeCall({
    url: server === config.ECZEMALESS_APP ? endpoints.ECZEMALESS_CONFIRM_ASSISTANT : endpoints.PSORIASIS_CONFIRM_ASSISTANT,
    method: 'post',
    body: {
      password,
      firstName,
      lastName,
      address,
      email,
      token,
      phoneNumber: phone
    }
  })
}

export const postConfirmResetPassword = ({
  password,
  token,
  email
}) => {
  return makeCall({
    url: endpoints.CONFIRM_RESET_PASSWORD,
    method: 'post',
    body: {
      password,
      email,
      token
    }
  })
}

export const postConfirmDoctorAccount = ({
  password,
  firstName,
  lastName,
  titles,
  email,
  token,
  phone
}) => {
  return makeCall({
    url: endpoints.CONFIRM_DOCTOR_ACCOUNT,
    method: 'post',
    body: {
      password,
      firstName,
      lastName,
      titles,
      email,
      token,
      phone
    }
  })
}

export const disconnectPatient = ({
  token,
  patientId
}) => {
  return makeCall({
    url: endpoints.DISCONNECT_PATIENT,
    method: 'delete',
    headers: {
      token,
      userid: patientId
    }
  })
}

export const getDoctorProfile = ({
  token
}) => {
  return makeCall({
    url: endpoints.DOCTOR_PROFILE_NEW,
    method: 'get',
    headers: {
      token
    }
  })
}

export const getCareAllData = ({
  token,
  userid
}) => {
  return makeCall({
    url: endpoints.CARE_ALL_DATA,
    method: 'get',
    headers: {
      token,
      userid
    }
  })
}