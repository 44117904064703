import endpoints from '../config/endpoints'
import { makeCall } from './apiFactory'

export const getMessagePresets = ({
  token
}) => {
  return makeCall({
    url: endpoints.MESSAGE_PRESETS,
    method: 'get',
    headers: {
      token
    }
  })
}

export const deleteMessagePreset = ({
  token,
  id
}) => {
  return makeCall({
    url: endpoints.MESSAGE_PRESETS,
    method: 'delete',
    headers: {
      token,
      id
    }
  })
}

export const createMessagePresets = ({
  presetName,
  presetBody,
  token,
}) => {
  return makeCall({
    url: endpoints.MESSAGE_PRESETS,
    method: 'post',
    body: {
      token,
      data: {
        presetName,
        presetBody
      }
    }
  })
}
