import axios from './axios'
import endpoints from '../config/endpoints'

export default async (token, assistantId) => {

    const headers = {
        headers: {
            token,
            assistantId
        }
    }

    try {
        const response = await axios.delete(endpoints.DOCTOR_ASSISTANTS, headers)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}
