const roles = {
  ADMIN: 'ADMIN',
  DOCTOR: 'DOCTOR',
  DOCTOR_ASSISTANT: 'DOCTOR_ASSISTANT',
  REVIEW: 'REVIEW',
  STUDY: 'STUDY',
  STUDY_USER: 'STUDY_USER',
  REVIEW_USER: 'REVIEW_USER'
}

export const actions = {
  view_unread_messages: 'fetch_unread_messages',
  raw_query: 'raw_query',
  view_stats: 'view_stats',
  time_tracker: 'time_tracker',
  view_messages: 'view_messages',
  view_all_cases: 'view_all_cases',
  view_all_activities: 'view_all_activities',
  view_all_triggers: 'view_all_triggers',
  view_case_details_doctor_name: 'case_details_doctor_name',
  view_case_list_doctor_name: 'case_list_doctor_name',
  view_case_list_doctor_country: 'case_list_doctor_country',
  view_user_list_country: 'user_list_country',
  view_user_list_location: 'user_list_location',
  view_doctor_assistants: 'view_doctor_assistants',
  view_doctor_presets: 'view_doctor_presets',
  update_care_activity: 'update_care_activity',
  view_notes: 'view_notes',
  create_patient: 'create_patient',
  view_review_data: 'view_review_data',
  download_report: 'download_report',
  download_anon_report: 'download_anon_report',
  cancel_invite: 'cancel_invite',
  disconnect_patient: 'disconnect_patient',
  signup_date: 'signup_date',
  view_consultations: 'view_consultations',
  message_presets: 'message_presets'
}

const rules = {
  [roles.ADMIN]: {
    extends: false,
    static: {
      [actions.view_unread_messages]: false,
      [actions.raw_query]: true,
      [actions.view_stats]: true,
      [actions.time_tracker]: false,
      [actions.view_messages]: false,
      [actions.view_all_cases]: true,
      [actions.view_all_activities]: true,
      [actions.view_all_triggers]: true,
      [actions.view_case_details_doctor_name]: true,
      [actions.view_case_list_doctor_name]: true,
      [actions.view_case_list_doctor_country]: true,
      [actions.view_user_list_country]: true,
      [actions.view_user_list_location]: true,
      [actions.view_doctor_assistants]: false,
      [actions.view_doctor_presets]: false,
      [actions.update_care_activity]: false,
      [actions.view_notes]: true,
      [actions.create_patient]: false,
      [actions.view_review_data]: true,
      [actions.download_report]: true,
      [actions.cancel_invite]: true
    },
    dynamic: {}
  },
  [roles.DOCTOR]: {
    extends: false,
    static: {
      [actions.raw_query]: false,
      [actions.view_stats]: false,
      [actions.time_tracker]: true,
      [actions.view_unread_messages]: true,
      [actions.view_messages]: true,
      [actions.view_all_cases]: false,
      [actions.view_all_activities]: false,
      [actions.view_all_triggers]: false,
      [actions.view_case_details_doctor_name]: false,
      [actions.view_case_list_doctor_name]: false,
      [actions.view_case_list_doctor_country]: false,
      [actions.view_user_list_country]: true,
      [actions.view_user_list_location]: false,
      [actions.view_doctor_assistants]: true,
      [actions.view_doctor_presets]: true,
      [actions.update_care_activity]: true,
      [actions.view_notes]: true,
      [actions.create_patient]: true,
      [actions.view_review_data]: true,
      [actions.download_report]: true,
      [actions.cancel_invite]: true,
      [actions.disconnect_patient]: true,
      [actions.view_consultations]: true,
      [actions.message_presets]: true
    },
    dyanmic: {}
  },
  [roles.DOCTOR_ASSISTANT]: {
    extends: roles.DOCTOR,
    static: {
      [actions.view_doctor_assistants]: false,
      [actions.update_care_activity]: false,
      [actions.view_notes]: true,
      [actions.download_report]: true,
      [actions.cancel_invite]: false,
      [actions.disconnect_patient]: false,
      [actions.view_user_list_country]: true,
      [actions.message_presets]: false
    },
    dynamic: {}
  },
  [roles.STUDY]: {
    extends: roles.DOCTOR,
    static: {
      [actions.view_unread_messages]: false,
      [actions.view_messages]: false,
      [actions.view_doctor_presets]: false,
      [actions.time_tracker]: false,
      [actions.view_notes]: false,
      [actions.update_care_activity]: false,
      [actions.create_patient]: false,
      [actions.view_review_data]: true,
      [actions.cancel_invite]: false,
      [actions.view_consultations]: false,
      [actions.message_presets]: false,
      [actions.download_anon_report]: true
    },
    dynamic: {}
  },
  [roles.STUDY_USER]: {
    extends: roles.DOCTOR_ASSISTANT,
    static: {
      [actions.view_unread_messages]: false,
      [actions.view_messages]: false,
      [actions.view_doctor_presets]: false,
      [actions.time_tracker]: false,
      [actions.view_notes]: false,
      [actions.create_patient]: false,
      [actions.view_review_data]: true,
      [actions.view_consultations]: false,
      [actions.download_anon_report]: true
    },
    dynamic: {}
  },
  [roles.REVIEW]: {
    extends: roles.DOCTOR,
    static: {
      [actions.view_unread_messages]: false,
      [actions.view_messages]: false,
      [actions.view_doctor_presets]: false,
      [actions.time_tracker]: false,
      [actions.view_notes]: false,
      [actions.update_care_activity]: false,
      [actions.create_patient]: false,
      [actions.view_review_data]: true,
      [actions.download_report]: false,
      [actions.download_anon_report]: true,
      [actions.cancel_invite]: false,
      [actions.signup_date]: true,
      [actions.view_consultations]: false,
      [actions.message_presets]: false
    },
    dynamic: {}
  },
  [roles.REVIEW_USER]: {
    extends: roles.DOCTOR_ASSISTANT,
    static: {
      [actions.view_unread_messages]: false,
      [actions.view_messages]: false,
      [actions.view_doctor_presets]: false,
      [actions.time_tracker]: false,
      [actions.view_notes]: false,
      [actions.create_patient]: false,
      [actions.view_review_data]: true,
      [actions.download_report]: false,
      [actions.download_anon_report]: true,
      [actions.signup_date]: true,
      [actions.view_consultations]: false
    },
    dynamic: {}
  }
}

export const check = (role, action, data) => {
  const permissions = rules[role]
  if (!permissions) {
    // role is not present in the rules
    return false
  }

  const staticPermissions = permissions.static

  if (staticPermissions && staticPermissions[action] !== undefined) {
    // static rule present has a value
    return staticPermissions[action]
  }

  const dynamicPermissions = permissions.dynamic

  if (dynamicPermissions && dynamicPermissions[action]) {
    const permissionCondition = dynamicPermissions[action]
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false
    }
    return permissionCondition(data)
  }
  if (permissions.extends) {
    return check(permissions.extends, action, data)
  }
  return false
}

export default roles
