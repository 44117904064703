import React, {Component} from 'react'
import {Table} from 'reactstrap'
import AssistantListHeading from './Assistant-list-heading'

class AssistantList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let coverLoaderClass = ''
        if (this.props.isCoverLoaderOn) {
            coverLoaderClass = 'cover-loader'
        }
        return (
            <div className='users-list-v2 table-responsive'>
                <Table className='users-list-table-v2'>
                    <AssistantListHeading/>
                    <tbody className={`${coverLoaderClass}`}>
                    {this.props.assistantList.map((item, index) => {
                        return <tr className='content-list-group-item' key={item._id}>
                            <td className='align-middle text-center'>{item.firstName}</td>
                            <td className='align-middle text-center'>{item.lastName}</td>
                            <td className='align-middle text-center'>{item.email}</td>
                            <td className='align-middle text-center'>{item.phoneNumber}</td>
                            <td className='align-middle text-center'>{item.address}</td>
                            <td className='align-middle text-center cursor-pointer'
                                onClick={() => this.props.updateAssistant(item._id)}><i className="fa fa-edit"
                                                                                        aria-hidden="true"></i></td>
                            <td className='align-middle text-center cursor-pointer'
                                onClick={() => this.props.deleteAssistant(item._id)}><i className="fa fa-trash"
                                                                                        aria-hidden="true"></i></td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default AssistantList
