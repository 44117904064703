export const addToken = (token) => ({
  type: 'ADD_TOKEN',
  payload: token
})

export const addTokenPsoriasis = (tokenPsoriasis) => ({
  type: 'ADD_TOKEN_PSORIASIS',
  payload: tokenPsoriasis
})

export const addTokenEczemaless = (tokenEczemaless) => ({
  type: 'ADD_TOKEN_ECZEMALESS',
  payload: tokenEczemaless
})

export const setIsAuthenticated = (isAuthenticated) => ({
  type: 'SET_IS_AUTHENTICATED',
  payload: isAuthenticated
})

export const setIsAdmin = (isAdmin) => ({
  type: 'SET_IS_ADMIN',
  payload: isAdmin
})

export const setUserRole = (role) => ({
  type: 'SET_USER_ROLE',
  payload: role
})
