import React, { useState, useMemo } from 'react'
const noop = () => {}

const PageNavigator = ({
  currentPageNumber,
  goNext,
  goPrevious,
  submitPageNumber,
  isNextDisabled,
  isPrevDisabled
}) => {
  const [currentPageNumberState, setCurrentPageNumberState] = useState(currentPageNumber)
  var prevDisabledClass = ''
  var nextDisabledClass = ''
  if(isNextDisabled) {
    nextDisabledClass = 'disabled'
  }
  if(isPrevDisabled) {
    prevDisabledClass = 'disabled'
  }
  return <div className='page-navigator-wrapper'>
    <div onClick={isPrevDisabled? noop : goPrevious} className={`next-prev-btn btn btn-primary ${prevDisabledClass}`}>Go Prev</div>
      <input onChange={(e) => {
        setCurrentPageNumberState(Number(e.target.value))
      }} onKeyDown={(e) => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if(code == 13) {
          submitPageNumber(currentPageNumberState)
        }
      }} type="number" min={1} value={currentPageNumberState} />
    <div onClick={isNextDisabled? noop : goNext} className={`next-prev-btn btn btn-primary ${nextDisabledClass}`}>Go Next</div>
  </div>
}

export default PageNavigator
