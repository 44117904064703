import axios from './axios'
import endpoints from '../config/endpoints'

export default async (token, assistantId) => {
  const headers = {
    headers: {
      token
    }
  }

  try {
    const response = await axios.get(`${endpoints.DOCTOR_ASSISTANTS}/${assistantId}`, headers)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}
