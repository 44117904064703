import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { tokenSelector } from '../../selectors/auth-selectors'
// import ConsultationRow from './ConsultationRow'
import {
  createMessagePresets,
  deleteMessagePreset,
  getMessagePresets
} from '../../Api/message-presets'
import MessagePresetRow from './Message-preset-row'
import moment from 'moment'
import AddPresetModal from './Message-preset-modal'

const MessagePresetsPage = () => {
  const [loaded, setLoaded] = useState(false)
  const [presets, setPresets] = useState([])
  const [isModalOn, setIsModalOn] = useState(false)
  const token = useSelector(tokenSelector)
  useEffect(() => {
    loadPresets()
  }, [])
  const toggleModal = () => {
    setIsModalOn(!isModalOn)
  }
  const loadPresets = () => {
    setLoaded(false)
    getMessagePresets({ token }).then(response => {
      setLoaded(true)
      setPresets(response.data.data)
    })
  }
  const deletePreset = async id => {
    const shouldDelete = window.confirm('Do you want to delete this preset?')
    if (shouldDelete) {
      await deleteMessagePreset({
        token: token,
        id: id
      })
      loadPresets()
    }
  }
  const listBody = () => {
    if (!loaded) {
      return <tbody>Loading...</tbody>
    }
    if (loaded && presets.length < 1) {
      return <tbody>No consultations found</tbody>
    }
    return (
      <tbody>
        {presets.map(preset => {
          return (
            <MessagePresetRow
              createdAt={moment(preset.createdAt).format('ll')}
              messageContent={preset.presetBody}
              presetName={preset.presetName}
              deletePreset={deletePreset}
              id={preset.id}
            />
          )
        })}
      </tbody>
    )
  }

  return (
    <div className='consultations-sections'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px'
        }}
      >
        <h3 class='screen-header__heading'>Presets</h3>
        <div className='cta-button-primary' onClick={toggleModal}>
          <span className='cta-button-primary__icon'>
            <i className='fa fa-plus' aria-hidden='true'></i>
          </span>
          <span className='cta-button-primary__text'>Add Preset</span>
        </div>
      </div>
      <AddPresetModal
        isModalOn={isModalOn}
        toggle={toggleModal}
        onSubmit={async ({ presetName, presetBody }) => {
          await createMessagePresets({
            token,
            presetName,
            presetBody
          })
          toggleModal()
          loadPresets()
        }}
      />
      <table
        className='table table-bordered table-striped'
        style={{
          backgroundColor: '#fff'
        }}
      >
          <colgroup>
            <col style={{
              width: '15%'
            }}/>
           <col style={{
              width: '25%'
            }}/>
           <col style={{
              width: '50%'
            }}/>
            <col style={{
              width: '10%'
            }}/>
          </colgroup>  
        <thead>
          <tr>
            <th scope='col'>Created At</th>
            <th scope='col'>Preset Name</th>
            <th scope='col'>Message Content</th>
            <th />
          </tr>
        </thead>
        {listBody()}
      </table>
    </div>
  )
}

export default MessagePresetsPage
