import React from 'react'
import { CTAButtonProps } from './interfaces'

const CTAButton = (props: CTAButtonProps) => {
  return (
    <div onClick={props.onClick} className='cta-button-primary' style={props.style}>
      <span className='cta-button-primary__icon'>{props.icon}</span>
      <span className='cta-button-primary__text'>{props.text}</span>
    </div>
  )
}

export default CTAButton
