import axios from "./axios";
import endpoints from "../config/endpoints";

export default async (email, password) => {
  const loginBody = {
    email,
    password,
  };
  try {
    const response = await axios.post(endpoints.REGISTERED_DOCTOR_PSORIASIS_LOGIN_API, loginBody);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};