import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Chart from "react-apexcharts";
import EffectivenessChartTabs from "./Effectivenessc-chart-tabs";
import RangeFilterTabs from "./Range-filter-tabs";
import {getCaseHistory} from "../../../Api/History-and-insights/history-and-insights-apis";
import {actions, check} from "../../../config/roles";
import { getStartAndEndFromRange, logAndReturn } from './utils';
import moment from 'moment';
import { RangeChangeSubscriber } from '.';


interface EffectivenessChartProps {
    filterBtnList?: any;
    activeFilter?: any;
    filterClickHandler?: any;
    token?: string;
    userid?: string;
    subscribeToRangeChange: (RangeChangeSubscriber) => any;
}

class EffectivenessChart extends Component<EffectivenessChartProps> {

    state = {
        loadingComplete: false,
        activeCaseFilterId: 0,
        activeRangeFilter: 2,
        btnList: [
            {
                id: 0,
                title: 'Severity',
                isDefault: true,
            },
            {
                id: 1,
                title: 'Itch',
                isDefault: false,
            },
            {
                id: 4,
                title: 'Quality of Life',
                isDefault: false,
            },
        ],
        series: [],
        options: {
            chart: {
                height: 350,
                width: 550,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                show: true,
                type: "datetime",
                min: moment(getStartAndEndFromRange(2).start).toDate().getTime(),
                max: moment(getStartAndEndFromRange(2).end).toDate().getTime(),
                labels: {
                    show: true
                },
                tickPlacement: 'on'
            },
            yaxis: {
                show: true,
                tickAmount: 4,
                min: 0,
                max: 4,
                labels: {
                    show: true,
                    minWidth: 70,
                    formatter: function(val) {
                        if(val === 0) {
                            return 'Clear'
                        }
                        else if(val === 1) {
                            return 'Almost Clear'
                        }
                        else if(val === 2) {
                            return 'Mild'
                        }
                        else if(val === 3) {
                            return 'Moderate'
                        }
                        else if(val === 4) {
                            return 'Severe'
                        }
                    },
                    style: {
                        fontSize: '14px'
                    }
                }
            },
            markers: {
                size: 4,
            },
            tooltip: {
                // x: {
                //     formatter: function (val, { series, seriesIndex, dataPointIndex}) {
                //         const data = series[0]
                //         return data[dataPointIndex]
                //     }
                // }
            }
        }
    }

    componentDidMount() {
        this.props.subscribeToRangeChange(this.RangeFilterClickHandler)
        this.loadCaseHistoryData(this.state.activeRangeFilter, this.state.activeCaseFilterId)
    }

    caseHistoryToSeries = (caseHistories = []) => {
        return caseHistories.map(history => {
            return {
                y: history.value,
                x: `${history.month}-${history.day}-${history.year} GMT`
            }
        }).sort((a, b) => {
            a['x'] = a.x.split('-').join('/')
            b['x'] = b.x.split('-').join('/')
            let aTime = moment(a.x.replace(' GMT', '')).valueOf()
            let bTime = moment(b.x.replace(' GMT', '')).valueOf()
            return bTime - aTime
        })
    }

    loadCaseHistoryData = async (rangeFilter, caseFilter) => {
        const range = getStartAndEndFromRange(rangeFilter)
        const fromDate = range.start
        const toDate = range.end
        const caseHistory = await getCaseHistory(this.props.userid, this.props.token, fromDate, toDate, caseFilter)
        this.setState(() => ({
            series: [{
                name: 'Case history',
                data: this.caseHistoryToSeries(caseHistory.data.caseHistory)
            }],
            loadingComplete: true,
        }))
    }

    CaseFilterClickHandler = async (caseFilterId) => {
        this.setState(() => {
            return {
                activeCaseFilterId: caseFilterId,
                
            }
        })
        await this.loadCaseHistoryData(this.state.activeRangeFilter, caseFilterId)
    }

    RangeFilterClickHandler = async (activeRange) => {
        this.setState(() => {
            return {
                activeRangeFilter: activeRange,
                options: {
                    ...this.state.options,
                    xaxis: {
                        ...this.state.options.xaxis,
                        min: moment(getStartAndEndFromRange(activeRange).start).toDate().getTime(),
                        max: moment(getStartAndEndFromRange(activeRange).end).toDate().getTime(),
                    }
                }
            }
        })
        await this.loadCaseHistoryData(activeRange, this.state.activeCaseFilterId)
        return true
    }

    render() {
            if(this.state.loadingComplete) {
                return (
                <div id="chart">
                    <div className="chart-header">
                        <div className="chart-title-holder">
                            Effectiveness
                        </div>
                        {/* <RangeFilterTabs
                            btnList={this.props.filterBtnList}
                            activeButtonId={this.state.activeRangeFilter}
                            handleButtonClick={this.RangeFilterClickHandler}
                        /> */}
                    </div>
                    <EffectivenessChartTabs
                        btnList={this.state.btnList}
                        activeCaseFilterId={this.state.activeCaseFilterId}
                        handleButtonClick={this.CaseFilterClickHandler}
                    />
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="line"
                        width="550"
                    />
                </div>
                )
            }
            else {
                return (
                    <div>No Data Available</div>
                )
            }
    }
}

export default withRouter(EffectivenessChart)
