import React, { Component } from 'react';

export default class ErrorBoundary extends Component {

  constructor(props) {
    super(props)
  }

  state = {
    hasError: false
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    }
  }

  componentDidCatch(error) {
    console.error(error)
  }

  render() {
    if(this.state.hasError) {
      if(this.props.errFallback) {
        return this.props.errFallback
      } else {
        return <div className='component-error'>Error</div>
      }
    }
    return this.props.children
  }
}

