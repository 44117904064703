import React, { useEffect, useState } from 'react'
import { getUsrConsultations } from '../../Api/Consultations/apis'
import ConsultationCard from './ConsultationCard'
import './style.scss'

const Consultations = (props) => {
  const [consultations, setConsultations] = useState([])
  useEffect(() => {
    getUsrConsultations({
      token: props.token,
      userid: props.userId
    }).then(response => {
      setConsultations(response.data.data)
    })
  }, [])
  return (
    <div className='consultations-sections'>
      <h6 className='consultations-main-heading'>Consultations</h6>
      {consultations.map(consultation => {
        return (
          <ConsultationCard
            history={props.history}
            token={props.token}
            key={consultation.id}
            {...consultation}
          />
        )
      })}
    </div>
  )
}

export default Consultations
