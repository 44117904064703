import React, { useEffect, useState } from 'react'
import getReviewData from '../../Api/get-review-data'
import { Table } from 'reactstrap'

const ReviewUserData = ({
  token,
  userid
}) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  useEffect(() => {
    getReviewData(userid, token)
      .then(res => {
        setData(res.data.data)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  if (data.length < 1) return null

  return (
    <div style={{
      marginTop: '27px'
    }}
    >
      {data.map((singleObj, index) => {
        return (
          <div key={index}>
            <h4 className='review-data-type'>{singleObj.type} user info</h4>
            <Table bordered striped>
              <thead>
                <tr><td><strong>Question</strong></td><td><strong>Answer</strong></td></tr>
              </thead>
              <tbody>
                {Object.values(singleObj.values).map((valueObj, index) => {
                  const questionKey = `q${index + 1}`;
                  const answerKey = `a${index + 1}`;
                  const optionKey = `op${index + 1}`;

                  let answerContent;
                  if (typeof valueObj[answerKey] === 'object') {
                    answerContent = Object.entries(valueObj[answerKey]).map(([subKey, subValue]) => (
                      <div key={subKey}>
                        <strong>{subKey}</strong>: {subValue}
                      </div>
                    ));
                  } else {
                    answerContent = valueObj[answerKey];
                  }
                  return (
                    <>
                      {answerContent && <tr key={index}>
                        <td>{valueObj[questionKey]}</td>
                        <td>
                          {answerContent}
                          <div><strong>Comment:</strong> {valueObj[optionKey]}</div>
                        </td>
                      </tr>}
                    </>
                  );
                })}
              </tbody>

            </Table>
          </div>
        )
      })}
    </div>
  )
}

export default ReviewUserData