import React, { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { postCareActivitiesList, postCareActivitiesTracked } from '../../Api/Manage-tracking/manage-tracking'
import { getCareAllData } from '../../Api/misc-apis/misc-apis'
import CategoriesModal from './CategoriesModal'

// truncated version of an activity stored in the db. It does not contain the category id
type ActivityMinimal = {
  careActivityId: string,
  careActivityLabel: string
}

// ideal respresentation of an activity. we will transform to this format
interface Activity {
  careActivityId: string,
  careActivityLabel: string,
  careActivitiesCategory: string,
  careCategoryName: string,
  careCategoryId: string,
  disabled: boolean
}

interface Option {
  value: string,
}

interface SelectOption extends Option {
  label: string,
  careCategoryId: string,
  disabled: boolean,
  __isNew__?: never
}
interface CustomOption extends Option {
  __isNew__: true
}

type DataFetcher = () => any
interface ActivitiesSelectorProps {
  disabled: boolean,
  token: string,
  userId: string,
  afterSelect: () => void,
  setupDataFetcher: (func: DataFetcher) => void
}

// indidcates an activity sent by the API. has to be transformed
type CareActivitieAll = {
  careActivities: Array<ActivityMinimal>
}

type ReceivedActivity = {
  careActivitiesAll: Array<CareActivitieAll>,
  careCategoryId: string,
  careCategoryName: string
}

const transFormActivitiesList = <T extends ReceivedActivity[]>(activities: T): Array<Activity> => {
  const transformedActivities: Activity[] = []
  activities.forEach(activityCategory => {
    const defaultActivities = activityCategory.careActivitiesAll[0]
    defaultActivities.careActivities.forEach(activity => {
      transformedActivities.push({
        careActivityId: activity.careActivityId,
        careActivityLabel: activity.careActivityLabel,
        careActivitiesCategory: activityCategory.careCategoryId,
        careCategoryName: activityCategory.careCategoryName,
        careCategoryId: activityCategory.careCategoryId,
        disabled: true
      })
    })
  })
  return transformedActivities
}

const generateSelectOptions = (activities: Activity[], trackedActivities: Activity[]): SelectOption[] => {
  return activities.map(activity => {
    return {
      ...activity,
      disabled: !!trackedActivities.find(tracked => tracked.careActivityId === activity.careActivityId)
    }
  }).map(activity => ({
    value: activity.careActivityId,
    label: activity.careActivityLabel,
    careCategoryId: activity.careCategoryId,
    disabled: activity.disabled
  }))
}

const ActivitiesSelector = (props: ActivitiesSelectorProps) => {
  const [loading, setLoading] = useState(true)
  const [activitiesList, setActivitiesList] = useState<Activity[]>([])
  const [activitiesTracked, setActivitiesTracked] = useState<Activity[]>([])
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null)
  const [categoryModalOn, setCategoryModalOn] = useState(false)
  const [customActivityName, setCustomActivityName] = useState('')
  const toggleCategoryModal = () => setCategoryModalOn(!categoryModalOn)
  const fetchSelectorData = () => {
    getCareAllData({
      token: props.token,
      userid: props.userId
    }).then(response => {
      const activitiesList = response.data?.data?.activitiesList ?? []
      const activitiesTracked = response.data?.data?.activitiesTracked ?? []
      setActivitiesList(transFormActivitiesList(activitiesList))
      setActivitiesTracked(activitiesTracked)
      setLoading(false)
    })
  }
  useEffect(fetchSelectorData, [])
  props.setupDataFetcher(fetchSelectorData)
  const selectOptions = generateSelectOptions(activitiesList, activitiesTracked)
  return (
    <>
    <CreatableSelect
      onChange={async (selectedOption: CustomOption | SelectOption) => {
        if (selectedOption.__isNew__) {
          setCustomActivityName(selectedOption.value)
          setCategoryModalOn(true)
        } else {
          selectedOption = selectedOption as SelectOption
          setSelectedOption(selectedOption)
          await postCareActivitiesTracked({
            userid: props.userId,
            token: props.token,
            careActivityId: selectedOption.value,
            careCategoryId: selectedOption.careCategoryId,
            treatmentPlan: 0,
            careActivityLabel: selectedOption.label,
            isDoctorOnly: undefined,
            quantity: undefined,
            frequency: undefined
          })
          props.afterSelect()
        }
      }}
      value={selectedOption}
      isSearchable
      options={selectOptions}
      isDisabled={props.disabled}
      placeholder='Create or Select activities...'
      isOptionDisabled={(option: SelectOption) => {
        return option.disabled
      }}
      isLoading={loading}
    />
      <CategoriesModal
        customActivityLabel={customActivityName}
        isModalOn={categoryModalOn}
        toggle={toggleCategoryModal}
        onSubmit={async (category) => {
          const response = await postCareActivitiesList({
            userid: props.userId,
            careCategoryId: category.careCategoryId,
            careActivityLabel: customActivityName,
            token: props.token,
            treatmentPlan: 0
          })
          const careActivityId: string = response.data.data
          await postCareActivitiesTracked({
            userid: props.userId,
            token: props.token,
            careActivityId: careActivityId,
            careCategoryId: category.careCategoryId,
            treatmentPlan: 0,
            careActivityLabel: customActivityName,
            isDoctorOnly: undefined,
            quantity: undefined,
            frequency: undefined
          })
          setCustomActivityName('')
          toggleCategoryModal()
          props.afterSelect()
        }}
      />
    </>
  )
}


export default ActivitiesSelector
