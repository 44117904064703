import React, { Component } from 'react'
import { connect } from 'react-redux'

import CaseList from './User-case-list-view'
import LoaderButton from '../UI-helper-components/Loader-button'
import getCasesByUser from '../../Api/get-cases-by-user'
import updateCasesByUser from '../../Api/update-cases-by-user'

class OpenCases extends Component {

  state = {
    caseList: [],
    casesLoaded: false,
    isSpinnerOn: false,
    isFullScreenLoaderOn: false,
    isButtonLoaderOn: false
  }

  toggleSpinner = () => {
    this.setState(({ isSpinnerOn }) => {
      return {
        isSpinnerOn: !isSpinnerOn
      }
    })
  }

  toggleFullScreenLoader = () => {
    this.setState(({ isFullScreenLoaderOn }) => ({
      isFullScreenLoaderOn: !isFullScreenLoaderOn
    }))
  }

  getCareUserData = () => {
    getCasesByUser(this.props.userId, this.props.state.authReducer.token)
      .then(res => {
        this.toggleSpinner()
        this.setState(() => {
          return {
            caseList: res.data.data,
            casesLoaded: true
          }
        })
      })
      .catch(err => {
        this.toggleSpinner()
        alert('Error. Please retry')
      })
  }

  handleIGAValueChange = (data, selectedOption) => {
    updateCasesByUser(this.props.userId, data.id, selectedOption, this.props.state.authReducer.token)
      .then(res => {
        if(res){
          alert('Data updated successfully')
          this.toggleSpinner()
          this.getCareUserData()
        }
      })
      .catch(err => {
        // this.toggleSpinner()
        alert('Error. Please retry')
        this.toggleSpinner()
        this.getCareUserData()
      })
  }

  componentDidMount() {
    this.toggleSpinner()
    this.getCareUserData()
  }

  loaderButtonClickHandler = async (e) => {
    e.preventDefault()
    this.setState(() => ({
      isButtonLoaderOn: true
    }))
    try {
      const res = await getCasesByUser(this.props.userId, this.props.state.authReducer.token)
      this.setState((prevState) => {
        if (prevState.caseList !== res.data.data) {
          return {
            isButtonLoaderOn: false,
            caseList: res.data.data
          }
        }
      })
    } catch (error) {
      this.setState(() => ({
        isButtonLoaderOn: false
      }))
      alert('Error. Please retry.')
    }
  }

  render() {
    return (
      <div>
        {this.state.isFullScreenLoaderOn && <div className='tibot-full-screen-loader'></div>}
        {this.state.isSpinnerOn && <div className='square-spinner'></div>}
        <CaseList
          {...this.props}
          toggleFullScreenLoader={this.toggleFullScreenLoader}
          caseList={this.state.caseList}
          handleIGAValueChange={this.handleIGAValueChange}
          origin='pendingCases'
          casesLoaded={this.state.casesLoaded} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state
  }
}

export default connect(mapStateToProps)(OpenCases)