/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import NoImageComponent from "../../assets/easi/no-image.png";
import { getAsiImage } from "../../Api/get-asi-images";

const ImageComponent = ({ token, imagePath, bodyPart, individualSeverityScores, handleImageClicked }) => {
    const [imageSrc, setImageSrc] = useState('');
    const bodyParts = {
        "head": "Head Area",
        "leg": "Legs",
        "trunk": "Body Area",
        "hand": "Hands",
        "front": "Front",
        "back": "Back"
    }

    const blobToImage = (blob) => {
        let u, x;
        u = URL.createObjectURL(blob);
        x = new XMLHttpRequest();
        x.open('GET', u, false);
        x.send();
        URL.revokeObjectURL(u);
        return x.responseText;
    }

    useEffect(() => {
        if (imagePath) {
            getAsiImage(token, imagePath.replace('/opt/skincare-images/images-easi/', '')).then(response => {
                const { data } = response;
                const src = `data:image/png;base64,${blobToImage(data)}`
                setImageSrc(src);
            })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [imagePath, token]);
    return (
        <div>
            {imagePath ?
                <div className={"t-box"}>
                    <a className="img-area">
                        <img src={imageSrc}
                            alt='img'
                            onClick={(e) => handleImageClicked(e, imageSrc, individualSeverityScores)}
                        />
                    </a>
                    <p>{bodyParts[bodyPart]}</p>
                </div> :
                <div className="t-box empty">
                    <a className="img-area empty">
                        <img src={NoImageComponent} alt="" />
                    </a>
                    <p>{bodyParts[bodyPart]}</p>
                </div>
            }
        </div>
    );
};

export default ImageComponent