import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Login from "../../Api/login";
import {
  addToken,
  addTokenEczemaless,
  addTokenPsoriasis,
  setIsAdmin,
  setIsAuthenticated,
  setUserRole,
} from "../../actions/authActions";
import { setUserEmail } from "../../actions/uiActions";
import config from "../../config/config";
import { actions, check } from "../../config/roles";

import Loginpsoriasis from "../../Api/loginpsoriasis";
import EczemaLogo from "../../assets/eczema-logo.png";
import PolyfinsLogo from "../../assets/v2/polyfins-logo.png";
import { uiEvent } from "../../events/main";
import { setPortalMode } from "../../actions/portalModeActions";
import axios from "../../Api/axios";

const StyledSignIn = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 35px;
  margin-top: 25px;
`;

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    isFSLoaderOpen: false,
  };

  toggleFSLoader = () => {
    this.setState(({ isFSLoaderOpen }) => {
      return {
        isFSLoaderOpen: !isFSLoaderOpen,
      };
    });
  };

  emailHandler = (e) => {
    const email = e.target.value;
    this.setState(() => ({
      email: email,
    }));
  };

  passwordHandler = (e) => {
    const password = e.target.value;
    this.setState(() => ({
      password: password,
    }));
  };

  submitHandler = async (e) => {
    e.preventDefault();
    this.toggleFSLoader();
    // Clear previous login data from localStorage and Redux state
    localStorage.removeItem("dev_auth_token");
    localStorage.removeItem("dev_psoriasis_auth_token");
    localStorage.removeItem("dev_eczemaless_auth_token");
    localStorage.removeItem("dev_user_role");
    localStorage.removeItem("dev_is_admin");
    localStorage.removeItem("email");
    localStorage.removeItem("portalMode");
    this.props.dispatcher(setIsAuthenticated(false));
    this.props.dispatcher(addToken(null));
    this.props.dispatcher(addTokenPsoriasis(null));
    this.props.dispatcher(addTokenEczemaless(null));
    this.props.dispatcher(setUserRole(null));
    this.props.dispatcher(setIsAdmin(false));
    let eczemaResponse = null;
    let psoriasisResponse = null;
    // Try logging into both servers
    try {
      eczemaResponse = await Login(this.state.email, this.state.password);
    } catch (error) {
      console.log("Login to eczema server failed:", error);
    }

    try {
      psoriasisResponse = await Loginpsoriasis(this.state.email, this.state.password);
    } catch (error) {
      console.log("Login to psoriasis server failed:", error);
    }
    this.toggleFSLoader();
    if (!eczemaResponse && !psoriasisResponse) {
      alert("Login failed on both servers. Please retry");
      return;
    }

    if (eczemaResponse) {
      this.props.dispatcher(setPortalMode(config.ECZEMALESS_APP));
      localStorage.setItem("portalMode", config.ECZEMALESS_APP);
      axios.defaults.baseURL = config.ECZEMALESS_API_BASE_URL;
      this.props.dispatcher(addToken(eczemaResponse.data.token));
    } else {
      this.props.dispatcher(setPortalMode(config.PSORIASIS_APP));
      localStorage.setItem("portalMode", config.PSORIASIS_APP);
      axios.defaults.baseURL = config.PSORIASIS_API_BASE_URL;
      this.props.dispatcher(addToken(psoriasisResponse.data.token));
    }

    if (eczemaResponse) {
      this.props.dispatcher(setUserEmail(this.state.email));
      this.props.dispatcher(addTokenEczemaless(eczemaResponse.data.token));
      const isAdmin = eczemaResponse.data.role === "ADMIN";
      this.props.dispatcher(setIsAdmin(isAdmin));
      this.props.dispatcher(setIsAuthenticated(true));
      this.props.dispatcher(setUserRole(eczemaResponse.data.role));
      // Emit login event
      uiEvent.emit("login", {
        role: eczemaResponse.data.role,
        token: eczemaResponse.data.token,
        tokenEczemaless: eczemaResponse.data.token,
        dispatcher: this.props.dispatcher,
      });
      // Development only settings
      if (process.env.NODE_ENV === "development") {
        localStorage.setItem("dev_auth_token", eczemaResponse.data.token);
        localStorage.setItem("dev_eczemaless_auth_token", eczemaResponse.data.token);
        localStorage.setItem("dev_user_role", eczemaResponse.data.role);
        localStorage.setItem("dev_is_admin", isAdmin);
        localStorage.setItem("email", this.state.email);
      }
    }

    if (psoriasisResponse) {
      this.props.dispatcher(addTokenPsoriasis(psoriasisResponse.data.token));
      this.props.dispatcher(setUserEmail(this.state.email));
      const isAdmin = psoriasisResponse.data.role === "ADMIN";
      this.props.dispatcher(setIsAdmin(isAdmin));
      this.props.dispatcher(setIsAuthenticated(true));
      this.props.dispatcher(setUserRole(psoriasisResponse.data.role));
      // Emit login event for psoriasis
      uiEvent.emit("login", {
        role: psoriasisResponse.data.role,
        token: psoriasisResponse.data.token,
        tokenPsoriasis: psoriasisResponse.data.token,
        dispatcher: this.props.dispatcher,
      });
      // Development only settings for psoriasis
      if (process.env.NODE_ENV === "development") {
        localStorage.setItem("dev_auth_token", psoriasisResponse.data.token);
        localStorage.setItem("dev_user_role", psoriasisResponse.data.role);
        localStorage.setItem("dev_is_admin", isAdmin);
        localStorage.setItem("email", this.state.email);
        localStorage.setItem("dev_psoriasis_auth_token", psoriasisResponse.data.token);
      }
    }
    // Redirect based on role
    const userRole = eczemaResponse ? eczemaResponse.data.role : psoriasisResponse.data.role;
    if (check(userRole, actions.view_stats)) {
      this.props.history.replace(config.baseRoute + "dashboard");
    } else {
      this.props.history.replace(config.baseRoute + "users/page/1");
    }

  };

  render() {
    const logoImg =
      process.env.REACT_APP_NAME === "Eczema" ? EczemaLogo : PolyfinsLogo;
    return (
      <div className="container">
        {this.state.isFSLoaderOpen && (
          <div className="tibot-full-screen-loader"></div>
        )}
        <StyledSignIn>
          <LogoWrapper>
            <img
              src={logoImg}
              alt="Logo"
              style={{
                maxWidth: "300px",
                margin: "0 auto",
              }}
            />
          </LogoWrapper>
          <form onSubmit={this.submitHandler}>
            <div className="form-group">
              <label htmlFor="email">Email address:</label>
              <input
                required
                type="email"
                className="form-control"
                placeholder="Email"
                value={this.state.email}
                onChange={this.emailHandler}
              />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Password:</label>
              <input
                required
                type="password"
                className="form-control"
                placeholder="Password"
                value={this.state.password}
                onChange={this.passwordHandler}
              />
            </div>
            <button type="submit" className="btn btn-lg btn-primary">
              Log in
            </button>
          </form>
          <div
            className="reset-password-link"
            style={{
              marginTop: "15px",
            }}
          >
            <span
              style={{
                color: "#071e38",
                cursor: "pointer",
                fontSize: "18px",
              }}
              onClick={() => {
                window.alert(
                  "Please contact us at support@eczemaless.com to request a password reset."
                );
              }}
            >
              Reset Password
            </span>
          </div>
        </StyledSignIn>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher: (action) => {
      dispatch(action);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);