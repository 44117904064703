import moment from 'moment'

import axios from './axios'
import endpoints from '../config/endpoints'

export default async (token, period, userId) => {
  const headers = {
    headers: {
      token,
      period,
      userId
    },
    responseType: 'blob'
  }
  try {
    const response = await axios.get(endpoints.USER_SUMMARY_REPORT, headers)
    const url = window.URL.createObjectURL(new window.Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'EczemaLess-report-' + moment().format('YYYY-MM-DD') + '.pdf')
    document.body.appendChild(link)
    link.click()
    return Promise.resolve(true)
  } catch (error) {
    return Promise.reject(error)
  }
}
