import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import imageIdGenerator from '../../utilityFunctions/image-id-generator'
import loadingGif from '../../assets/loading.gif'
import endpoints from '../../config/endpoints'
import config from '../../config/config'
import ReactAuthImage from '../UI-helper-components/react-auth-image'
import UtcToLocal from '../../utilityFunctions/utc-to-local'
import classnames from 'classnames'
import truncate from 'truncate'
import anonImg from '../../assets/v2/anon.png'
import moment from 'moment'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { setRedirectRoute } from '../../actions/portalModeActions';

class MessageList extends React.Component {

  state = {
    ascendingDate: false
  }

  toggleDateSort = () => {
    this.setState(() => ({
      ascendingDate: !this.state.ascendingDate
    }))
  }
  
  getLastMessage = (message) => {
    if (message.type === 'image') {
      return 'Image'
    } else {
      return truncate(message.content, 50)
    }
  }
  
  goToMessage = (patientId) => {
    this.handleLink(config.baseRoute + 'user/' + patientId);
    this.props.history.push(config.baseRoute + 'user/' + patientId)
  }
  
  reloadData = () => {
    this.props.loadData()
  }

  handleLink = (newRoute) => {
    const { location } = this.props;
    const routeData = {
      redirectRoute: location.pathname,
      currentRoute: newRoute
    }
    console.log(routeData);
    this.props.dispatcher(setRedirectRoute(routeData));
  }
  
  render () {
    const messageHeadings = this.props.messageHeadings.sort((a, b) => {
        //lastMessage
        if (this.state.ascendingDate) {
          return moment(a.lastMessage.date).valueOf() - moment(b.lastMessage.date).valueOf()
        } else {
          return moment(b.lastMessage.date).valueOf() - moment(a.lastMessage.date).valueOf()
        }
    })
    const ascendDescendIcon = () => {
      if (this.state.ascendingDate) {
        return <i className="fa fa-angle-up" aria-hidden="true"></i>
      } else {
        return <i className="fa fa-angle-down" aria-hidden="true"></i>
      }
    }
    return (
      <div className='message-list'>
        <table className='message-list-table table'>
          <thead>
            <tr>
              <th style={{textAlign: 'center'}}>
                <i onClick={this.reloadData} className="fa fa-refresh refresh-icon" aria-hidden="true"></i>
              </th>
              <th>
                <span className='message-list-heading-text'>Patient Name</span>
              </th>
              <th>
                <span className='message-list-heading-text'>Last Message</span>
              </th>
              <th style={{cursor: 'pointer'}} onClick={this.toggleDateSort}>
                <span className='ascend-descend-icon-wrapper'>{ascendDescendIcon()}</span><span className='message-list-heading-text'>Date</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {messageHeadings.map(heading => {
              return (
                <tr onClick={() => {
                  this.goToMessage(heading.patientId)
                }} style={{ cursor: 'pointer' }} key={heading.id} className={classnames({
                  unread: heading.isUnread
                })}>
                      <td className="message-list__avatar">
                        <img className='inbox-user-image' src={anonImg} alt='user' />
                      </td>
                      <td className='message-list__email'>
                        <div className={classnames({
                          messageBold: heading.isUnread
                        })}> {heading.patient.name || heading.userEmail}</div>
                      </td>
                      <td className="message-list__content">
                          {this.getLastMessage(heading.lastMessage)}
                      </td>
                      <td className='message-list__date-new'>
                        {UtcToLocal(heading.lastMessageTime)}
                      </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageList)) 