import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Menu from '../Menu'
import VerticalMenu from '../Menu/Vertical-menu'
import IdleTimer from 'react-idle-timer'
import config from "../../config/config";
import LogoffAlertModal from "./LogoffAlertModal";
import postDoctorPatientSessionApi from "../../Api/Doctor-patient-session/post-doctor-patient-session";
import PatientTab from '../PatientTab/PatientTab'
import { setCurrentSessionUserId } from '../../actions/uiActions'

const mapStateToProps = (state) => {
    return {
        state: state,
        inboxCount: state.uiReducer.unreadMessages.length,
        userRole: state.authReducer.userRole,
        shadowId: state.uiReducer.shadowId,
        isAdmin: state.authReducer.isAdmin,
        userEmail: state.uiReducer.userEmail,
        isAuthenticated: state.authReducer.isAuthenticated,
        portalMode: state.portalModeReducer.portalMode,
        token: state.authReducer.token
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatcher : (action) => {
            dispatch(action)
        }
    }
}

const WithMenu = (Component) => {
    class CompWithMenu extends React.Component {

        state = {
            isModalOn: false,
            key: 0,
        }

        toggle = () => {
            this.setState(() => ({
                isModalOn: !this.state.isModalOn
            }))
        }

        postDoctorPatientSession = (action) => {
            postDoctorPatientSessionApi(this.props.state.uiReducer.doctorCurrentSessionUserId, this.props.state.authReducer.token, action)
                .then(res => {
                    if(res) {
                        this.props.dispatcher(setCurrentSessionUserId(''));
                        window.location.replace("/")
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        console.error('Unauthorized access. Redirecting to login page.');
                        throw err;
                    }
                    console.log(err)
                    alert('Error. Please retry')
                })
        }

        handleOnAction = (event) => {
        }

        handleOnActive = (event) => {
            //console.log('user is active')
        }

        handleOnIdle = (event) => {
            //console.log('user is idle')
            this.toggle()
            setTimeout(() => {
                if(this.props.state.uiReducer.doctorCurrentSessionUserId !== '') {
                    this.postDoctorPatientSession('stop')
                } else {
                    window.location.replace("/")
                }
            }, 5000);
        }

        componentDidUpdate(prevProps) {
            if (prevProps.portalMode !== this.props.portalMode) {
                this.setState((prevState) => ({
                    key: prevState.key + 1,  //By updating the state with a new key value, React triggers a re-render of the component
                }));
            }
        }

        render() {
            return (
                <Fragment>

                    <IdleTimer
                        timeout={config.IDLE_TIME_OUT}
                        onActive={this.handleOnActive}
                        onIdle={this.handleOnIdle}
                        onAction={this.handleOnAction}
                        debounce={250}
                    />
                    {this.state.isModalOn && <LogoffAlertModal
                        isModalOn={this.state.isModalOn}
                        toggle={this.toggle}
                    />}
                    <div className='tibot-menu-wrapper sticky-top'>
                        <Menu />
                    </div>
                    <div className='sidebar-and-component'>
                        {this.props.isAuthenticated && <VerticalMenu
                            isAdmin={this.props.isAdmin}
                            inboxCount={this.props.inboxCount}
                            userRole={this.props.userRole}
                            shadowId={this.props.shadowId}
                        />}

                        <div className='template-component-wrapper'>
                            <PatientTab />
                            <Component key={this.state.key} />
                        </div>
                    </div>
                </Fragment>
            )
        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(CompWithMenu)
}

export default WithMenu