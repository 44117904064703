import 'rc-switch/assets/index.css'
import React from 'react'
import RcSwitch from 'rc-switch'

const TrackButtonAtom = (props) => {
  return (
    <div>
      <RcSwitch
        disabled={props.disabled}
        checked={props.isTracked}
      />
    </div>
  )
}

export default TrackButtonAtom
