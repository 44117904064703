import React, { Component } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'

import { fetchUnreadMessages } from '../../actions/uiActions'
import ScreenHeader from '../Common/Screen-header'
import LoaderButton from '../UI-helper-components/Loader-button'
import MessageList from './Message-list'
import { getMessageList } from '../../Api/Messaging/messaging'
import CTAButton from '../Common/Cta-button'
import Can from '../UI-helper-components/Can'
import { actions } from '../../config/roles'

class Messages extends Component {

  state = {
    isSpinnerOn: false,
    unreadMessages: [],
    isLoading: true
  }

  toggleSpinner = () => {
    this.setState(() => ({ isSpinnerOn: !this.state.isSpinnerOn }))
  }
  
  shouldShowNoMessages = () => {
    return this.state.unreadMessages.length <= 0
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    const result = await getMessageList({ token: this.props.state.authReducer.token })
    this.setState(() => ({
      unreadMessages: result.data.data,
      isLoading: false
    }))
  }

  ctaOnClick = () => {
    this.props.history.push(`/invite-patient`)
  }

  render () {
    return (
      <div className='message-screen-wrapper'>
        <ScreenHeader
          heading='Messages'
          button={(
            <Can 
              action={actions.create_patient}
              yes={() => (
                <CTAButton
                  text='Add New Patient'
                  onClick={this.ctaOnClick}
                  icon={(
                      <i className="fa fa-plus" aria-hidden="true"></i>
                  )}
                />  
              )}
            />
          )}
        />
        {this.shouldShowNoMessages() && !this.state.isLoading ? <div className='no-list-item'>No messages</div> : 
          !this.props.isMessageListLoading && <MessageList
            history={this.props.history}
            messageHeadings={this.state.unreadMessages}
            loadData={this.loadData}
          />        
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state,
    unreadMessages: state.uiReducer.unreadMessages,
    isMessageListLoading: state.uiReducer.isMessageListLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher : (action) => {
      dispatch(action)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Messages))
