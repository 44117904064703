import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import roles from '../../config/roles'
import config from '../../config/config'
import Login from '../../Api/login'
import { addToken, setIsAuthenticated, setIsAdmin, setUserRole, addTokenEczemaless, addTokenPsoriasis } from '../../actions/authActions'
import { setUserEmail, setDoctorName } from '../../actions/uiActions'

import EczemaLogo from '../../assets/eczema-logo.png'

import { withRouter } from 'react-router-dom'
import qs from 'querystring'
import { Formik, Form } from 'formik'
import validationSchema from './validation-schema'
import { TextInput } from './Fields'
import { postConfirmAssistant } from '../../Api/misc-apis/misc-apis'
import { getAPIErrorMessageData } from '../../utilityFunctions/error-handling'
import { uiEvent } from '../../events/main'
import { setPortalMode } from '../../actions/portalModeActions'
import axios from '../../Api/axios'
import loginpsoriasis from '../../Api/loginpsoriasis'

const StyledSignIn = styled.div`
max-width: 600px;
margin: 0 auto;
`
const LogoWrapper = styled.div`
text-align: center;
margin-bottom: 35px;
margin-top: 25px;
`

const CompleteRegistrationHeading = styled.div`
text-align: center;
margin-bottom: 30px;
`

function delay(ms) {
  return new Promise((resolve, reject) => setTimeout(reject, ms));
}

class CompleteAssistantReg extends Component {
    
  queryParamValue = (paramName) => {
    if(paramName[0] === '?') {
          return qs.parse(this.props.location.search)[paramName] || qs.parse(this.props.location.search)[paramName.replace('?', '')] || ''
    }
    return qs.parse(this.props.location.search)[paramName] || ''
  }

  state = {
    isFSLoaderOpen: false
  }

  toggleFSLoader = () => {
    this.setState(({isFSLoaderOpen}) => {
      return {
        isFSLoaderOpen: !isFSLoaderOpen
      }
    })
  }

  toggleIsSubmitting = () => {
    this.setState(({isSubmitting}) => {
      return {
        isSubmitting: !isSubmitting
      }
    })
  }

  autoLogin = async (email, password) => {
    this.toggleFSLoader()
    try {
      this.toggleFSLoader();
    // Clear previous login data from localStorage and Redux state
    localStorage.removeItem("dev_auth_token");
    localStorage.removeItem("dev_psoriasis_auth_token");
    localStorage.removeItem("dev_eczemaless_auth_token");
    localStorage.removeItem("dev_user_role");
    localStorage.removeItem("dev_is_admin");
    localStorage.removeItem("email");
    localStorage.removeItem("portalMode");
    this.props.dispatcher(setIsAuthenticated(false));
    this.props.dispatcher(addToken(null));
    this.props.dispatcher(addTokenPsoriasis(null));
    this.props.dispatcher(addTokenEczemaless(null));
    this.props.dispatcher(setUserRole(null));
    this.props.dispatcher(setIsAdmin(false));
    let eczemaResponse = null;
    let psoriasisResponse = null;
    // Try logging into both servers
    try {
      eczemaResponse = await Login(email, password);
    } catch (error) {
      console.log("Login to eczema server failed:", error);
    }
    try {
      psoriasisResponse = await loginpsoriasis(email, password);
    } catch (error) {
      console.log("Login to psoriasis server failed:", error);
    }
    this.toggleFSLoader();
    if (!eczemaResponse && !psoriasisResponse) {
      alert("Login failed on both servers. Please retry");
      return;
    }
    if (eczemaResponse) {
      this.props.dispatcher(setPortalMode(config.ECZEMALESS_APP));
      localStorage.setItem("portalMode", config.ECZEMALESS_APP);
      axios.defaults.baseURL = config.ECZEMALESS_API_BASE_URL;
      this.props.dispatcher(addToken(eczemaResponse.data.token));
    } else {
      this.props.dispatcher(setPortalMode(config.PSORIASIS_APP));
      localStorage.setItem("portalMode", config.PSORIASIS_APP);
      axios.defaults.baseURL = config.PSORIASIS_API_BASE_URL;
      this.props.dispatcher(addToken(psoriasisResponse.data.token));
    }
    if (eczemaResponse) {
      this.props.dispatcher(setUserEmail(email));
      this.props.dispatcher(addTokenEczemaless(eczemaResponse.data.token));
      const isAdmin = eczemaResponse.data.role === "ADMIN";
      this.props.dispatcher(setIsAdmin(isAdmin));
      this.props.dispatcher(setIsAuthenticated(true));
      this.props.dispatcher(setUserRole(eczemaResponse.data.role));
      // Emit login event
      uiEvent.emit("login", {
        role: eczemaResponse.data.role,
        token: eczemaResponse.data.token,
        tokenEczemaless: eczemaResponse.data.token,
        dispatcher: this.props.dispatcher,
      });
      // Development only settings
      if (process.env.NODE_ENV === "development") {
        localStorage.setItem("dev_auth_token", eczemaResponse.data.token);
        localStorage.setItem("dev_eczemaless_auth_token", eczemaResponse.data.token);
        localStorage.setItem("dev_user_role", eczemaResponse.data.role);
        localStorage.setItem("dev_is_admin", isAdmin);
        localStorage.setItem("email", email);
      }
    }
    if (psoriasisResponse) {
      this.props.dispatcher(addTokenPsoriasis(psoriasisResponse.data.token));
      this.props.dispatcher(setUserEmail(email));
      const isAdmin = psoriasisResponse.data.role === "ADMIN";
      this.props.dispatcher(setIsAdmin(isAdmin));
      this.props.dispatcher(setIsAuthenticated(true));
      this.props.dispatcher(setUserRole(psoriasisResponse.data.role));
      // Emit login event for psoriasis
      uiEvent.emit("login", {
        role: psoriasisResponse.data.role,
        token: psoriasisResponse.data.token,
        tokenPsoriasis: psoriasisResponse.data.token,
        dispatcher: this.props.dispatcher,
      });
      // Development only settings for psoriasis
      if (process.env.NODE_ENV === "development") {
        localStorage.setItem("dev_auth_token", psoriasisResponse.data.token);
        localStorage.setItem("dev_user_role", psoriasisResponse.data.role);
        localStorage.setItem("dev_is_admin", isAdmin);
        localStorage.setItem("email", email);
        localStorage.setItem("dev_psoriasis_auth_token", psoriasisResponse.data.token);
      }
    }
      this.props.history.replace(config.baseRoute + 'users/page/1')
    } catch (error) {
      this.toggleFSLoader()
      alert('Login failed. Please retry')
      console.log(error)
    }
  }

  handleSubmitAsync = async (values, actions) => {
      const {
        password,
        confirmPassword,
        firstName,
        lastName,
        address,
        email,
        phone
      } = values
      try {
        await postConfirmAssistant({
          password,
          firstName,
          lastName,
          address,
          email,
          token: this.queryParamValue('token'),
          server: this.queryParamValue('server'), 
          phone
        })
        actions.setSubmitting(false)
        this.autoLogin(email, password)
      } catch (error) {
        actions.setSubmitting(false)
        const message = getAPIErrorMessageData(error)
        if(message) {
          window.alert(message)
        } else {
          window.alert('Error. Please retry')
        }
      }
  }

  formSubmitHandler = (values, actions) => {
    this.handleSubmitAsync(values, actions)
  }

  getSubmitButton = (isSubmitting) => {
    if(!isSubmitting) {
      return <button type="submit" className="btn btn-primary next-button">SUBMIT</button>
    } else {
      return (
      <button disabled={true} type="submit" className="btn btn-primary next-button">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        SUBMITTING...
      </button>
      )
    }
  }

  render () {
    return (
      <div className='container'>
       {this.state.isFSLoaderOpen && <div className='tibot-full-screen-loader'></div>} 
        <StyledSignIn>
          <LogoWrapper>
            <img src={EczemaLogo} alt="Logo" style={{
              maxWidth: '300px',
              margin: '0 auto'
            }}/>
          </LogoWrapper>
          <CompleteRegistrationHeading>
            <h4>Complete Registration</h4>
          </CompleteRegistrationHeading>
          <Formik
            validationSchema={validationSchema}
            onSubmit={this.formSubmitHandler}
            initialValues={{
                password: '',
                confirmPassword: '',
                firstName: '',
                lastName: '',
                address: '',
                email: this.queryParamValue('?email'),
                phone: ''
            }}
          >
              {({ submitForm, isValid, values, setTouched, validateForm, isSubmitting }) => {
                return(
                    <Form>
                        <TextInput
                            key='email'
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="Your Email"
                            disabled={true}
                        />
                        <TextInput
                            key='password'
                            label="Password"
                            name="password"
                            type="password"
                            placeholder="New Password"
                        />
                        <TextInput
                            key='confirmPassword'
                            label="Confirm Password"
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                        />
                        <TextInput
                            key='firstName'
                            label="First Name"
                            name="firstName"
                            type="text"
                            placeholder="Your First Name"
                        />
                        <TextInput
                            key='lastName'
                            label="Last Name"
                            name="lastName"
                            type="text"
                            placeholder="Your Last Name"
                        />
                        <TextInput
                            isNotRequired={true}
                            key='phone'
                            label="Phone Number"
                            name="phone"
                            type="text"
                            placeholder="Your Phone Number"
                        />
                        <TextInput
                            isNotRequired={true}
                            key='address'
                            label="Address"
                            name="address"
                            type="textarea"
                            placeholder="Your address"
                        />
                        {this.getSubmitButton(isSubmitting)}
                    </Form>
                )
            }}
          </Formik>
        </StyledSignIn>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher : (action) => {
      dispatch(action)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    state : state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompleteAssistantReg))