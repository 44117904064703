import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../Api/axios";
import { addToken } from "../../actions/authActions";
import { setPortalMode, setRedirectRoute } from "../../actions/portalModeActions";
import config from "../../config/config";
import TabMenu from "../TabMenu/TabMenu";
import { withRouter } from 'react-router-dom';

class PatientTab extends Component {
  tabHeadings = [config.ECZEMALESS_APP, config.PSORIASIS_APP];

  handleRedirect = () => {
    const { location } = this.props;
    const routeInfo = this.props.redirectRoute;
    if (routeInfo) {
      if (routeInfo.currentRoute === location.pathname) {
        window.location.href = routeInfo.redirectRoute;
        this.props.dispatcher(setRedirectRoute({}));
      } else {
        this.props.dispatcher(setRedirectRoute({}));
      }
    }
  };

  handleTabChange = (selectedTabHeading) => {
    if (selectedTabHeading === config.ECZEMALESS_APP) {
      this.props.dispatcher(addToken(this.props.tokenEczemaless));
      localStorage.setItem("dev_auth_token", this.props.tokenEczemaless);
      axios.defaults.baseURL = config.ECZEMALESS_API_BASE_URL;
    } else {
      this.props.dispatcher(addToken(this.props.tokenPsoriasis));
      localStorage.setItem("dev_auth_token", this.props.tokenPsoriasis);
      axios.defaults.baseURL = config.PSORIASIS_API_BASE_URL;
    }

    this.props.dispatcher(setPortalMode(selectedTabHeading));
    if (process.env.NODE_ENV === "development") {
      localStorage.setItem("portalMode", selectedTabHeading);
    }

    this.handleRedirect();
  };

  render() {
    const availableTabs = this.tabHeadings.filter((tab) => {
      if (tab === config.ECZEMALESS_APP && this.props.tokenEczemaless) {
        return true;
      }
      if (tab === config.PSORIASIS_APP && this.props.tokenPsoriasis) {
        return true;
      }
      return false;
    });

    return (
      <div style={{ margin: "-25px 0px 5px 0px" }}>
        <TabMenu
          activeTab={this.props.portalMode}
          headings={availableTabs}
          onTabChange={this.handleTabChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  isAdmin: state.authReducer.isAdmin,
  token: state.authReducer.token,
  tokenPsoriasis: state.authReducer.tokenPsoriasis,
  tokenEczemaless: state.authReducer.tokenEczemaless,
  portalMode: state.portalModeReducer.portalMode,
  redirectRoute: state.portalModeReducer.redirectRoute
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PatientTab));