import axios from '../axios'
import endpoints from '../../config/endpoints'

export default async (token, patientId, type, content) => {
  const body = {
    token,
    patientId,
    type,
    content
  }
  try {
    const response = await axios.post(endpoints.DOCTOR_NOTE, body)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}
