const uiReducerDefaults = {
  isReactMenuOpen: false,
  userEmail: '',
  doctorName: '',
  unreadMessageCount: 0,
  imageLightBoxOpen: false,
  imageLightBoxUrl: '',
  unreadMessages: [],
  isMessageListLoading: false,
  shadowId: '',
  doctorCurrentSessionUserId: '',
  consultationNotes: {},
  consultationPrescriptions: {}
}

export default (state = uiReducerDefaults, action) => {
  switch (action.type) {
    case 'SET_REACT_MENU':
      return {
        ...state,
        isReactMenuOpen: action.payload
      }
    case 'SET_USER_EMAIL':
      return {
        ...state,
        userEmail: action.payload
      }
    case 'SET_DOCTOR_NAME':
      return {
        ...state,
        doctorName: action.payload
      }
    case 'SET_UNREAD_MESSAGE_COUNT':
      return {
        ...state,
        unreadMessageCount: action.payload
      }
    case 'OPEN_IMAGE_LIGHTBOX':
      return {
        ...state,
        imageLightBoxOpen: true,
        imageLightBoxUrl: action.payload
      }
    case 'CLOSE_IMAGE_LIGHTBOX':
      return {
        ...state,
        imageLightBoxOpen: false,
        imageLightBoxUrl: ''
      }
    case 'SET_UNREAD_MESSAGES':
      return {
        ...state,
        unreadMessages: action.payload
      }
    case 'SET_IS_MESSAGE_LIST_LOADING':
      return {
        ...state,
        isMessageListLoading: action.payload
      }
    case 'SET_SHADOW_ID':
      return {
        ...state,
        shadowId: action.payload
      }
    case 'SET_CURRENT_SESSION_USER_ID':
      return {
        ...state,
        doctorCurrentSessionUserId: action.payload
      }
    case 'SET_CONSULTATION_NOTE': {
      const newState = state
      const consultationId = action.payload.consultationId
      const text = action.payload.text
      newState.consultationNotes[consultationId] = {
        text: text
      }
      return newState
    }
    case 'REMOVE_CONSULTATION_NOTE': {
      const newState = state
      const consultationId = action.payload.consultationId
      delete newState.consultationNotes[consultationId]
      return newState
    }
    case 'SET_CONSULTATION_PRESCRIPTION': {
      const newState = state
      const consultationId = action.payload.consultationId
      const text = action.payload.text
      newState.consultationPrescriptions[consultationId] = {
        text: text
      }
      return newState
    }
    case 'REMOVE_CONSULTATION_PRESCRIPTION': {
      const newState = state
      const consultationId = action.payload.consultationId
      delete newState.consultationPrescriptions[consultationId]
      return newState
    }
    default:
      return state
  }
}
