import React, {Component} from 'react'
import {connect} from 'react-redux'
import addDoctorAssistant from '../../Api/post-doctor-assistant'
import LoaderButton from '../UI-helper-components/Loader-button'
import {extractErrorMessage} from '../../utilityFunctions/common'
import axios from "axios";
import config from '../../config/config'

const successMessage = (email) => {
    return <div className='success-message-invite'>Sent invitation to <strong>{email}</strong>.
    </div>
}

const errorMessage = (email, apiErrorMessage) => {
    return <div className='error-message-invite'>
        <div>{apiErrorMessage}</div>
        <div>
            Error adding <strong>{email}</strong>. Please contact us at support@eczemaless.com for assistance regarding
            this issue.
        </div>
    </div>
}

const responseTypes = {
    'SUCCESS': 'SUCCESS',
    'ERROR': 'ERROR'
}

class AddAssistant extends Component {
    state = {
        isSpinnerOn: false,
        didGetResponse: false,
        lastSubmittedEmail: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        password: '',
        apiErrorMessage: ''
    }

    viewMessage = () => {
        if (!this.state.didGetResponse) {
            return null
        }
        if (this.state.responseType === responseTypes.SUCCESS) {
            return successMessage(this.state.lastSubmittedEmail)
        } else {
            return errorMessage(this.state.lastSubmittedEmail, this.state.apiErrorMessage)
        }
    }

    toggleSpinner = () => {
        this.setState(({isSpinnerOn}) => {
            return {
                isSpinnerOn: !isSpinnerOn
            }
        })
    }

    onFirstNameChangeHandler = (e) => {
        const firstName = e.target.value
        this.setState(() => ({
            firstName
        }))
    }

    onLastNameChangeHandler = (e) => {
        const lastName = e.target.value
        this.setState(() => ({
            lastName
        }))
    }

    onEmailChangeHandler = (e) => {
        const email = e.target.value
        this.setState(() => ({
            email
        }))
    }

    onPhoneNumberChangeHandler = (e) => {
        const phoneNumber = e.target.value
        this.setState(() => ({
            phoneNumber
        }))
    }

    onAddressChangeHandler = (e) => {
        const address = e.target.value
        this.setState(() => ({
            address
        }))
    }

    onPasswordChangeHandler = (e) => {
        const password = e.target.value
        this.setState(() => ({
            password
        }))
    }

    onSubmitHandler = async (e) => {
        e.preventDefault()
        if (!this.state.email) {
            return
        }
        try {
            this.toggleSpinner()
            this.setState(() => ({
                didGetResponse: false,
                lastSubmittedEmail: this.state.email,
                apiErrorMessage: ''
            }))
            const eczemalessBody = {
                token: this.props.state.authReducer.tokenEczemaless,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                address: this.state.address,
                password: this.state.password
            }
            const psoriasisBody = {
                token: this.props.state.authReducer.tokenPsoriasis,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                address: this.state.address,
                password: this.state.password
            }

            if(this.props.portalMode === config.ECZEMALESS_APP){
                axios.defaults.baseURL = config.ECZEMALESS_API_BASE_URL;
                console.log("assistant creation for -----> eczemaless")
                await addDoctorAssistant(eczemalessBody)
            } else {
                axios.defaults.baseURL = config.PSORIASIS_API_BASE_URL;
                console.log("assistant creation for ----->  psoriasis")
                await addDoctorAssistant(psoriasisBody)
            }
               

            this.setState(() => ({
                didGetResponse: true,
                responseType: responseTypes.SUCCESS,
                email: ''
            }))
        } catch (error) {
            console.log(error)
            this.setState(() => ({
                didGetResponse: true,
                responseType: responseTypes.ERROR,
                apiErrorMessage: extractErrorMessage(error)
            }))
        } finally {
            this.toggleSpinner()
        }
    }

    render() {
        return (
            <div className='container'>
                <p>Enter assistant information below</p>
                <form onSubmit={this.onSubmitHandler}>
                    <div className="form-group">
                        <input required onChange={this.onEmailChangeHandler} type="email" className="form-control" id="email"
                               aria-describedby="email" placeholder="Enter Email" value={this.state.email}/>
                    </div>
                    <div style={{
                        display: 'inline-block'
                    }}>
                        <LoaderButton
                            clickHandler={this.onSubmitHandler}
                            buttonText='Add'
                            isLoading={this.state.isSpinnerOn}
                            isSubmit={true}
                        />
                    </div>
                </form>
                {this.viewMessage()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state,
        portalMode: state.portalModeReducer.portalMode,
    }
}

export default connect(mapStateToProps)(AddAssistant)