import EventEmitter from 'events'
import { setShadowId } from '../actions/uiActions'
import { actions, check } from '../config/roles'
import { getDoctorProfile } from '../Api/misc-apis/misc-apis'
import getProp from '../Components/User/User-details-components/Utils/get'

export const uiEvent = new EventEmitter()

uiEvent.on('login', async ({
  token,
  dispatcher,
  role
}) => {
  if (check(role, actions.view_doctor_presets)) {
    try {
      const response = await getDoctorProfile({ token })
      dispatcher(setShadowId(getProp(response, 'data.data.shadowId')))
    } catch (error) {
      console.log(error)
    }
  }
})

uiEvent.on('presetApplied', (fn) => {
  try {
    fn()
  } catch (error) {
    console.log(error)
  }
})
