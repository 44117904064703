import React, { Component, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const  AddPresetModal = (props) => {
  const [presetName, setPresetName] = useState('')
  const [presetBody, setPresetBody] = useState('')
  return (
      <Modal isOpen={props.isModalOn} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Add a message preset</ModalHeader>
        <ModalBody>
          <input required style={{ marginBottom: '10px'}} type='text' className='form-control' placeholder='Preset Name' onChange={(e) => {
            setPresetName(e.target.value)
          }} />
          <textarea style={{
            minHeight: '180px'
          }} className='form-control' value={presetBody} onChange={(e) => {
            setPresetBody(e.target.value)
          }} placeholder='Write your message content here' />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            if (!presetName || !presetBody) {
              window.alert('All fields are required')
              return
            }
            props.onSubmit({
              presetName,
              presetBody
            })
          }}>Submit</Button>
          <Button color="secondary" onClick={props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
  )
}

export default AddPresetModal
