import React from 'react'

const RangeFilterTabs = (props) => {
  return (
    <div className='chart-header'>
      <div className='chart-button-holder' style={props.style}>
        {props.btnList.map(btn => (
          <button
            key={btn.id}
            className={btn.id === props.activeButtonId ? 'chart-navigation-button active' : 'chart-navigation-button'}
            value={btn.id}
            onClick={() => {
              props.handleButtonClick(btn.id)
            }}
          >
            {btn.title}
          </button>
        ))}
        {props.isLoading && <div className='chart-tab-loader'><div className='loader' /></div>}
      </div>
    </div>
  )
}

export default RangeFilterTabs
