import React, {PureComponent} from 'react';
import CreatableSelect from 'react-select/creatable';
import {isEqual} from 'lodash'

export default class CreatableAdvanced extends PureComponent {

    shouldComponentUpdate(prevProps) {
        if (isEqual(prevProps.options, this.props.options)
            && isEqual(prevProps.value, this.props.value)
            && isEqual(prevProps.otherValue, this.props.otherValue)
        ) {
            return false
        } else {
            return true
        }
    }

    render() {
        return (
            <CreatableSelect
                placeholder={this.props.placeholder}
                isClearable
                isDisabled={this.props.isLoading}
                isLoading={this.props.isLoading}
                onChange={this.props.onChange}
                onCreateOption={this.props.handleCreate}
                options={this.props.options}
                value={this.props.value}
                styles={this.props.styles}
            />
        );
    }
}
