import React, { useEffect, useState } from 'react'
import { getCareCategories } from '../../Api/Manage-tracking/manage-tracking'
import { tokenSelector } from '../../selectors/auth-selectors'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import './tracker.scss'
import Category from './Category'
import { unconnectedAwareQuery } from './utils'
import getProp from '../User/User-details-components/Utils/get'

// mode='unconnected'
// careActivitiesListAPI={}
// careActivitiesTrackedAPI={}
// userIdentifierHeader=''
// userIdentifierParam=''

const CareManagement = (props) => {
  const { userEmail, userId } = useParams()
  const token = useSelector(tokenSelector)
  const [categories, setCategories] = useState([])
  const [careActivitiesTracked, setCareActivitiesTracked] = useState([])
  const [suggestedIdsByDoctor, setSuggestedIdsByDoctor] = useState([])

  const [frequencies, setFrequencies] = useState({})
  const [quantities, setQuantities] = useState({})

  const fetchCategories = async () => {
    if (props.getCareCategoriesAPI) {
      const response = await props.getCareCategoriesAPI({ token })
      const careCategories = getProp(response, 'data.careCategories', [])
      setCategories(careCategories)
    } else {
      const response = await getCareCategories({ token })
      const careCategories = getProp(response, 'data.careCategories', [])
      setCategories(careCategories)
    }
  }

  const fetchCareActivitiesTracked = async () => {
    const queryObj = {
      token,
      userid: userId,
      treatmentPlan: props.treatmentPlan,
      includeInactive: true
    }
    if (props.mode === 'unconnected') {
      delete queryObj.userid
      queryObj.userEmail = userEmail
    }
    const response = await props.careActivitiesTrackedAPI(queryObj)
    setCareActivitiesTracked(response.data.data)
  }

  const fetchSuggestedByDoctor = async () => {
    if (props.type !== 'activities') {
      return
    }
    const queryObj = {
      token,
      userid: userId,
      includeInactive: true,
      treatmentPlan: props.treatmentPlan
    }
    if (props.mode === 'unconnected') {
      delete queryObj.userid
      queryObj.userEmail = userEmail
    }
    const response = await props.careActivitiesTrackedAPI(queryObj)
    const allTracked = getProp(response, 'data.data', [])
    const suggestedIdsByDoctor = allTracked.filter(item => item.startedBy === 'DOCTOR').map(item => item.careActivityId)
    setSuggestedIdsByDoctor(suggestedIdsByDoctor)
  }

  const addCareActivityTracked = (careActivityId, careCategoryId, frequency, quantity, isDoctorOnly, careActivityLabel) => {
    const newCareActivitiesTracked = [...careActivitiesTracked, {
      careActivityId,
      careCategoryId,
      startedBy: 'DOCTOR',
      isActive: true,
      frequency: frequency,
      quantity: quantity,
      isDoctorOnly
    }]
    setCareActivitiesTracked(newCareActivitiesTracked)
    const queryObj = {
      token,
      userid: userId,
      careActivityId,
      careCategoryId,
      treatmentPlan: props.treatmentPlan,
      frequency: frequency,
      quantity: quantity,
      isDoctorOnly,
      careActivityLabel
    }
    const queryObjUnconnectedAware = unconnectedAwareQuery(queryObj, props.mode, userEmail)
    props.postCareActivitiesTracked(queryObjUnconnectedAware)
    addToSuggestedIds(careActivityId)
  }

  const deleteCareActivityTracked = (careActivityId, careCategoryId, frequency, quantity, isDoctorOnly) => {
    const newCareActivitiesTracked = careActivitiesTracked.filter(activity => {
      return activity.careActivityId !== careActivityId
    })
    setCareActivitiesTracked(newCareActivitiesTracked)
    const queryObj = {
      token,
      userid: userId,
      careActivityId,
      careCategoryId,
      treatmentPlan: props.treatmentPlan,
      frequency: frequency,
      quantity: quantity,
      isDoctorOnly
    }
    const queryObjUnconnectedAware = unconnectedAwareQuery(queryObj, props.mode, userEmail)
    props.deleteCareActivitiesTracked(queryObjUnconnectedAware)
    removeFromSuggestedIds(careActivityId)
  }

  const addToSuggestedIds = (careActivityId) => {
    setSuggestedIdsByDoctor([...suggestedIdsByDoctor, careActivityId])
  }

  const removeFromSuggestedIds = (careActivityId) => {
    const idsWithoutSpecified = suggestedIdsByDoctor.filter(item => item !== careActivityId)
    setSuggestedIdsByDoctor(idsWithoutSpecified)
  }

  useEffect(() => {
    fetchCategories()
    fetchCareActivitiesTracked()
    fetchSuggestedByDoctor()
  }, [userEmail])

  return (
    <div className={props.wrapperClass}>
      {categories.map(category => {
        return <Category
          key={category.careCategoryId}
          {...props}
          careCategoryId={category.careCategoryId}
          token={token}
          heading={category.careCategoryName}
          userEmail={userEmail}
          userId={userId}
          careActivitiesTracked={careActivitiesTracked}
          addCareActivityTracked={addCareActivityTracked}
          deleteCareActivityTracked={deleteCareActivityTracked}
          suggestedIdsByDoctor={suggestedIdsByDoctor}
          quantities={quantities}
          frequencies={frequencies}
          setQuantities={setQuantities}
          setFrequencies={setFrequencies}
        />
      })}
    </div>
  )
}

export default CareManagement
