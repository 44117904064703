import axios from '../axios'
import endpoints from '../../config/endpoints'

export default async (patientId, token, action) => {
  const body = {
    patientId,
    token,
    action
  }
  try {
    const response = await axios.post(endpoints.DOCTOR_PATIENT_SESSION, body)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}
