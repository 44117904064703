import React from 'react'
import Icon from '../Common/Icon'
import { NotificationIconProps } from './interfaces'

const NotificationIcon = (props: NotificationIconProps) => {
  return (
    <div className='notification-icon temp-hide'>
      <Icon name='notificationUnread' className='notification-unread-icon' />
    </div>
  )
}

export default NotificationIcon
