import React, { Fragment } from 'react'

const AsiCaseListHeader = () => {
  return (
    <Fragment><thead>
    <tr>
      <td className='align-middle'>Date</td>
      <td className='align-middle text-center'>Case Type</td>
      <td className='align-middle text-center'>Condition Type</td>
      <td></td>
    </tr>
  </thead>
    </Fragment>
  )
}

export default AsiCaseListHeader