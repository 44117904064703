export const repeatAsync = (asyncFunc, interval) => {
  let clearTimeoutId: any
  const repeater = async () => {
    await asyncFunc()
    clearTimeoutId = setTimeout(repeater, interval);
  }
  repeater()
  return () => {
    clearTimeout(clearTimeoutId)
  }
}
