import 'react-image-lightbox/style.css'
import React from 'react'
import Lightbox from 'react-image-lightbox'
import { connect } from 'react-redux'
import { closeImageLightbox } from '../../actions/uiActions'

const ImageLightbox = (props) => {
    if (props.lightBoxOpen) {
        return (
          <Lightbox
            mainSrc={props.lightBoxUrl}
            onCloseRequest={() => props.dispatcher(closeImageLightbox())}
          />
        )
    } else {
        return null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatcher : (action) => {
        dispatch(action)
      }
    }
}
  
const mapStateToProps = (state) => {
    return {
      state : state,
      lightBoxOpen: state.uiReducer.imageLightBoxOpen,
      lightBoxUrl: state.uiReducer.imageLightBoxUrl
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageLightbox)
