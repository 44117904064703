import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BuildUrl from 'build-url'
import loadable from 'react-loadable'
import qs from 'querystring'

import { MAX_USERS_COUNT } from '../../constants'
import LoaderButton from '../UI-helper-components/Loader-button'
import usersListApi from '../../Api/get-users'
import UserList from './User-list'
import PageNavigator from '../UI-helper-components/page-navigator'
import roles, { actions } from '../../config/roles'
import ScreenHeader from '../Common/Screen-header'
import CTAButton from '../Common/Cta-button'
import Can from '../UI-helper-components/Can'
import getAnonReport from '../../Api/get-anon-report'
import updateFitzpatrickByUser from '../../Api/update-fitzpatrick-by-user'

const JsonEditor = loadable({
  loader: () => import('../UI-helper-components/Json-editor'),
  loading: () => <p>Loading...</p>
})

class OpenCases extends Component {
  
  constructor(props) {
    super(props)
    this.queryParamValue = this.queryParamValue.bind(this)
  }
  
  queryParamValue = (paramName) => {
    if(paramName[0] === '?') {
      return qs.parse(this.props.location.search)[paramName] || qs.parse(this.props.location.search)[paramName.replace('?', '')] || ''
    }
    return qs.parse(this.props.location.search)[paramName] || ''
  }
  
  state = {
    userList: [],
    usersLoaded: false,
    isSpinnerOn: false,
    isFullScreenLoaderOn: false,
    isButtonLoaderOn: false,
    page: this.props.match.params.pageNumber - 1,
    totalUsers: 0,
    navigatorIndex: 1,
    searchTerm: this.queryParamValue('?search') || '' ,
    activeSearchTerm: '',
    queryTerm: this.queryParamValue('query') && atob(this.queryParamValue('query')) || '',
    activeQueryTerm: '',
    isReportDownloading: false
  }

  toggleSpinner = () => {
    this.setState(({isSpinnerOn}) => {
      return {
        isSpinnerOn: !isSpinnerOn
      }
    })
  }

  toggleFullScreenLoader = () => {
    this.setState(({isFullScreenLoaderOn}) => ({
      isFullScreenLoaderOn: !isFullScreenLoaderOn
    }))
  }
  handleFitzpatrickValueChange = (data, selectedOption) => {
    updateFitzpatrickByUser( data.id, selectedOption, this.props.token)
      .then(res => {
        if(res){
          alert('Data updated successfully')
          this.toggleSpinner()
          this.getUsersData()
        }
      })
      .catch(err => {
        // this.toggleSpinner()
        alert('Error. Please retry')
        this.toggleSpinner()
        this.getUsersData()
      })
  }
  componentDidMount () {
    this.toggleSpinner()
    this.getUsersData()
  }

  getUsersData = () => {
    let activeSearchTerm = this.state.searchTerm
    let activeQueryTerm = this.state.queryTerm
    usersListApi(this.props.token, this.state.page * MAX_USERS_COUNT, this.state.searchTerm, this.state.queryTerm)
      .then(res => {
        let data = res.data && res.data.data
        this.toggleSpinner()
        this.setState(() => {
          return {
            userList: data.careUsersData,
            usersLoaded: true,
            totalUsers: data.careUsersCount,
            activeSearchTerm: activeSearchTerm,
            activeQueryTerm: activeQueryTerm
          }
        })
      })
      .catch(err => {
        console.log(err)
        this.toggleSpinner()
        alert('Error. Please retry')
      })
  }

  loaderButtonClickHandler = async (e) => {
    if(e) e.preventDefault()
    this.setState(() => ({
      isButtonLoaderOn: true
    }))
    try {
      let activeSearchTerm = this.state.searchTerm
      let activeQueryTerm = this.state.queryTerm
      const res = await usersListApi(this.props.state.authReducer.token, this.state.page * MAX_USERS_COUNT, this.state.searchTerm, this.state.queryTerm)
      this.setState((prevState) => {
        if(prevState.userList !== res.data.data.careUsersData){
          return {
            isButtonLoaderOn: false,
            userList: res.data.data.careUsersData,
            totalUsers: res.data.data.careUsersCount,
            activeSearchTerm: activeSearchTerm,
            activeQueryTerm: activeQueryTerm
          }
        }
      })
    } catch (error) {
      this.setState(() => ({
        isButtonLoaderOn: false
      }))
      alert('Error. Please retry.')
    }
  }

  getReadablePageNumber = () => {
    return this.state.page + 1
  }

  getPageCountText = () => {
    if(this.state.userList.length === 0 ) {
      return 'No users found'
    }
    return `${this.getReadablePageNumber()} of ${Math.ceil(this.state.totalUsers / MAX_USERS_COUNT)} pages (total: ${this.state.totalUsers})`
  }

  goNextPage = () => {
    const urlQueryParams = {
      search: this.queryParamValue('?search'),
      query: this.queryParamValue('query')
    }

    const urlStr = BuildUrl('', {
      path: `/users/page/${this.getReadablePageNumber() + 1}`,
      queryParams: urlQueryParams
    })
    this.props.history.replace(urlStr)
    this.setState((prevState) => ({
      page: prevState.page + 1
    }), this.loaderButtonClickHandler)
  }

  goPrevPage = () => {
    const urlQueryParams = {
      search: this.queryParamValue('?search'),
      query: this.queryParamValue('query')
    }
    const urlStr = BuildUrl('', {
      path: `/users/page/${this.getReadablePageNumber() - 1}`,
      queryParams: urlQueryParams
    })
    this.props.history.replace(urlStr)
    this.setState((prevState) => ({
      page: prevState.page - 1
    }), this.loaderButtonClickHandler)
  }

  isNextDisabled = () => {
    return ((this.state.page + 1) * MAX_USERS_COUNT) >= this.state.totalUsers
  }

  isPrevDisabled = () => {
    return this.state.page === 0
  }

  submitPageNumber = (pageNumber) => {
    this.props.history.replace(`/users/page/${pageNumber}`)
    let actualPageNumber = pageNumber - 1
    this.setState(() => ({
      page: actualPageNumber
    }), this.loaderButtonClickHandler)
  }

  isButtonLoaderOn = () => {
    return this.state.isButtonLoaderOn
  }

  searchAction = () => {
    this.setState(() => {
      return {
        page: 0,
        totalUsers: 0,
        navigatorIndex: 1
      }
    }, () => {
      this.props.history.replace(`/users/page/1?search=${this.state.searchTerm}&query=${btoa(this.state.queryTerm)}`)
      this.loaderButtonClickHandler()
    })
  }

  searchOnSubmit = () => {
    this.setState(() => {
      return {
        queryTerm: this.state.queryTerm.trim()
      }
    }, this.searchAction)
  }

  searchOnChange = (e) => {
    const searchTerm = e.target.value
    this.setState(() => ({
      searchTerm
    }))
  }

  searchOnClear = () => {
    this.props.history.replace(`/users/page/1`)
    this.setState(() => {
      return {
        userList: [],
        page: 0,
        totalUsers: 0,
        navigatorIndex: 1,
        searchTerm: '',
        activeSearchTerm: '',
        queryTerm: '',
        activeQueryTerm: ''
      }
    }, this.loaderButtonClickHandler)
  }

  getSearchResultText = () => {
    if(this.state.activeSearchTerm) {
      return <p className='search-term-result-text'>Search result for <span className='search-term-text'>{this.state.activeSearchTerm}</span></p>
    } else {
      return null
    }
  }

  searchOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.searchOnSubmit()
    }
  }

  queryOnChange = (text) => {
    const queryTerm = text
    this.setState(() => ({
      queryTerm
    }))
  }

  ctaOnClick = () => {
    this.props.history.push(`/invite-patient`)
  }

  removePendingUser = (userEmail) => {
    this.setState(() => ({
      userList: this.state.userList.filter((user) => {
        return user.email !== userEmail
      })
    }))
  }

  render () {
    return (
      <React.Fragment>
        <ScreenHeader heading='Patients' button={(
          <Can 
            action={actions.create_patient}
            yes={() => (
              <CTAButton
                text='Add New Patient'
                onClick={this.ctaOnClick}
                icon={(
                    <i className="fa fa-plus" aria-hidden="true"></i>
                )}
              />  
            )}
          />
        )} />
        <Can
          action={actions.download_anon_report}
          yes={() =>  {
            return (
              <CTAButton
                style={{
                  width: '200px',
                  marginBottom: '15px'
                }}
                text={this.state.isReportDownloading? 'Downloading...': 'Download report'}
                onClick={async () => {
                  try {
                    this.setState(() => ({
                      isReportDownloading: true
                    }))
                    await getAnonReport(this.props.token)
                    this.setState(() => ({
                      isReportDownloading: false
                    }))
                  } catch (error) {
                    this.setState(() => ({
                      isReportDownloading: false
                    }))
                  }
                }}
                icon={(
                    <i className="fa fa-download" aria-hidden="true"></i>
                )}
              />
            )
          }}
        />
        <div className='screen-content users-screen'>
          <div>
            <div className='users-header'>

              {/* <div className='search-bar'>
                <div className="form-group has-search">
                  <input onKeyDown={this.searchOnKeyDown} onChange={this.searchOnChange} value={this.state.searchTerm} type="text" className="form-control" placeholder="Search by email or country name" />
                  <span className="fa fa-search form-control-feedback"></span>
                </div>
                <div className='search-area-buttons'>
                  <button onClick={this.searchOnSubmit} type="button" className="btn btn-primary search-btn">Search</button>
                  <button onClick={this.searchOnClear} type="button" className="btn btn-primary clear-btn">Clear</button>
                </div>
              </div> */}

            {/* customstarts */}
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div class="input-group">
                      <input class="form-control py-2 border-right-0 border" type="text" onKeyDown={this.searchOnKeyDown} onChange={this.searchOnChange} value={this.state.searchTerm} placeholder="Search Patients" />
                      <div class="input-group-append">
                          <div class="input-group-text" style={{ backgroundColor: '#fff'}}><i style={{color: 'grey'}} class="fa fa-search"></i></div>
                      </div>
                </div>
              </div>
            </div>
            {/* customends */}

            </div>
            <Can
              action={actions.raw_query}
              yes={() => (
                <div className='query-input form-group'>
                  <JsonEditor
                    theme="ace/theme/github"
                    onChange={this.queryOnChange}
                    value={this.state.queryTerm}
                  />
                </div>
              )}
            />
          </div>
          <Can
            action={actions.raw_query}
            yes={() => (
              <div>
                <button onClick={this.searchOnSubmit} type="button" className="btn btn-primary search-btn">Search</button>
                <button onClick={this.searchOnClear} type="button" className="btn btn-primary clear-btn">Clear</button>
            </div>
            )}
          />
          <div>
            {this.getSearchResultText()}
          </div>
          {
            // the below part is hidden temporarily during design. it must be uncommented before publishing
          }
          {this.state.usersLoaded && <div className="row">
            <div className="col-md-12 list-page-heading-wrapper">
              <p className='page-count-text-heading'>{this.getPageCountText()}</p>
              <LoaderButton
                clickHandler={this.loaderButtonClickHandler}
                isLoading={this.state.isButtonLoaderOn}
              />
            </div>
          </div>}
          {this.state.isFullScreenLoaderOn && <div className='tibot-full-screen-loader'></div>} 
          {this.state.isSpinnerOn && <div className='square-spinner'></div>}
          {this.state.usersLoaded && this.state.userList.length > 0 && <UserList
            token={this.props.token}
            isAdmin={this.props.isAdmin}
            userList={this.state.userList}
            isCoverLoaderOn={this.isButtonLoaderOn()}
            removePendingUser={this.removePendingUser}
            handleFitzpatrickValueChange={this.handleFitzpatrickValueChange}
          />}
        </div>
        {this.state.usersLoaded && this.state.userList.length > 0 && <PageNavigator
          key={this.getReadablePageNumber()}
          currentPageNumber={this.getReadablePageNumber()}
          goNext={this.goNextPage}
          goPrevious={this.goPrevPage}
          isNextDisabled={this.isNextDisabled()}
          isPrevDisabled={this.isPrevDisabled()}
          submitPageNumber={this.submitPageNumber}
        />}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state,
    isAdmin: state.authReducer.isAdmin,
    token: state.authReducer.token
  }
}

export default connect(mapStateToProps)(withRouter(OpenCases))