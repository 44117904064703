import React, {useEffect, useState} from 'react'
import CareManagement from '../Care-management/Care-management'
import {
    deleteCareActivitiesTrackedUnconnected,
    getAllTreatmentPlan,
    getCareActivitiesListByUserUnconnected,
    getCareActivitiesTrackedByUserUnconnected,
    getCategoryInstructionUnconnected,
    postApplyPresetUnconnected,
    postCareActivitiesListUnconnected,
    postCareActivitiesTrackedUnconnected,
    updateActivityMetaUnconnected,
    updateCategoryInstructionUnconnected
} from '../../Api/Manage-tracking/manage-tracking'
import {useParams} from 'react-router-dom'
import TriggerTrackedUnconnected from '../Trigger-tracker/Trigger-tracker-unconnected'
import Tab from '../UI-helper-components/Careplan-tab'
import PresetSelector from '../User/Preset-selector'
import getProp from "../User/User-details-components/Utils/get";
import {useSelector} from "react-redux";
import {tokenSelector} from "../../selectors/auth-selectors";

const Unconnecteduser = (props) => {
    const {userId} = useParams()
    const token = useSelector(tokenSelector)
    const {userEmail} = useParams()
    const [treatmentPlan, setTreatmentPlan] = useState(0)
    const [trackerKey, setTrackerKey] = useState(0)
    const [treatmentPlans, setTreatmentPlans] = useState(0)
    const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState(0)
    const [targetTreatmentPlanValue, setTargetTreatmentPlanValue] = useState(0)

    const onPresetChange = () => {
        setTrackerKey(Math.random())
        setTreatmentPlan(targetTreatmentPlanValue)
    }

    const onChangeHandler = (item) => {
        let treatmentPlan;
        if(item) {
            treatmentPlan = treatmentPlans.find(o => o.value === item.value)
        } else {
            treatmentPlan = treatmentPlans[0]
        }
        setTargetTreatmentPlanValue(treatmentPlan.value)
        setSelectedTreatmentPlan(treatmentPlan)
    }

    const fetchAllTreatmentPlan = async () => {
        const queryObj = {
            token
        }
        const response = await getAllTreatmentPlan(queryObj)
        const allTreatmentPlan = getProp(response, 'data.data', [])
        if (!Array.isArray(allTreatmentPlan) || allTreatmentPlan.length < 1) {
            return
        }
        setTreatmentPlans(allTreatmentPlan)
        setSelectedTreatmentPlan(allTreatmentPlan[0])
    }

    useEffect(() => {
        fetchAllTreatmentPlan()
    }, [userId])

    return (
        <div className='container' key={trackerKey}>
            <h3 className='unconnected-user-email'>{userEmail}</h3>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='care-activity-tracker-unconnected'>
                        <Tab
                            navProps={{
                                className: 'nowrap-nav nav-custom-styles'
                            }}
                            headings={['Suggest Care Activities', 'Track Triggers']}
                            components={[
                                (
                                    <div>
                                        <h4 className='suggest-care-activity-headline'>Suggest Care Activities</h4>
                                        <div className='preset-treatment-wrapper'>
                                            <div className='preset-empty-div'></div>
                                            {selectedTreatmentPlan && <PresetSelector
                                                targetTreatmentPlan={targetTreatmentPlanValue}
                                                treatmentPlan={treatmentPlan}
                                                onPresetChangeSuccess={onPresetChange}
                                                postApplyPreset={postApplyPresetUnconnected}
                                                treatmentPlans={treatmentPlans}
                                                showCreatable={false}
                                                value={selectedTreatmentPlan}
                                                onChange={onChangeHandler}
                                            />}
                                        </div>
                                        <CareManagement
                                            key={treatmentPlan}
                                            {...props}
                                            mode='unconnected'
                                            type='activities'
                                            careActivitiesListAPI={getCareActivitiesListByUserUnconnected}
                                            careActivitiesTrackedAPI={getCareActivitiesTrackedByUserUnconnected}
                                            postCareActivitiesTracked={postCareActivitiesTrackedUnconnected}
                                            deleteCareActivitiesTracked={deleteCareActivitiesTrackedUnconnected}
                                            postCareActivitiesListAPI={postCareActivitiesListUnconnected}
                                            userIdentifierHeader='userEmail'
                                            userIdentifierParam='userEmail'
                                            wrapperClass='unconnected-care-management'
                                            treatmentPlan={treatmentPlan}
                                            updateActivityMeta={updateActivityMetaUnconnected}
                                            updateCategoryInstruction={updateCategoryInstructionUnconnected}
                                            getCategoryInstruction={getCategoryInstructionUnconnected}
                                        />
                                    </div>
                                ),
                                (
                                    <TriggerTrackedUnconnected
                                        {...props}
                                        heading='Track Triggers'
                                    />
                                )
                            ]}
                            className='tab-wrapper tab-wrapper-unconnected'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unconnecteduser
