import React, {useEffect, useState} from 'react'
import CareManagement from '../Care-management/Care-management'
import {
    deleteCareActivitiesTracked,
    getAllTreatmentPlan,
    getCareActivitiesListByUser,
    getCareActivitiesTrackedByUser,
    getCategoryInstruction,
    postApplyPreset,
    postCareActivitiesList,
    postCareActivitiesTracked,
    postNewTreatmentPlan,
    updateActivityMeta,
    updateCategoryInstruction
} from '../../Api/Manage-tracking/manage-tracking'
import Selector from './Color-Selector'
import PresetSelector from './Preset-selector'
import {useSelector} from "react-redux";
import {tokenSelector} from "../../selectors/auth-selectors";
import getProp from "./User-details-components/Utils/get";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

const CareTracker = (props) => {
    const {userId} = useParams()
    const token = useSelector(tokenSelector)
    const [treatmentPlan, setTreatmentPlan] = useState(0)
    const [treatmentPlans, setTreatmentPlans] = useState(0)
    const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState(0)
    const [trackerKey, setTrackerKey] = useState(0)
    const [targetTreatmentPlanValue, setTargetTreatmentPlanValue] = useState(0)

    const onChangeHandler = (item) => {
        let treatmentPlan;
        if (item) {
            treatmentPlan = treatmentPlans.find(o => o.value === item.value)
        } else {
            treatmentPlan = treatmentPlans[0]
        }
        setTreatmentPlan(treatmentPlan.value)
        setSelectedTreatmentPlan(treatmentPlan)
    }

    const onChangeHandlerPreset = (item) => {
        let treatmentPlan;
        if (item) {
            treatmentPlan = treatmentPlans.find(o => o.value === item.value)
        } else {
            treatmentPlan = treatmentPlans[0]
        }
        setTargetTreatmentPlanValue(treatmentPlan.value)
        setSelectedTreatmentPlan(treatmentPlan)
    }

    const onPresetChange = () => {
        setTrackerKey(Math.random())
    }

    const fetchAllTreatmentPlan = async (showLastPlan = false) => {
        const queryObj = {
            token
        }
        const response = await getAllTreatmentPlan(queryObj)
        const allTreatmentPlan = getProp(response, 'data.data', [])
        setTreatmentPlans(allTreatmentPlan)
        if (!Array.isArray(allTreatmentPlan) || allTreatmentPlan.length < 1) {
            return
        }
        const plan = showLastPlan ? allTreatmentPlan[allTreatmentPlan.length - 1] : allTreatmentPlan[0]
        setSelectedTreatmentPlan(plan)
        setTreatmentPlan(plan.value)
    }

    const handleTreatmentPlanCreate = async (newValue, actionMeta) => {
        try {
            const obj = {
                token: token,
                label: newValue,
                color: '#36B37E',
                custom: true
            }
            await postNewTreatmentPlan(obj)
            toast(`${newValue} has been created successfully`, {
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                type: 'success'
            })
            await fetchAllTreatmentPlan(true);
        } catch (error) {
            toast('Error occured. Please retry', {
                type: 'error',
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                autoClose: false
            })
        }
    }

    const addTreatmentPlanHandler = async (e) => {
        e.stopPropagation()
        const presetName = window.prompt('Type a new preset name')
        if (!presetName) return

        try {
            const obj = {
                token: token,
                label: presetName,
                color: '#36B37E',
                custom: true
            }
            await postNewTreatmentPlan(obj)
            toast(`${presetName} has been created successfully`, {
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                type: 'success'
            })
            await fetchAllTreatmentPlan(true);
        } catch (error) {
            toast('Error occurred. Please retry', {
                type: 'error',
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                autoClose: false
            })
        }
    }

    useEffect(() => {
        fetchAllTreatmentPlan()
    }, [userId])

    // treatmentplan for the user's tab will always be 0. But for preset, it is required to function
    // as before.
    let uiTreatmentPlan = props.ignoreTreatmentPlan? 0: treatmentPlan
    return (
        <div className='row' key={trackerKey}>
            <div className='col-sm-12'>
                <div className='care-tracker-wrapper-div'>
                    {!props.shouldHideHeading &&
                    <h4 className='suggest-care-activity-headline'>{props.heading || 'Suggest care activities to the patient'}</h4>}
                    <div className='preset-treatment-wrapper'>
                        <div className='preset-empty-div'></div>
                        {props.showTreatmentPlan && <label className={'treatment-plan-name-label'}>Preset Name</label>}
                        {props.showTreatmentPlan ? <Selector
                            treatmentPlans={treatmentPlans}
                            onChange={onChangeHandler}
                            selected={treatmentPlan}
                            handleCreate={handleTreatmentPlanCreate}
                            showCreatable={true}
                            value={selectedTreatmentPlan}
                        /> : <div></div>}
                        {props.showTreatmentPlan &&
                        <div className={'add-treatment-plan'} onClick={addTreatmentPlanHandler}><i
                            className="fa fa-plus" aria-hidden="true"></i></div>}
                        {props.showPresetSelector && <label className={'preset-name-label'}>Preset Name</label>}
                        {props.showPresetSelector && selectedTreatmentPlan ? <PresetSelector
                            targetTreatmentPlan={targetTreatmentPlanValue}
                            treatmentPlan={treatmentPlan}
                            onPresetChangeSuccess={onPresetChange}
                            postApplyPreset={postApplyPreset}
                            treatmentPlans={treatmentPlans}
                            onChange={onChangeHandlerPreset}
                            value={selectedTreatmentPlan}
                        /> : <div className='preset-empty-div'></div>}
                    </div>
                    <CareManagement
                        key={treatmentPlan}
                        wrapperClass='care-tracker care-tracker--user'
                        {...props}
                        mode='connected'
                        careActivitiesListAPI={getCareActivitiesListByUser}
                        careActivitiesTrackedAPI={getCareActivitiesTrackedByUser}
                        postCareActivitiesTracked={postCareActivitiesTracked}
                        deleteCareActivitiesTracked={deleteCareActivitiesTracked}
                        postCareActivitiesListAPI={postCareActivitiesList}
                        userIdentifierHeader='userId'
                        userIdentifierParam='userId'
                        treatmentPlan={uiTreatmentPlan}
                        updateActivityMeta={updateActivityMeta}
                        updateCategoryInstruction={updateCategoryInstruction}
                        getCategoryInstruction={getCategoryInstruction}
                    />
                </div>
            </div>
        </div>
    )
}

export default CareTracker
