import moment from 'moment'
import React from 'react'

const ConsultationRow = ({
  createdAt,
  isComplete,
  doctorNote,
  prescription,
  completedAt,
  patientMessage,
  history,
  id,
  patientId,
  patientEmail
}) => {
  return (
    <tr
      onClick={() => {
        history.push(`/user/${patientId}?currTab=6&consultationId=${id}`)
      }}
      style={{
        cursor: 'pointer'
      }}
    >
      <td>
        {moment(createdAt).format('ll')}
      </td>
      <td>
        {patientEmail}
      </td>
      <td>
        {isComplete ? 'Complete' : 'Incomplete'}
      </td>
      <td>
        {doctorNote}
      </td>
      <td>
        {prescription}
      </td>
      <td>
        {patientMessage}
      </td>
      <td>{completedAt ? moment(completedAt).format('ll') : null}</td>
      <td style={{
        color: 'darkblue',
        textAlign: 'center'
      }}
      >
        open
      </td>
    </tr>
  )
}

export default ConsultationRow
