  const initialState = {
    portalMode: 'EczemaLess',             // default selected tab is 'EczemaLess'.
    redirectRoute: {}
  };
  
  const portalModeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_PORTAL_MODE':
        return {
          ...state,
          portalMode: action.payload,
        }
      case 'SET_REDIRECT_ROUTE':
        return {
          ...state,
          redirectRoute: action.payload,
        }
      default:
        return state;
    }
  };
  
  export default portalModeReducer;