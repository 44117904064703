import endpoints from '../../config/endpoints'
import {makeCall} from '../apiFactory'

export const getCareCategories = ({
  token
}) => {
  return makeCall({
    url: endpoints.CARE_CATEGORIES,
    method: 'get',
    headers: {
      token
    }
  })
}

export const getCareActivitiesListByUser = ({
  token,
  userid,
  careCategoryId,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVIIIES_LIST_BY_USER,
    method: 'get',
    headers: {
      token,
      careCategoryId,
      userid,
      treatmentPlan
    }
  })
}

export const getCareActivitiesListByUserUnconnected = ({
  token,
  careCategoryId,
  userEmail,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVITIES_LIST_UNCONNECTED,
    method: 'get',
    headers: {
      token,
      careCategoryId,
      userEmail,
      treatmentPlan
    }
  })
}

export const getCareActivitiesTrackedByUser = ({
  token,
  userid,
  treatmentPlan,
  includeInactive
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVITY_TRACKED_BY_USER,
    method: 'get',
    headers: {
      token,
      userid,
      treatmentPlan,
      includeInactive
    }
  })
}

export const getCareActivitiesTrackedByUserUnconnected = ({
  token,
  userEmail,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVITY_TRACKED_BY_USER_UNCONNECTED,
    method: 'get',
    headers: {
      token,
      userEmail,
      treatmentPlan
    }
  })
}

export const postCareActivitiesTracked = ({
  userid,
  careActivityId,
  careCategoryId,
  token,
  treatmentPlan,
  frequency,
  quantity,
  isDoctorOnly,
  careActivityLabel
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVITY_TRACKED_BY_USER,
    method: 'post',
    body: {
      userid,
      careActivityId,
      careCategoryId,
      token,
      treatmentPlan,
      frequency,
      quantity,
      isDoctorOnly,
      careActivityLabel
    }
  })
}

export const updateActivityMeta = ({
  userid,
  careActivityId,
  careCategoryId,
  token,
  treatmentPlan,
  frequency,
  quantity,
  careActivityLabel
}) => {
  return makeCall({
    url: endpoints.UPDATE_ACTIVITY_META,
    method: 'post',
    body: {
      userid,
      careActivityId,
      careCategoryId,
      token,
      treatmentPlan,
      frequency,
      quantity,
      careActivityLabel
    }
  })
}

export const updateActivityMetaUnconnected = ({
  userEmail,
  careActivityId,
  careCategoryId,
  token,
  treatmentPlan,
  frequency,
  quantity,
  careActivityLabel
}) => {
  return makeCall({
    url: endpoints.UPDATE_ACTIVITY_META_UNCONNECTED,
    method: 'post',
    body: {
      userEmail,
      careActivityId,
      careCategoryId,
      token,
      treatmentPlan,
      frequency,
      quantity,
      careActivityLabel
    }
  })
}

export const updateCategoryInstruction = ({
  userid,
  careCategoryId,
  token,
  treatmentPlan,
  instruction
}) => {
  return makeCall({
    url: endpoints.CATEGORY_INSTRUCTION,
    method: 'post',
    body: {
      userid,
      careCategoryId,
      token,
      treatmentPlan,
      instruction
    }
  })
}

export const updateCategoryInstructionUnconnected = ({
  userEmail,
  careCategoryId,
  token,
  treatmentPlan,
  instruction
}) => {
  return makeCall({
    url: endpoints.CATEGORY_INSTRUCTION_UNCONECTED,
    method: 'post',
    body: {
      userEmail,
      careCategoryId,
      token,
      treatmentPlan,
      instruction
    }
  })
}

export const getCategoryInstruction = ({
  userid,
  careCategoryId,
  token,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CATEGORY_INSTRUCTION,
    method: 'get',
    headers: {
      token,
      careCategoryId,
      userid,
      treatmentPlan
    }
  })
}

export const getCategoryInstructionUnconnected = ({
  userEmail,
  careCategoryId,
  token,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CATEGORY_INSTRUCTION_UNCONECTED,
    method: 'get',
    headers: {
      token,
      careCategoryId,
      userEmail,
      treatmentPlan
    }
  })
}

export const postCareActivitiesTrackedUnconnected = ({
  userEmail,
  careActivityId,
  careCategoryId,
  token,
  treatmentPlan,
  frequency,
  quantity,
  isDoctorOnly,
  careActivityLabel
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVITY_TRACKED_BY_USER_UNCONNECTED,
    method: 'post',
    body: {
      token,
      careActivityId,
      careCategoryId,
      userEmail,
      treatmentPlan,
      frequency,
      quantity,
      isDoctorOnly,
      careActivityLabel
    }
  })
}

export const deleteCareActivitiesTracked = ({
  token,
  userid,
  careActivityId,
  careCategoryId,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVITY_TRACKED_BY_USER,
    method: 'delete',
    headers: {
      token,
      userid,
      careActivityId,
      careCategoryId,
      treatmentPlan
    }
  })
}

export const deleteCareActivitiesTrackedUnconnected = ({
  token,
  userEmail,
  careActivityId,
  careCategoryId,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVITY_TRACKED_BY_USER_UNCONNECTED,
    method: 'delete',
    headers: {
      token,
      userEmail,
      careActivityId,
      careCategoryId,
      treatmentPlan
    }
  })
}

export const postCareActivitiesList = ({
  userid,
  careCategoryId,
  careActivityLabel,
  token,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVITIES_LIST,
    method: 'post',
    body: {
      userid,
      careCategoryId,
      careActivityLabel,
      token,
      treatmentPlan
    }
  })
}

export const postCareActivitiesListUnconnected = ({
  userEmail,
  careCategoryId,
  careActivityLabel,
  token,
  treatmentPlan
}) => {
  return makeCall({
    url: endpoints.CARE_ACTIVIIIES_LIST_ONLY_UNCONNECTED,
    method: 'post',
    body: {
      userEmail,
      careCategoryId,
      careActivityLabel,
      token,
      treatmentPlan
    }
  })
}

export const postApplyPreset = ({
  userid,
  token,
  treatmentPlan,
  targetTreatmentPlan
}) => {
  return makeCall({
    url: endpoints.APPLY_PRESET,
    method: 'post',
    body: {
      userid,
      token,
      treatmentPlan,
      targetTreatmentPlan
    }
  })
}

export const postApplyPresetUnconnected = ({
  userEmail,
  token,
  treatmentPlan,
  targetTreatmentPlan
}) => {
  return makeCall({
    url: endpoints.APPLY_PRESET_UNCONNECTED,
    method: 'post',
    body: {
      userEmail,
      token,
      treatmentPlan,
      targetTreatmentPlan
    }
  })
}

export const getAllTreatmentPlan = ({
    token
}) => {
  return makeCall({
    url: endpoints.TREATMENT_PLANS,
    method: 'get',
    headers: {
      token
    }
  })
}

export const postNewTreatmentPlan = ({
  token,
  label,
  color,
  custom
}) => {
  return makeCall({
    url: endpoints.TREATMENT_PLANS,
    method: 'post',
    body: {
      token,
      label,
      color,
      custom
    }
  })
}