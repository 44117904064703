import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import authReducer from '../reducers/authReducer'
import uiReducer from '../reducers/uiReducer'
import portalModeReducer from '../reducers/portalModeReducer'

export default () => {
  const middlewares = [thunk]
  const enhancers = applyMiddleware(...middlewares)
  const composedEnhancers = composeWithDevTools(enhancers)
  const store = createStore(combineReducers({
    authReducer,
    uiReducer,
    portalModeReducer
  }), composedEnhancers)
  return store
}
