import React from 'react'
import CareManagement from '../Care-management/Care-management'

import {
  getCareCategories,
  getCareActivitiesTrackedByUserUnconnected,
  getCareActivitiesListByUserUnconnected,
  postCareActivitiesTrackedUnconnected,
  deleteCareActivitiesTrackedUnconnected,
  postCareActivitiesListUnconnected
} from '../../Api/Manage-tracking/trigger-response-transformers'


const TriggerTrackerUnconnected = (props) => {
  return (
    <div className="row">
      <div className='col-sm-12'>
            <h4 className='suggest-care-activity-headline'>{props.heading}</h4>
            <CareManagement
              wrapperClass='care-tracker care-tracker--user'
              {...props}
              mode='unconnected'
              getCareCategoriesAPI={getCareCategories}
              careActivitiesListAPI={ getCareActivitiesListByUserUnconnected }
              careActivitiesTrackedAPI={ getCareActivitiesTrackedByUserUnconnected }
              postCareActivitiesTracked={ postCareActivitiesTrackedUnconnected }
              deleteCareActivitiesTracked={ deleteCareActivitiesTrackedUnconnected }
              postCareActivitiesListAPI={ postCareActivitiesListUnconnected }
              userIdentifierHeader='userEmail'
              userIdentifierParam='userEmail'
            />        
      </div>
    </div>
  )
}

export default TriggerTrackerUnconnected
