import React, {Component} from 'react'
import {connect} from 'react-redux'
import CareTracker from '../User/Care-tracker'

class SetPreset extends Component {
    render() {
        return (
            <div className='container'>
                <CareTracker
                    {...this.props}
                    heading='Set Suggestion Presets'
                    type='activities'
                    showTreatmentPlan={true}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps)(SetPreset)
