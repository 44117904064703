import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import config from '../../config/config'
import Login from '../../Api/login'
import { addToken, setIsAuthenticated, setIsAdmin, setUserRole } from '../../actions/authActions'
import { setUserEmail, setDoctorName } from '../../actions/uiActions'

import EczemaLogo from '../../assets/eczema-logo.png'

import { withRouter } from 'react-router-dom'
import qs from 'querystring'
import { Formik, Form } from 'formik'
import validationSchema from './validation-schema'
import { TextInput } from './Fields'
import { postConfirmResetPassword } from '../../Api/misc-apis/misc-apis'
import { getAPIErrorMessageData } from '../../utilityFunctions/error-handling'
import { uiEvent } from '../../events/main'

const StyledSignIn = styled.div`
max-width: 600px;
margin: 0 auto;
`
const LogoWrapper = styled.div`
text-align: center;
margin-bottom: 35px;
margin-top: 25px;
`

const CompleteRegistrationHeading = styled.div`
text-align: center;
margin-bottom: 30px;
`

function delay(ms) {
  return new Promise((resolve, reject) => setTimeout(reject, ms));
}

class CompleteResetPassword extends Component {
    
  queryParamValue = (paramName) => {
    if(paramName[0] === '?') {
          return qs.parse(this.props.location.search)[paramName] || qs.parse(this.props.location.search)[paramName.replace('?', '')] || ''
    }
    return qs.parse(this.props.location.search)[paramName] || ''
  }

  state = {
    isFSLoaderOpen: false
  }

  toggleFSLoader = () => {
    this.setState(({isFSLoaderOpen}) => {
      return {
        isFSLoaderOpen: !isFSLoaderOpen
      }
    })
  }

  toggleIsSubmitting = () => {
    this.setState(({isSubmitting}) => {
      return {
        isSubmitting: !isSubmitting
      }
    })
  }


  autoLogin = async (email, password) => {
    this.toggleFSLoader()
    try {
      const res = await Login(email, password)
      this.toggleFSLoader()
      this.props.dispatcher(setUserEmail(email))
      this.props.dispatcher(addToken(res.data.token))
      let isAdmin = res.data.role === 'ADMIN'
      this.props.dispatcher(setIsAdmin(isAdmin))
      this.props.dispatcher(setIsAuthenticated(true))
      this.props.dispatcher(setUserRole(res.data.role))
      uiEvent.emit('login', {
        role: res.data.role,
        token: res.data.token,
        dispatcher: this.props.dispatcher
      })
      this.props.history.replace(config.baseRoute + 'users/page/1')
    } catch (error) {
      this.toggleFSLoader()
      alert('Login failed. Please retry')
      console.log(error)
    }
  }

  handleSubmitAsync = async (values, actions) => {
      const {
        password,
        email
      } = values
      try {
        await postConfirmResetPassword({
          password,
          email,
          token: this.queryParamValue('token')
        })
        actions.setSubmitting(false)
        this.autoLogin(email, password)
      } catch (error) {
        actions.setSubmitting(false)
        const message = getAPIErrorMessageData(error)
        if(message) {
          window.alert(message)
        } else {
          window.alert('Error. Please retry')
        }
      }
  }

  formSubmitHandler = (values, actions) => {
    this.handleSubmitAsync(values, actions)
  }

  getSubmitButton = (isSubmitting) => {
    if(!isSubmitting) {
      return <button type="submit" className="btn btn-primary next-button">SUBMIT</button>
    } else {
      return (
      <button disabled={true} type="submit" className="btn btn-primary next-button">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        SUBMITTING...
      </button>
      )
    }
  }

  render () {
    return (
      <div className='container'>
       {this.state.isFSLoaderOpen && <div className='tibot-full-screen-loader'></div>} 
        <StyledSignIn>
          <LogoWrapper>
            <img src={EczemaLogo} alt="Logo" style={{
              maxWidth: '300px',
              margin: '0 auto'
            }}/>
          </LogoWrapper>
          <CompleteRegistrationHeading>
            <h4>Reset Password</h4>
          </CompleteRegistrationHeading>
          <Formik
            validationSchema={validationSchema}
            onSubmit={this.formSubmitHandler}
            initialValues={{
                password: '',
                confirmPassword: '',
                email: this.queryParamValue('?email')
            }}
          >
              {({ submitForm, isValid, values, setTouched, validateForm, isSubmitting }) => {
                return(
                    <Form>
                        <TextInput
                            key='email'
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="Your Email"
                            disabled={true}
                        />
                        <TextInput
                            key='password'
                            label="Password"
                            name="password"
                            type="password"
                            placeholder="New Password"
                        />
                        <TextInput
                            key='confirmPassword'
                            label="Confirm Password"
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                        />
                        {this.getSubmitButton(isSubmitting)}
                    </Form>
                )
            }}
          </Formik>
        </StyledSignIn>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher : (action) => {
      dispatch(action)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    state : state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompleteResetPassword))
