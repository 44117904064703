import queryString from 'query-string'
import jwtDecode from 'jwt-decode'
// extracts the error message from an axios error
export const extractErrorMessage = (error) => {
  return error && error.response && error.response.data && error.response.data.message
}

export const getIdFromToken = (token) => {
  try {
    const obj = jwtDecode(token)
    return obj.userId
  } catch (error) {
    console.log(error)
    return ''
  }
}

export const delay = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getParamValue = (key) => {
  const parsed = queryString.parse(window.location.search)
  return parsed && parsed[key]
}
