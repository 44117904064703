import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import DownloadReport from '../../Download-report'
import patientAvatar from '../../../../assets/v2/anon.png'
import moment from 'moment'
import { disconnectPatient } from '../../../../Api/misc-apis/misc-apis'
import Can from '../../../UI-helper-components/Can'
import { actions } from '../../../../config/roles'
import postDoctorPatientSession from '../../../../Api/Doctor-patient-session/post-doctor-patient-session'
import { delay } from '../../../../utilityFunctions/common'

const viewMessageThisPatient = (history, patientId, patientName, email) => {
  return null
  const onClick = () => {
    history.push(`/message/${patientId}`, {
      patientName: patientName,
      email: email
    })
  }
  return (<div className='btn btn-primary message-patient-btn' onClick={onClick}>
    Message Patient <i className="fa fa-envelope" aria-hidden="true"></i>
  </div>)
}

const DetailsField = ({
  detailsFieldTitle,
  detailsFieldValue
}) => {
  return (
    <React.Fragment>
      <div className="details-field">
        <div className="details-field__title">
          {detailsFieldTitle}
        </div>
        <div className="details-field__info">
          {detailsFieldValue}
        </div>
      </div>
    </React.Fragment>
  )
}

const PatientDetails = (props) => {
  const { setSessionDetails, patientName, doctorName, email, token, userId, history, gender, DOB } = props
  const [deleting, setDeleting] = useState(false)
  const formattedDOB = DOB? moment(DOB).format('LL') : ''
  return (
    <div className='patient-details-info details-block'>
      <div className="patient-details-avatar">
        <img className='patient-avatar-details' src={patientAvatar} />
        <div className='patient-details-and-message-button'>
          <DownloadReport userId={userId} token={token} />
        </div>
        </div>
      
      <div className='patient-details-info-list'>
        <DetailsField
          detailsFieldTitle='Gender'
          detailsFieldValue={gender}
        />
        <DetailsField
          detailsFieldTitle='Condition'
          detailsFieldValue=''
        />
        <DetailsField
          detailsFieldTitle='Email'
          detailsFieldValue={email}
        />
        <DetailsField
          detailsFieldTitle='Date of Birth'
          detailsFieldValue={formattedDOB}
        />
        <Can
          action={actions.disconnect_patient}
          yes={() => (
            <button onClick={async () => {
              const isConfimred = window.confirm('You will no longer be able to access this patient\'s information. Would you like to proceed?')
              if (isConfimred) {
                try {
                  setDeleting(true)
                  await postDoctorPatientSession(userId, token, 'stop')
                  setSessionDetails({
                    runningSince: null,
                    isCurrentSessionPaused: true
                  })
                  await delay(1000)
                  await disconnectPatient({
                    token: token,
                    patientId: userId
                  })
                  window.alert('Successfully disconnected')
                  setDeleting(false)
                  props.history.push(`/users/page/1`)
                } catch (error) {
                  window.alert('Error. Please retry.')
                }
              }
            }} className="primary btn btn-primary disconnect-button">{deleting? 'Deleting...': 'Disconnect with patient'}</button>
          )}
        />
      </div>
    </div>
  )
}

export default withRouter(PatientDetails)
