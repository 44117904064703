import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import roles, { actions, check } from '../../config/roles'

import config from '../../config/config'
import EczemaLogo from '../../assets/eczema-logo.png'
import PolyfinsLogo from '../../assets/v2/polyfins-logo.png'

import { setUnreadMessages } from '../../actions/uiActions'
import { getUnreadMessageCount } from '../../Api/Messaging/messaging'
import NotificationIcon from './Notification-icon'
import HeaderProfile from './Header-profile'

class Menu extends React.Component {

  state = {
    dropdownOpen: false
  }

  toggle = () => {
    this.setState((currentState) => ({
      dropdownOpen: !currentState.dropdownOpen
    }))
  }

  getMessageCount = () => {
    return this.props.state.uiReducer.unreadMessages.length
  }

  getLogoHolderLinkUrl = () => {
    if(check(this.props.userRole, actions.view_stats)) {
      return config.baseRoute + 'dashboard'
    } else {
      return config.baseRoute + 'users/page/1'
    }
  }

  componentDidMount () {
    if(this.props.state.authReducer.isAuthenticated && check(this.props.state.authReducer.userRole, actions.view_unread_messages)) {
      setTimeout(() => {
        getUnreadMessageCount({ token: this.props.state.authReducer.token })
        .then(res => {
          try {
            const unreadMessages = res.data.data
            this.props.dispatcher(setUnreadMessages)(unreadMessages)            
          } catch (error) {
            console.log(error)
          }
        })
        .catch(err => {
          console.log(err)
        })
      }, 10)
    }
  }

  render () {
    const logoImg = process.env.REACT_APP_NAME === 'Eczema'? EczemaLogo : PolyfinsLogo
    return (
      <Fragment>
      {this.props.state.authReducer.isAuthenticated?
        <div className='v2-menu-wrapper d-flex justify-content-between'>
          <div className='logo-holder-v2'>
            <NavLink to={this.getLogoHolderLinkUrl()}> <img alt='Tibot logo' src={logoImg} /> </NavLink>
          </div>
          <div className="header-content-holder">
            <NotificationIcon />
            <div style={{
              display: 'flex',
              flex: 1,
              marginTop: '5px',
              marginBottom: '5px',
              marginLeft: '25px',
              marginRight: '25px'
            }}>
              <div className='vertical-divider temp-hide'/>
            </div>
            <HeaderProfile name={this.props.userEmail} />
          </div>
        </div>
      : null }
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state,
    userRole: state.authReducer.userRole,
    shadowId: state.uiReducer.shadowId,
    userEmail: state.uiReducer.userEmail
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher : (action) => {
      return (arg) => {
        dispatch(action(arg))
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
