import { makeCall } from '../apiFactory'
import endpoints from '../../config/endpoints'

export const getUserStats = ({
    token,
    userid
  }) => {
    return makeCall({
      url: endpoints.GET_USER_STATS,
      method: 'get',
      headers: {
        token,
        userid
      }
    })
}
