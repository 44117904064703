import React from 'react'
import { ScreenHeaderProps } from './interfaces'

const ScreenHeader = (props: ScreenHeaderProps) => {
  return (
    <div className='screen-header'>
      <h3 className='screen-header__heading'>
        {props.heading}
      </h3>
      {props.button && <div className='screen-header__cta'>
        {props.button}
      </div>}
    </div>
  )
}

export default ScreenHeader
