import React from 'react'
import { IGABatchProps } from './interfaces'

const IGAValueToLabel = {
  0: 'Clear',
  1: 'Almost Clear',
  2: 'Mild',
  3: 'Moderate',
  4: 'Severe'
}

const IGABatch = (props: IGABatchProps) => {
  if (props.value === undefined) {
    return null
  }
  return (
    <div className={`iga-badge iga-${props.value}`}>
      {IGAValueToLabel[props.value]}      
    </div>
  )
}

export default IGABatch
