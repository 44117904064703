import moment from "moment";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table } from "reactstrap";

import endpoints from "../../config/endpoints";
import imageIdGenerator from "../../utilityFunctions/image-id-generator";
import UTCToLocal from "../../utilityFunctions/utc-to-local";
import Select from 'react-select';
import ReactAuthImage from "../UI-helper-components/react-auth-image";
import CaseListHeader from "./Case-list-header-user";

import loadingGif from "../../assets/loading.gif";
import config from "../../config/config";
import IGABatch from "../Common/IGA-batch";
import ItchBadge from "../Common/Itch-batch";
import getProp, { checkIGAValueHide } from "./User-details-components/Utils/get";

const getFormattedDate = (dateStr) => {
  return moment(dateStr);
};

const getLastSeverityBadge = (severityValue, lastSeverityCheckLabel) => {
  if (!severityValue && severityValue !== 0) {
    return null;
  }
  return <IGABatch value={severityValue} />;
};

const getItchBadge = (obj) => {
  return <ItchBadge {...obj} />;
};

const IGAValues = [
  { result: "IGA0", value: 0, label: 'Clear' },
  { result: "IGA1", value: 1, label: 'Almost Clear' },
  { result: "IGA2", value: 2, label: 'Mild' },
  { result: "IGA3", value: 3, label: 'Moderate' },
  { result: "IGA4", value: 4, label: 'Severe' }
];
const CaseList = (props) => {
  const isCompletedCases = props.listType === "completedCases";
  const momentNow = moment();
  const [showForm, setShowForm] = useState(null);


  const handleToggleForm = (data) => {
    setShowForm(data);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '180px', // Adjust the width as needed
    }),
  };
  return (
    <div className="need-to-copy">
      <Table className="users-list-table-v2 user-case-list-table">
        {props.casesLoaded && props.caseList.length === 0 ? (
          <div style={{ textAlign: "center" }}>No cases to show</div>
        ) : (
          <React.Fragment>
            <CaseListHeader
              {...props}
              fromQueryList={props.fromQueryList}
              listType={props.listType}
            />
            <tbody>
              {props.caseList &&
                props.caseList.map((singleCase, index) => {
                  let imagePathArr = singleCase.imagePath || [];
                  let lastImage = imagePathArr[imagePathArr.length - 1];
                  return (
                    <tr className="content-list-group-item" key={singleCase.id}>
                      <td className="case-image-v2 align-middle">
                        <div
                          style={{ cursor: "pointer" }}
                          className="case-image-inner"
                          onClick={() => {
                            props.history.push(
                              `${window.location.pathname}?currTab=5&caseId=${singleCase.id}`
                            );
                          }}
                        >
                          <ReactAuthImage
                            className="case-list-image"
                            targetUrl={endpoints.GET_IMAGE}
                            headers={{
                              imageId:
                                lastImage &&
                                lastImage["path"] &&
                                imageIdGenerator(lastImage["path"]),
                              token: props.state.authReducer.token,
                              caseId: singleCase.id,
                            }}
                            defaultImage={loadingGif}
                          />
                        </div>
                      </td>
                      <td className="align-middle">
                        {UTCToLocal(singleCase.date)}
                      </td>
                      {props.fromQueryList && (
                        <td className="align-middle">
                          <Link
                            to={config.baseRoute + "user/" + singleCase.user.id}
                          >
                            {" "}
                            {singleCase.user && singleCase.user.email}{" "}
                          </Link>
                        </td>
                      )}

                      <td className="align-middle text-center">
                        {singleCase.poemScore}
                      </td>
                    
                      {checkIGAValueHide(props.state.authReducer.userRole, ['STUDY_USER']) && (
                        <td className="align-middle text-left">
                          {getLastSeverityBadge(
                            singleCase.finalReplyJson &&
                            singleCase.finalReplyJson.value
                          )}
                        </td>
                      )}

                      <td className="align-middle text-left">
                        {singleCase.doctorIGAData ? <div>
                          {showForm?.id === singleCase.id ? (
                            <Select
                              onChange={(selectedOption) => { props.handleIGAValueChange(singleCase, selectedOption); setShowForm(null); }}
                              options={IGAValues}
                              isClearable
                              placeholder="Enter IGA value"
                              styles={customStyles}
                              value={singleCase.doctorIGAData ? singleCase.doctorIGAData : null}
                            />
                          ) : (
                            <div style={{ cursor: 'pointer' }} onClick={() => handleToggleForm(singleCase)}>
                              <IGABatch value={singleCase.doctorIGAData.value} />
                            </div>
                          )}</div> : <Select
                          onChange={(selectedOption) => { props.handleIGAValueChange(singleCase, selectedOption); setShowForm(null); }}
                          options={IGAValues}
                          isClearable
                          placeholder="Enter IGA value"
                          styles={customStyles}
                          value={singleCase.doctorIGAData ? singleCase.doctorIGAData : null}
                        />}

                      </td>
                      <td className="align-middle text-left">
                        {getItchBadge(
                          getProp(singleCase, "discomfort.itch", {})
                        )}
                      </td>
                      <td className="align-middle text-center">
                        <Link
                          to={`${window.location.pathname}?currTab=7&caseId=${singleCase.id}`}
                          className="case-open-link"
                        >
                          {" "}
                          Open{" "}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </React.Fragment>
        )}
      </Table>
    </div>
  );
};

export default withRouter(CaseList);