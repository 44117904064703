import React from 'react'
import { useField, Field } from 'formik'

const errorStyles = {
    display: 'inline-block',
    color: 'red',
    fontSize: '12px'
}

export const TextInput = ({label, ...props}) => {
    const [field, meta] = useField(props)
    return (
      <div className='form-group fg-multi-step'>
        <label className='label-multi-step' htmlFor={props.id || props.name}>{label} {props.label} {props.isNotRequired? '' : '*'} <span class="multi-step-sub">{props.subLabel}</span></label>
        <input className="form-control" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div style={errorStyles} className="multi-step-error">{meta.error}</div>
        ) : null}
      </div>
    )
}

export const PasswordInput = ({label, ...props}) => {
    const [field, meta] = useField(props)
    return (
      <div className='form-group fg-multi-step'>
        <label className='label-multi-step' htmlFor={props.id || props.name}>{label} {props.label} {props.isNotRequired? '' : '*'} <span class="multi-step-sub">{props.subLabel}</span></label>
        <input className="form-control" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div style={errorStyles} className="multi-step-error">{meta.error}</div>
        ) : null}
      </div>
    )
}
