import axios from './axios'
import endpoints from '../config/endpoints'

export const getAsiImage = async (token, imgPath) => {
  let imageDownloadHeaders = {
      responseType: 'blob',
      headers: {
          token: token,
          imageId: imgPath
      }
  }
  try {
      const response = await axios.get(endpoints.GET_ASI_IMAGE, imageDownloadHeaders)
      return response
  } catch (error) {
      return Promise.reject(error)
  }
}