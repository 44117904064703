import React from 'react'

const MessagePresetRow = ({
  createdAt,
  presetName,
  messageContent,
  id,
  deletePreset
}) => {
  return (
    <tr>
      <td>{createdAt}</td>
      <td>{presetName}</td>
      <td>{messageContent}</td>
      <td style={{ textAlign: 'center' }}>
        <i style={{ cursor: 'pointer' }} onClick={() => {
          deletePreset(id)
        }} className="fa fa-trash" aria-hidden="true"></i>
      </td>
    </tr>
  )
}
export default MessagePresetRow
