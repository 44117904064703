import React, { Component } from 'react'
import { Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import UserListHeading from './User-list-heading'
import utcToLocal from '../../utilityFunctions/utc-to-local'
import config from '../../config/config'
import getProp, { checkFitzpatrickShow, checkIGAValueHide } from '../../Components/User/User-details-components/Utils/get'
import IGABatch from '../Common/IGA-batch'
import deleteInvite from '../../Api/delete-invite'
import moment from "moment";
import Can from '../UI-helper-components/Can'
import { actions } from '../../config/roles'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { setRedirectRoute } from '../../actions/portalModeActions';
import Select from 'react-select';

class UserList extends Component {
  constructor(props) {
    super(props)
    console.log(this.props)
  }
  state = {
    showForm: null
  }

  handleSubmit = (singleCase, selectedOption) => {
    this.props.handleFitzpatrickValueChange(singleCase, selectedOption);
    this.setState(() => {
      return {
        showForm: false
      }
    })
  };

  handleToggleForm = (data) => {
    this.setState(() => {
      return {
        showForm: data
      }
    })
  };

  getLastSeverityBadge = (severityValue, lastSeverityCheckLabel) => {
    if (!severityValue && severityValue !== 0) {
      return null
    }
    return (
      <IGABatch
        value={severityValue}
      />
    )
  }

  deleteInvite = (userEmail) => {
    this.props.removePendingUser(userEmail)
    deleteInvite(this.props.token, userEmail)
  }

  parseDOB = (item) => {
    const DOB = item.userDemographics ? item.userDemographics.DOB : 'N/A'
    return DOB && DOB !== 'N/A' ? moment(DOB, 'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY') : DOB
  }

  handleLink = (newRoute) => {
    const { location } = this.props;
    const routeData = {
      redirectRoute: location.pathname,
      currentRoute: newRoute
    }
    this.props.dispatcher(setRedirectRoute(routeData));
  }

  render() {
    let coverLoaderClass = ''
    if (this.props.isCoverLoaderOn) {
      coverLoaderClass = 'cover-loader'
    }
    const FitzpatrickValues = [
      { value: 0, label: 'Type I - palest' },
      { value: 1, label: 'Type II - light colored' },
      { value: 2, label: 'Type III - golden honey or olive' },
      { value: 3, label: 'Type IV - moderate brown' },
      { value: 4, label: 'Type V - dark brown' },
      { value: 5, label: 'Type VI - darkest brown' },
    ];
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        width: '180px', // Adjust the width as needed
      }),
      menu: (provided) => ({
        ...provided,
        maxHeight: '300px', // Set the maximum height for the dropdown menu
        overflowY: 'auto', // Add scroll if the content exceeds maxHeight
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: '300px', // Ensure the menuList also respects the max height
        overflowY: 'auto', // Add scroll if the content exceeds maxHeight
      }),
    };
    return (
      <div className='users-list-v2 table-responsive'>
        <Table className='users-list-table-v2'>
          <UserListHeading userRole={this.props.state.authReducer.userRole} isAdmin={this.props.isAdmin} />
          <tbody className={`${coverLoaderClass}`}>
            {this.props.userList.map((item, index) => {
              const isPending = item.type === 'Pending-invitation'
              let viewDetailsLink = config.baseRoute + 'user/' + item.id
              if (isPending) {
                viewDetailsLink = config.baseRoute + 'unconnected-user/' + item.email
              }
              return <tr className='content-list-group-item' key={item.id}>
                <td className='align-middle'>{isPending ? 'Pending' : utcToLocal(item.date, true)} {isPending && <Can
                  action={actions.cancel_invite}
                  yes={() => (
                    <div style={{ color: '#0d3665', cursor: 'pointer' }} onClick={() => { this.deleteInvite(item.email) }}>cancel invite</div>
                  )}
                />}</td>
                <td className='align-middle text-center'>{item.userDemographics && item.userDemographics.name}</td>
                <td className='align-middle' style={{ textTransform: 'initial' }}>{item.email}</td>
                <td className='align-middle text-center'>{this.parseDOB(item)}</td>
                <td className='align-middle text-center'>{getProp(item, 'userHistoryActivities.numberOfDaysOpened', '')}</td>
                <td className="align-middle text-left">
                  {(item.is_activated && checkFitzpatrickShow(this.props.state.authReducer.userRole, ['STUDY_USER', 'STUDY'])) && <>
                    {item.FitzpartickData ? <div>
                      {this.state.showForm?.id === item.id ? (
                        <Select
                          onChange={(selectedOption) => { this.handleSubmit(item, selectedOption); }}
                          options={FitzpatrickValues}
                          isClearable
                          placeholder="Select Skin Type"
                          styles={customStyles}
                          value={item.FitzpartickData ? item.FitzpartickData : null}
                          menuPortalTarget={document.body} // Render menu in the portal
                          menuPosition={'fixed'} // Ensure the menu position is fixed
                        />
                      ) : (
                        <div className="" onClick={() => this.handleToggleForm(item)}>
                          {item.FitzpartickData.label}
                        </div>
                      )}</div> : <Select
                      onChange={(selectedOption) => { this.handleSubmit(item, selectedOption); }}
                      options={FitzpatrickValues}
                      isClearable
                      placeholder="Select Skin Type"
                      styles={customStyles}
                      value={item.FitzpartickData ? item.FitzpartickData : null}
                      menuPortalTarget={document.body} // Render menu in the portal
                      menuPosition={'fixed'} // Ensure the menu position is fixed
                    />}

                  </>}
                </td>
                <Can
                  action={actions.view_user_list_country}
                  yes={() => (
                    <td className='align-middle'>{item.userLocation && item.userLocation.country}</td>
                  )}
                />
                <td className='align-middle text-center'>{item.casesCount}</td>
                <td className='align-middle text-center'>{item.careCount}</td>
                <td className='align-middle text-center'>{item.triggersCount}</td>
                <td className='align-middle text-center'>
                  <Link onClick={() => this.handleLink(viewDetailsLink)} to={viewDetailsLink} className='view-details-link'> View Details </Link>
                </td>
              </tr>
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  state
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher: (action) => {
      dispatch(action);
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserList))