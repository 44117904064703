import { getUnreadMessage, getUnreadMessageCount } from '../Api/Messaging/messaging'
import roles, { actions, check } from '../config/roles'

export const setUserEmail = (email) => ({
  type: 'SET_USER_EMAIL',
  payload: email
})

export const setDoctorName = (name) => ({
  type: 'SET_DOCTOR_NAME',
  payload: name
})

export const setUnreadMessageCount = (count) => ({
  type: 'SET_UNREAD_MESSAGE_COUNT',
  payload: count
})

export const setUnreadMessages = (messages) => ({
  type: 'SET_UNREAD_MESSAGES',
  payload: messages
})

export const openImageLightbox = (imageUrl) => ({
  type: 'OPEN_IMAGE_LIGHTBOX',
  payload: imageUrl
})

export const closeImageLightbox = () => ({
  type: 'CLOSE_IMAGE_LIGHTBOX'
})

export const setIsMessageListLoading = (isItLoading) => ({
  type: 'SET_IS_MESSAGE_LIST_LOADING',
  payload: isItLoading
})

export const setShadowId = (shadowId) => ({
  type: 'SET_SHADOW_ID',
  payload: shadowId
})

export const setCurrentSessionUserId = (userId) => ({
  type: 'SET_CURRENT_SESSION_USER_ID',
  payload: userId
})

export const setConsultationNote = ({
  consultationId,
  text
}) => ({
  type: 'SET_CONSULTATION_NOTE',
  payload: {
    consultationId,
    text
  }
})

export const setConsultationPrescription = ({
  consultationId,
  text
}) => ({
  type: 'SET_CONSULTATION_PRESCRIPTION',
  payload: {
    consultationId,
    text
  }
})

export const removeConsultationNote = ({
  consultationId
}) => ({
  type: 'REMOVE_CONSULTATION_NOTE',
  payload: {
    consultationId
  }
})

export const removeConsultationPrescription = ({
  consultationId
}) => ({
  type: 'REMOVE_CONSULTATION_PRESCRIPTION',
  payload: {
    consultationId
  }
})

export const fetchUnreadMessages = (showLoader) => {
  return async (dispatch, getState) => {
    const { authReducer } = getState()
    if (!check(authReducer.userRole, actions.view_unread_messages)) {
      return
    }
    const { token } = authReducer
    try {
      showLoader && dispatch(setIsMessageListLoading(true))
      const result = await getUnreadMessageCount({ token })
      const unreadMessages = result.data.data
      dispatch(setUnreadMessages(unreadMessages))
      showLoader && dispatch(setIsMessageListLoading(false))
    } catch (error) {
      showLoader && dispatch(setIsMessageListLoading(false))
      console.log(error)
    }
  }
}
