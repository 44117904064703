import React from 'react'
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

const ChatLogsModal = (props) => {
    return (
        <div>
            <Modal isOpen={props.isModalOn} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}>Timed-out alert</ModalHeader>
                <ModalBody>
                    You are being timed-out due to inactivity. You will be logged off automatically in 5 seconds.
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ChatLogsModal
