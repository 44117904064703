import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import queryString from 'query-string'

const getUrlKeyVals = () => {
    return queryString.parseUrl(window.location.href)
}

const getCurrTab = () => {
    return getUrlKeyVals().query.currTab
}


const Tab = (props) => {
   // props headings
  // props components
  // history

  let [activeTab, setActiveTab] = useState(Number(getCurrTab()) || 0)

  const setCurrTab = (newActiveTab) => {
      const urlParams = {
          currTab: getCurrTab()
      }
      const urlParamStr = queryString.stringify({
          currTab: newActiveTab
      })
      props.history.push(`${window.location.pathname}?${urlParamStr}`)
  }

  const switchToTab = tabNumber => {
      if(activeTab !== tabNumber) {
        setActiveTab(tabNumber)
        setCurrTab(tabNumber)
      } 
  }

  const renderTab = (heading, index, isActive) => {
      if(props.renderTab) {
          return props.renderTab(heading, index, isActive)
      } else {
          return heading
      }
  }

  const getTabFuncContent = (componentFunc, index) => {
      return componentFunc()
  }

  const getTabContent = () => {
      if(typeof props.components[0] === 'function') {
        return (
        <div className='tab-content-wrapper' activeTab={activeTab}>
            {props.components.map((componentFunc, index) => {
                const helperClassName = index === activeTab? 'activeTabContent': ''
                return (
                    <div className={'tab-content-inner ' + helperClassName} key={index} tabId={index}>
                            {activeTab === index? getTabFuncContent(componentFunc, index): null}
                    </div>        
                    )
                })}
        </div>
        )
      } else {
        return (
            <div className='tab-content-wrapper' activeTab={activeTab}>
                {props.components.map((component, index) => {
                    const helperClassName = index === activeTab? 'activeTabContent': ''
                    return (
                        <div className={'tab-content-inner ' + helperClassName} key={index} tabId={index}>
                            {component}
                        </div>
                    )
                    })}
            </div>
        )
      }
  }

  activeTab = Number(getCurrTab() || 0)

  return (
    <div {...props}>
      <div className='tab-heading-wrapper' {...props.navProps}>
        {props.headings.map((heading, index) => {
            if (heading === 'Single Case') return null
            return (
                <div
                    className={classnames({
                        active: activeTab === index,
                        tabItem: 'tabItem'
                    })}
                    onClick={() => { switchToTab(index)}}
                    key={index}
                >
                    {renderTab(heading, index, activeTab === index)}
                </div>
            )
        })}
      </div>
      {getTabContent()}
    </div>
  );
}


Tab.propTypes = {
    
}

Tab.defaultProps = {
    headings: [],
    components: []
}

export default Tab
