import React from 'react'

const SmallCard = (props) => {
  return (
    <div className={`small-card ${props.className}`}>
      {props.children}
    </div>
  )
}

export default SmallCard
