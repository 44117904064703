import axios from './axios'
import endpoints from '../config/endpoints'

export default async (patientId, data, token) => {
  const body = {
    token,
    data
  }

  const headers = {
     patientId 
  }

  try {
    const response = await axios.post(endpoints.UPDATE_FITZPATRICK_BY_ID, body, { headers })
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}