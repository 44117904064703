import endpoints from '../../config/endpoints'
import { makeCall } from '../apiFactory'

export const getConsultations = ({
  token
}) => {
  return makeCall({
    url: endpoints.CONSULTATIONS,
    method: 'get',
    headers: {
      token
    }
  })
}

export const getConsultation = ({
  token,
  consultationId
}) => {
  return makeCall({
    url: endpoints.CONSULTATION,
    method: 'get',
    headers: {
      token,
      consultationId
    }
  })
}

export const getConsultationSummary = ({
  token,
  consultationId
}) => {
  return makeCall({
    url: endpoints.CONSULTATION_SUMMARY,
    method: 'get',
    headers: {
      token,
      consultationId
    }
  })
}

export const getUsrConsultations = ({
  token,
  userid
}) => {
  return makeCall({
    url: endpoints.CONSULTATION_BY_USER,
    method: 'get',
    headers: {
      token,
      userid
    }
  })
}

export const completeConsultation = ({
  token,
  consultationId,
  doctorNote,
  prescription
}) => {
  return makeCall({
    url: endpoints.COMPLETE_CONSULTATION,
    method: 'post',
    body: {
      token,
      consultationId,
      doctorNote,
      prescription
    }
  })
}
