import React from "react";
// import "./style.css";
import ImageComponent from "./Image.component";
import { Carousel } from "@trendyol-js/react-carousel";

const ThoroughResultComponent = ({ token, asiCaseId, imagePaths, caseType, handleImageClicked }) => {

    const defaultBodyParts = [{ bodyPart: 'head' }, { bodyPart: 'leg' }, { bodyPart: 'trunk' }, { bodyPart: 'hand' }];

    const frontImagePaths = imagePaths.length > 0 && imagePaths.filter(function (option) {
        return option.location === 'front';
    });

    const backImagePaths = imagePaths.length > 0 && imagePaths.filter(function (option) {
        return option.location === 'back';
    });

    return (
        <div>
            <div className="res-block">
                <h6>Front Images</h6>
                <div className="show-res through">
                    <Carousel className={"react-carousel"} show={4} slide={1} swiping={true} infinite={false}>
                        {
                            defaultBodyParts.map(function (paths, index) {
                                const resultPath = frontImagePaths.length > 0 && frontImagePaths.filter(function (option) {
                                    return option.bodyPart === paths.bodyPart;
                                })
                                const finalPath = resultPath && resultPath.length > 0 ? resultPath[0] : paths
                                return (
                                    <ImageComponent
                                        token={token}
                                        asiCaseId={asiCaseId}
                                        imagePath={finalPath && finalPath.processedImgPath}
                                        caseType={caseType}
                                        bodyPart={finalPath && finalPath.bodyPart}
                                        individualSeverityScores={finalPath && finalPath.individual_severity_scores}
                                        handleImageClicked={handleImageClicked}
                                    />
                                )
                            })
                        }
                    </Carousel>
                </div>
            </div>

            <div className="res-block">
                <h6>Back Images</h6>
                <div className="show-res through">
                    <Carousel className={"react-carousel"} show={4} slide={1} swiping={true} infinite={false}>
                        {
                            defaultBodyParts.map(function (paths, index) {
                                const resultPath = backImagePaths.length > 0 && backImagePaths.filter(function (option) {
                                    return option.bodyPart === paths.bodyPart;
                                })
                                const finalPath = resultPath && resultPath.length > 0 ? resultPath[0] : paths
                                return (
                                    <ImageComponent
                                        token={token}
                                        asiCaseId={asiCaseId}
                                        imagePath={finalPath && finalPath.processedImgPath}
                                        caseType={caseType}
                                        bodyPart={finalPath && finalPath.bodyPart}
                                        individualSeverityScores={finalPath && finalPath.individual_severity_scores}
                                        handleImageClicked={handleImageClicked}
                                    />
                                )
                            })
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default ThoroughResultComponent