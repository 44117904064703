import axios from './axios'
import endpoints from '../config/endpoints'

export default async (token, userEmail) => {
    const headers = {
        headers: {
            token,
            userEmail
        }
    }

    try {
        const response = await axios.delete(endpoints.INVITE_PATIENT, headers)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
}
