import React from 'react'
import ImageLightbox from './Image-lightbox'

const Root = () => {
    return (
        <div>
            <ImageLightbox />
        </div>
    )
}

export default Root
