import React from 'react'

const EffectivenessChartTabs = (props) => {
    return (
        <div>
            <select onChange={(e) => {
              props.handleButtonClick(e.target.value)
            }} className="chart-dd" value={props.activeCaseFilterId}>
                {props.btnList.map(btn => (
                    <option key={btn.id} value={btn.id}>{btn.title}</option>
                ))}
            </select>
        </div>
    )
}

export default EffectivenessChartTabs
