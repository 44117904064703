import React from 'react'

const LoaderButton = ({ buttonText = 'Refresh', isLoading = false, clickHandler, isSubmit=false }) => {
  if(isSubmit) {
    return (
      <div className='loader-button-wrapper'>
        <input value={buttonText} type='submit' style={{
          marginLeft: 0,
          marginRight: '10px'
        }} disabled={!!isLoading} className='btn btn-primary btn-lg' />
        {isLoading && <div className='la-ball-clip-rotate'>
          <div />
        </div>}
      </div>
    )
  } else { 
    return (
      <div className='loader-button-wrapper'>
        {isLoading && <div className='la-ball-clip-rotate'>
          <div />
        </div>}
        <button onClick={clickHandler} disabled={!!isLoading} className='btn btn-primary'>
          {buttonText}
        </button>
      </div>
    )
  }
}

export default LoaderButton
