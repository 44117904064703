import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';


const Tab = (props) => {
  // props headings
  // props components
  console.log('default tab is ', props.defaultTab)
  const [activeTab, setActiveTab] = useState(props.defaultTab || 0);

  const switchToTab = tabNumber => {
      if(activeTab !== tabNumber) setActiveTab(tabNumber)
  }

  const renderTab = (heading, index, isActive) => {
      if(props.renderTab) {
          return props.renderTab(heading, index, isActive)
      } else {
          return heading
      }
  }

  const getTabFuncContent = (componentFunc, index) => {
      return componentFunc()
  }

  const getTabContent = () => {
      if(typeof props.components[0] === 'function') {
        return (
        <TabContent activeTab={activeTab}>
            {props.components.map((componentFunc, index) => {
                return (
                <TabPane key={index} tabId={index}>
                    <Row>
                        <Col sm="12">
                            {activeTab === index? getTabFuncContent(componentFunc, index): null}
                        </Col>
                    </Row>
                </TabPane>
                )
                })}
        </TabContent>
        )
      } else {
        return (
            <TabContent activeTab={activeTab}>
                {props.components.map((component, index) => {
                    return (
                    <TabPane key={index} tabId={index}>
                        <Row>
                            <Col sm="12">
                                {component}
                            </Col>
                        </Row>
                    </TabPane>
                    )
                    })}
            </TabContent>
        )
      }
  }

  return (
    <div {...props}>
      <Nav {...props.navProps} tabs>
        {props.headings.map((heading, index) => {
            return (
                <NavItem key={index}>
                    <NavLink
                        className={classnames({
                            active: activeTab === index,
                            [props.navLinkClass]: props.navLinkClass
                        })}
                        onClick={() => { switchToTab(index)}}
                    >
                        {renderTab(heading, index, activeTab === index)}
                    </NavLink>
                </NavItem>
            )
        })}
      </Nav>
      {getTabContent()}
    </div>
  );
}


Tab.propTypes = {
    
}

Tab.defaultProps = {
    headings: [],
    components: []
}

export default Tab
