import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import config from '../../config/config'
import { actions } from '../../config/roles'
import Can from '../UI-helper-components/Can'

window['isProfileDDActive'] = false

const isPointerEventInsideElement = function (event: MouseEvent, element: HTMLElement) {
  // @ts-ignore
  return element.contains(event.target)
}


const ProfileDropDown = (props) => {

  const [isMenuDown, setIsMenuDown] = useState(false)

  const documentClickHandlerRef = useRef((e) => {
    const profileDDElem = document.getElementById('profile-dropdown-elem')
    const profileDD = document.getElementById('profile-dropdown')
    const isInsideTargetDiv = isPointerEventInsideElement(e, profileDDElem)
    
    if (!isInsideTargetDiv && window['isMenuDown'] && profileDD) {
        profileDD.classList.remove('active')
        if (setIsMenuDown) {
          setIsMenuDown(false)
        }
        window['isMenuDown'] = false
    }
  })

  useEffect(() => {
    document.addEventListener('click', documentClickHandlerRef.current)
    return () => {
      document.removeEventListener('click', documentClickHandlerRef.current)
    }
  }, [])

  const toggleProfileDD = (e): void => {
    console.log('toggle click handler executed')
    e.stopPropagation()
    let profileDD = document.getElementById('profile-dropdown')
    if (isMenuDown) {
      // remove it
      profileDD.classList.remove('active')
      setIsMenuDown(false)
      window['isMenuDown'] = false
    } else {
      profileDD.classList.add('active')
      setIsMenuDown(true)
      window['isMenuDown'] = true
    }
  }

  const faAngleName = isMenuDown? 'fa-angle-up': 'fa-angle-down'
  console.log('is menu down ? ', isMenuDown)
  return (
    <div className='profile-dropdown' id='profile-dropdown-elem' onClick={toggleProfileDD}>
      <div
      style={{
        cursor: 'pointer'
      }}>{props.name} <i className={`fa ${faAngleName} name-dd-icon`} aria-hidden="true"></i></div>
      <div id='profile-dropdown' className="dropdown-menu dropdown-menu-right show name-dd-ul" aria-labelledby="bd-versions">
        <Can
          action={actions.create_patient}
          yes={() => (
            <NavLink className='dropdown-item' to={config.baseRoute + 'invite-patient'}>Invite Patient</NavLink>
          )}
        />
        <a className="dropdown-item" href="/">Log out</a>
      </div>
    </div>
  )
}

export default ProfileDropDown
