import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import getAsiCasesByUserDetails from '../../Api/get-asi-cases-by-user-details';
import scale0 from "../../assets/easi/scale0.svg";
import scale1 from "../../assets/easi/scale1.svg";
import scale2 from "../../assets/easi/scale2.svg";
import scale3 from "../../assets/easi/scale3.svg";
import scale4 from "../../assets/easi/scale4.svg";
import scale5 from "../../assets/easi/scale5.svg";
import ImageModal from '../Easi-Cases/Image-modal';
import ThoroughResultComponent from '../Easi-Cases/Thorough-result.component';
import DoctorEasi from '../Easi-Cases/DoctorEasi';
import updateAsiCasesByUser from '../../Api/update-asi-cases-by-user';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import getUser from '../../Api/get-user';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const getColorBySeverityValue = (value) => {
  if (value === 0) {
    return '#54F21C'
  } else if (value === 1) {
    return '#C7F21C'
  } else if (value === 2) {
    return '#F2D01C'
  } else if (value === 3) {
    return '#FFA133'
  } else if (value === 4) {
    return '#FF553E'
  } else if (value === 5) {
    return '#E92C4E'
  }
}

const AsiCasesData = ({
  token,
}) => {
  const location = useLocation();
  const [selectedScore, setSelectedScore] = useState({
    headNeck: {
      redness: null,
      scratching: null,
      thickness: null,
      lichenification: null,
      percentAffected: null
    },
    trunk: {
      redness: null,
      scratching: null,
      thickness: null,
      lichenification: null,
      percentAffected: null
    },
    upperLimbs: {
      redness: null,
      scratching: null,
      thickness: null,
      lichenification: null,
      percentAffected: null
    },
    lowerLimbs: {
      redness: null,
      scratching: null,
      thickness: null,
      lichenification: null,
      percentAffected: null
    }
  });
  const [loading, setLoading] = useState(true)
  const [age, setAge] = useState('')
  const [doctorEasiScore, setDoctorEasiScore] = useState('')
  const [data, setData] = useState({});
  const [individualSeverityScores, setIndividualSeverityScores] = useState({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [caseType, setCaseType] = useState('');
  const [imagePaths, setImagePaths] = useState([]);
  const [evaluatedImages, setEvaluatedImages] = useState([]);
  const query = useQuery();
  const asiCaseId = query.get('asiCaseId');
  const pathSegments = location.pathname.split('/');
  const patientId = pathSegments[2];
  useEffect(() => {
    getUser(patientId, token).then((user) => {
      if (user?.data?.data?.length > 0) {
        setAge(user?.data?.data[0]?.userDemographics?.DOB)
        getAsiCasesByUserDetails(asiCaseId, token)
          .then(res => {
            setImagePaths(res.data.data[0].images)
            setEvaluatedImages(res.data.data[0].imagesEvaluation)
            setCaseType(res.data.data[0].caseType)
            setData(res.data.data[0]?.finalResult)
            if (res.data.data[0]?.doctorEASIData) {
              const { doctorEASIDataCreator, ...filteredData } = res.data.data[0]?.doctorEASIData;
              setDoctorEasiScore(calculateEASI(filteredData, user?.data?.data[0]?.userDemographics?.DOB))
              setSelectedScore(filteredData)
            }
            setLoading(false)
          })
      }
    })

  }, [asiCaseId, token, patientId])
  function calculateEASI(doctorEASIData, dob) {
    const regions = ["headNeck", "trunk", "upperLimbs", "lowerLimbs"];
    const multipliers = {
      headNeck: { default: 0.1, child: 0.2 },
      trunk: { default: 0.3 },
      upperLimbs: { default: 0.2 },
      lowerLimbs: { default: 0.4, child: 0.3 }
    };

    // Calculate age
    const currentDate = new Date();
    const birthDate = new Date(dob);
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const isChild = age < 7;

    let totalEASI = 0;

    regions.forEach(region => {
      const data = doctorEASIData[region];
      if (data) {
        const redness = data.redness ? data.redness.value : 0;
        const scratching = data.scratching ? data.scratching.value : 0;
        const thickness = data.thickness ? data.thickness.value : 0;
        const lichenification = data.lichenification ? data.lichenification.value : 0;
        const percentAffected = data.percentAffected ? data.percentAffected.value : 0;

        const severityScore = redness + scratching + thickness + lichenification;
        const multiplier = isChild && multipliers[region].child ? multipliers[region].child : multipliers[region].default;

        const regionScore = severityScore * percentAffected * multiplier;
        totalEASI += regionScore;
      }
    });

    return totalEASI.toFixed(1);
  }

  const getScaleBySeverityValue = (value) => {
    if (value === 0) {
      return scale0
    } else if (value === 1) {
      return scale1
    } else if (value === 2) {
      return scale2
    } else if (value === 3) {
      return scale3
    } else if (value === 4) {
      return scale4
    } else if (value === 5) {
      return scale5
    }
  }

  const getClassBySeverityValue = (severity) => {
    if (severity === 0) {
      return 'clear pill'
    } else if (severity === 1) {
      return 'almost_Clear pill'
    } else if (severity === 2) {
      return 'mild pill'
    } else if (severity === 3) {
      return 'severe pill'
    } else if (severity === 4) {
      return 'moderate pill'
    } else if (severity === 5) {
      return 'very_severe pill'
    }
  }

  if (loading) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  const handleImageClicked = (e, imgSrc, individualSeverityScores) => {
    console.log(imgSrc)
    e.preventDefault();
    setImgSrc(imgSrc)
    setIndividualSeverityScores(individualSeverityScores)
    setShowImageModal(true);
  }

  const hideImageModal = () => {
    setShowImageModal(false);
  };

  const handleSelect = (score, label) => {
    const keys = label.split('.');
    const updatedScore = { ...selectedScore };
    let current = updatedScore;

    for (let i = 0; i < keys.length - 1; i++) {
      current = current[keys[i]];
    }
    if (current[keys[keys.length - 1]] === score.value) {
      current[keys[keys.length - 1]] = null;
    } else {
      current[keys[keys.length - 1]] = score.value;
    }

    updateAsiCasesByUser(asiCaseId, updatedScore, token)
      .then(res => {
        if (res.status === 200) {
          setDoctorEasiScore(calculateEASI(updatedScore, age))
          setSelectedScore(updatedScore);
        } else {
          alert('Error. Please retry')
        }
      })
      .catch(err => {
        alert('Error. Please retry')
      })

  };

  const scores = [
    { label: 'None, absent', unit: 0, value: { value: 0, label: 'Absent' } },
    { label: 'Mild (just perceptible)', value: { value: 1, label: 'Mild' } },
    { label: 'Moderate (obvious)', value: { value: 2, label: 'Moderate' } },
    { label: 'Severe', value: { value: 3, label: 'Severe' } },
  ];

  const percentages = [
    { label: '0% (no active eczema in region)', unit: 0, value: { value: 0, label: 'no region' } },
    { label: '1-9%', value: { value: 1, label: '1% to 9%' } },
    { label: '10-29%', value: { value: 2, label: '10% to 29%' } },
    { label: '30-49%', value: { value: 3, label: '30% to 49%' } },
    { label: '50-69%', value: { value: 4, label: '50% to 69%' } },
    { label: '70-89%', value: { value: 5, label: '70% to 89%' } },
    { label: '90-100% (entire region affected by eczema)', value: { value: 6, label: '90% to 100%' } },
  ];
  return (
    <div style={{
      marginTop: '27px'
    }}
    >
      {data ? <>
        <div>
          <DoctorEasi scores={scores} selectedScore={selectedScore} handleSelect={handleSelect} percentages={percentages}  ></DoctorEasi>
        </div>
        {doctorEasiScore && <div style={{ fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, monospace', textAlign: 'center', background: '#35467D', color: 'white', margin: '0px 380px', borderRadius: '10px', padding: '5px 0px' }}>
          <div className='mt-3'>
            <p>EASI Score is <b style={{ fontSize: '1.2em' }}>{doctorEasiScore}</b> out of <b style={{ fontSize: '1.2em' }}>72</b></p>
          </div>
        </div>}
        <br />
        <div className="select-result">
          <div className="result-item">
            <div className="scale-res">
              <div className='cent-block'>
                <div className='result'><span style={{
                  color: getColorBySeverityValue(data.severity?.value)
                }}>{data.severity?.value}</span>Out of 5
                </div>
                <h5>Eczema Score</h5></div>
              <img src={getScaleBySeverityValue(data.severity?.value)} alt="" />
              <p>Eczema Severity Score is
                <span className={getClassBySeverityValue(data.severity?.value)}>{data.severity?.text}</span>
              </p>
            </div>
          </div>
          <table className="thorough-table">
            <thead>
              <tr>
                <th className='span2'>Region</th>
                <th className='span1'>Severity
                  Score
                </th>
                <th className='span1'>Region
                  Score
                </th>
              </tr>
            </thead>
            <tr>
              <td>Head/Neck</td>
              <td>{data.asi?.headneck?.severityScore}</td>
              <td>{parseFloat(data.asi?.headneck?.regionScore).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Trunk</td>
              <td>{data.asi?.trunk?.severityScore}</td>
              <td>{parseFloat(data.asi?.trunk?.regionScore).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Upper Limbs</td>
              <td>{data.asi?.upperLimbs?.severityScore}</td>
              <td>{parseFloat(data.asi?.upperLimbs?.regionScore).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Lower Limbs</td>
              <td>{data.asi?.lowerLimbs?.severityScore}</td>
              <td>{parseFloat(data.asi?.lowerLimbs?.regionScore).toFixed(2)}</td>
            </tr>
            <tr className="total">
              <td>Total</td>
              <td></td>
              <td>{data?.totalScore.toFixed(2)} / {data?.maxScore}</td>
            </tr>
          </table>
        </div>
        <br />
        <h4>Uploaded Picture</h4>
        {!loading && imagePaths && imagePaths.length > 0 && caseType === 'fast' &&
          <ThoroughResultComponent
            token={token}
            asiCaseId={asiCaseId}
            imagePaths={evaluatedImages}
            caseType={caseType}
            handleImageClicked={handleImageClicked}
          />
        }
        {!loading && imagePaths && imagePaths.length > 0 && caseType === 'thorough' &&
          <ThoroughResultComponent
            token={token}
            asiCaseId={asiCaseId}
            imagePaths={evaluatedImages}
            caseType={caseType}
            handleImageClicked={handleImageClicked}
          />
        }
        <Modal isOpen={showImageModal} toggle={hideImageModal}>
          <ModalHeader toggle={hideImageModal}></ModalHeader>
          <ModalBody>
            <ImageModal
              show={showImageModal}
              imagePath={imgSrc}
              token={token}
              individualSeverityScores={individualSeverityScores}
            >
            </ImageModal>
          </ModalBody>
        </Modal>
      </> : <>
        <div className='text-center text-danger'>
          <p>No data found</p>
        </div>
      </>
      }
    </div>
  )
}

export default AsiCasesData