import moment from "moment";
import React, { useEffect, useState } from "react";
import getCaseDetailsById from "../../Api/get-case-details-by-id";
import loadingGif from "../../assets/loading.gif";
import endpoints from "../../config/endpoints";
import imageIdGenerator from "../../utilityFunctions/image-id-generator";
import IGABatch from "../Common/IGA-batch";
import ItchBadge from "../Common/Itch-batch";
import ReactAuthImage from "../UI-helper-components/react-auth-image";
import getProp from "../User/User-details-components/Utils/get";

const getItchBadge = (obj) => {
  return <ItchBadge {...obj} />;
};

const ConsultationCard = (props) => {
  const [caseDetail, setCaseDetail] = useState(null);
  useEffect(() => {
    getCaseDetailsById(props.caseId, props.token).then((response) => {
      setCaseDetail(response.data.data);
    });
  }, []);
  if (!caseDetail) {
    return null;
  }
  const imagePathArr = caseDetail.imagePath || [];
  const lastImage = imagePathArr[imagePathArr.length - 1];
  return (
    <div className="single-consultation" key={props.id}>
      <h5 className="consultation-date">{moment(props.date).format("ll")}</h5>
      <div
        className="consultation-inner"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.history.push(
            `${window.location.pathname}?currTab=6&consultationId=${props.id}`
          );
        }}
      >
        <div className="case-details">
          <div className="case-image">
            <div className="case-image-inner">
              <ReactAuthImage
                className="case-list-image"
                targetUrl={endpoints.GET_IMAGE}
                headers={{
                  imageId:
                    lastImage &&
                    lastImage.path &&
                    imageIdGenerator(lastImage.path),
                  token: props.token,
                  caseId: caseDetail.id,
                }}
                defaultImage={loadingGif}
              />
            </div>
          </div>
          <div className="case-info">
            <div>
              <strong>Severity</strong>:{" "}
              <IGABatch value={caseDetail.finalReplyJson.value} />
            </div>
            <div>
              <strong>Itch</strong>:{" "}
              {getItchBadge(getProp(caseDetail, "discomfort.itch", {}))}
            </div>
          </div>
        </div>
        <div className="consultation-info">
          <div>
            <strong>Status</strong>:{" "}
            {props.isComplete ? "Complete" : "Incomplete"}
          </div>
          <div>
            <strong>Suggested Care Routine</strong>:{" "}
            <span style={{ fontSize: "0.9em" }}>
              {props.careActivitiesTrackedAfterSnapshot &&
                props.careActivitiesTrackedAfterSnapshot
                  .map((activity) => activity.careActivityLabel)
                  .join(", ")}
            </span>
          </div>
          <div>
            <strong>Notes</strong>: {props.doctorNote}
          </div>
          <div>
            <strong>Prescription</strong>: {props.prescription}
          </div>
          {props.isComplete && (
            <div>
              <strong>Completed at</strong>:{" "}
              {moment(props.completedAt).format("ll")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsultationCard;