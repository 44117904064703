import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Chart from "react-apexcharts";
import RangeFilterTabs from './Range-filter-tabs';
import { getCaseHistory, getWeatherHistory } from '../../../Api/History-and-insights/history-and-insights-apis';
import { getStartAndEndFromRange } from './utils';
import DropDown from './DropDown'
import moment from 'moment';

const caseBtnList = [
    {
        id: 0,
        title: 'Severity',
        isDefault: true,
    },
    {
        id: 1,
        title: 'Itch',
        isDefault: false,
    },
    {
        id: 4,
        title: 'Quality of Life',
        isDefault: false,
    },
]

const weatherBtnList = [
    {
        id: 'airQuality',
        title: 'Air Auality'
    },
    {
        id: 'pollen',
        title: 'Pollen'
    },
    {
        id: 'uvIndex',
        title: 'UV Index'
    },
    {
        id: 'meanTemperature',
        title: 'Mean Temp'
    },
    {
        id: 'dewPoint',
        title: 'Dew Point'
    },
]
class EczemaVsWeather extends Component <any, any> {

    state = {
        activeRangeFilter: 2,
        activeCaseFilterId: 0,
        weatherDataType: 'pollen',
        series: [
            {
                name: "Eczema",
                data: [{}]
            },
            {
                name: "Weather",
                data: [{x: '01-01-2020', y: 0}]
            }
        ],
        options: {
            chart: {
                height: 350,
                width: 500,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                show: true,
                type: "datetime",
                min: moment(getStartAndEndFromRange(2).start).toDate().getTime(),
                max: moment(getStartAndEndFromRange(2).end).toDate().getTime(),
                labels: {
                    show: true
                },
                tickPlacement: 'on'
            },
            yaxis: {
                show: false,
                min: 0,
                max: 4,
                tickAmount: 4,
                labels: {
                    show: true,
                    formatter: (val) => {
                        return val.toFixed(0)
                    },
                    style: {
                        fontSize: '14px'
                    }
                },
            },
            legend: {
                show: true,
            },
            tooltip: {
                enabled: false
            }
        }
    }

    componentDidMount () {
        this.props.subscribeToRangeChange(this.RangeFilterClickHandler)
        this.loadWeatherData(this.state.activeRangeFilter, this.state.activeCaseFilterId, this.state.weatherDataType)
    }

    CaseFilterClickHandler = async (caseFilterId) => {
        this.setState(() => {
            return {
                activeCaseFilterId: caseFilterId,
            }
        })
        await this.loadWeatherData(this.state.activeRangeFilter, caseFilterId, this.state.weatherDataType)
    }

    RangeFilterClickHandler = async (activeRange) => {
        this.setState(() => {
            return {
                activeRangeFilter: activeRange,
                options: {
                    ...this.state.options,
                    xaxis: {
                        ...this.state.options.xaxis,
                        min: moment(getStartAndEndFromRange(activeRange).start).toDate().getTime(),
                        max: moment(getStartAndEndFromRange(activeRange).end).toDate().getTime(),
                    }
                }
            }
        })
        await this.loadWeatherData(activeRange, this.state.activeCaseFilterId, this.state.weatherDataType)
        return true
    }

    weatherTypeClickHandler = (weatherDataType) => {
        this.setState(() => {
            return {
                weatherDataType: weatherDataType,
            }
        })
        this.loadWeatherData(this.state.activeRangeFilter, this.state.activeCaseFilterId, weatherDataType)
    }

    caseHistoryToSeries = (caseHistories = []) => {
        return caseHistories.map(history => {
            return {
                y: history.value,
                x: `${history.month}-${history.day}-${history.year} GMT`
            }
        }).sort((a, b) => {
            a['x'] = a.x.split('-').join('/')
            b['x'] = b.x.split('-').join('/')
            let aTime = moment(a.x.replace(' GMT', '')).valueOf()
            let bTime = moment(b.x.replace(' GMT', '')).valueOf()
            return bTime - aTime
        })
    }

    weatherHistoryToSeries = (weatherHistory = []) => {
        return weatherHistory.map(weather => {
            let y
            let label = this.state.weatherDataType
            if (label === 'pollen' || label === 'airQuality' || label === 'uvIndex') {
                y = weather[label]
            } else {
                y = weather[label] / 30
            }
            return {
                y: y,
                x: `${weather.month}-${weather.day}-${weather.year} GMT`
            }
        }).sort((a, b) => {
            a['x'] = a.x.split('-').join('/')
            b['x'] = b.x.split('-').join('/')
            let aTime = moment(a.x.replace(' GMT', '')).valueOf()
            let bTime = moment(b.x.replace(' GMT', '')).valueOf()
            return bTime - aTime
        })
    }

    loadWeatherData = async (rangeId, caseAxis, weatherDataType) => {
        const range = getStartAndEndFromRange(rangeId)
        const caseHistory = await getCaseHistory(this.props.userid, this.props.token, range.start, range.end, caseAxis)
        this.setState(() => ({
            series: [{
                name: 'Eczema',
                data: this.caseHistoryToSeries(caseHistory.data.caseHistory)
            }, this.state.series[1]],
            loadingComplete: true,
            weatherDataType: weatherDataType
        }))
        const weatherHistory = await getWeatherHistory(this.props.userid, this.props.token, range.start, range.end)
        this.setState(() => ({
            series: [this.state.series[0], {
                name: 'Weather',
                data: this.weatherHistoryToSeries(weatherHistory.data.weatherHistory)
            }]
        }))
    }


    render() {
        return (
            <div id="chart">
                <div className="chart-header">
                    <div className="chart-title-holder">
                        Eczema vs Weather
                    </div>
                    {/* <RangeFilterTabs
                        btnList={this.props.filterBtnList}
                        activeButtonId={this.state.activeRangeFilter}
                        handleButtonClick={this.RangeFilterClickHandler}
                    /> */}
                </div>
                <div className="compare-holder">
                    <div>Compare </div>
                    <DropDown
                        btnList={caseBtnList}
                        activeCaseFilterId={this.state.activeCaseFilterId}
                        handleButtonClick={this.CaseFilterClickHandler}
                    />
                    <div> VS </div>
                    <DropDown
                        btnList={weatherBtnList}
                        activeCaseFilterId={this.state.weatherDataType}
                        handleButtonClick={this.weatherTypeClickHandler}
                    />
                </div>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    width="550"
                />
            </div>
        )
    }
}

export default withRouter(EczemaVsWeather)
