import React, {Component} from 'react'
import {connect} from 'react-redux'
import updateDoctorAssistant from '../../Api/put-doctor-assistant'
import LoaderButton from '../UI-helper-components/Loader-button'
import {extractErrorMessage} from '../../utilityFunctions/common'
import assistantByIdApi from "../../Api/get-doctor-assistant-by-id";
import {withRouter} from "react-router-dom";

const successMessage = (email) => {
    return <div className='success-message-invite'>Assistant updated successfully with email <strong>{email}</strong>.
    </div>
}

const errorMessage = (email, apiErrorMessage) => {
    return <div className='error-message-invite'>
        <div>{apiErrorMessage}</div>
        <div>
            Error updating <strong>{email}</strong>. Please contact us at support@eczemaless.com for assistance
            regarding
            this issue.
        </div>
    </div>
}

const responseTypes = {
    'SUCCESS': 'SUCCESS',
    'ERROR': 'ERROR'
}

class UpdateAssistant extends Component {

    state = {
        isSpinnerOn: false,
        didGetResponse: false,
        lastSubmittedEmail: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        password: '',
        apiErrorMessage: ''
    }

    componentDidMount() {
        this.toggleSpinner()
        this.getDoctorAssistantById()
    }

    getDoctorAssistantById() {
        assistantByIdApi(this.props.state.authReducer.token, this.props.match.params.assistantId)
            .then(res => {
                let data = res.data && res.data.data
                this.toggleSpinner()
                this.setState(() => {
                    const {firstName, lastName, email, phoneNumber, address} = data[0]
                    return {
                        firstName: firstName,
                        lastName: lastName,
                        phoneNumber: phoneNumber,
                        address: address,
                        email: email
                    }
                })
            })
            .catch(err => {
                console.log(err)
                this.toggleSpinner()
                alert('Error. Please retry')
            })
    }

    viewMessage = () => {
        if (!this.state.didGetResponse) {
            return null
        }
        if (this.state.responseType === responseTypes.SUCCESS) {
            return successMessage(this.state.lastSubmittedEmail)
        } else {
            return errorMessage(this.state.lastSubmittedEmail, this.state.apiErrorMessage)
        }
    }

    toggleSpinner = () => {
        this.setState(({isSpinnerOn}) => {
            return {
                isSpinnerOn: !isSpinnerOn
            }
        })
    }

    onFirstNameChangeHandler = (e) => {
        const firstName = e.target.value
        this.setState(() => ({
            firstName
        }))
    }

    onLastNameChangeHandler = (e) => {
        const lastName = e.target.value
        this.setState(() => ({
            lastName
        }))
    }

    onPhoneNumberChangeHandler = (e) => {
        const phoneNumber = e.target.value
        this.setState(() => ({
            phoneNumber
        }))
    }

    onAddressChangeHandler = (e) => {
        const address = e.target.value
        this.setState(() => ({
            address
        }))
    }

    onPasswordChangeHandler = (e) => {
        const password = e.target.value
        this.setState(() => ({
            password
        }))
    }

    onSubmitHandler = async (e) => {
        e.preventDefault()
        try {
            this.toggleSpinner()
            this.setState(() => ({
                didGetResponse: false,
                lastSubmittedEmail: this.state.email,
                apiErrorMessage: ''
            }))
            const body = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                address: this.state.address,
                password: this.state.password,
                email: this.state.email
            }
            await updateDoctorAssistant(this.props.state.authReducer.token, body)
            this.setState(() => ({
                didGetResponse: true,
                responseType: responseTypes.SUCCESS
            }))
        } catch (error) {
            console.log(error)
            this.setState(() => ({
                didGetResponse: true,
                responseType: responseTypes.ERROR,
                apiErrorMessage: extractErrorMessage(error)
            }))
        } finally {
            this.toggleSpinner()
        }
    }

    render() {
        return (
            <div className='container'>
                <p>Enter updated assistant information below</p>
                <form onSubmit={this.onSubmitHandler}>
                    <div className="form-group">
                        <input onChange={this.onFirstNameChangeHandler} type="text" className="form-control"
                               id="firstName" aria-describedby="firstName" placeholder="Enter First Name"
                               value={this.state.firstName}/>
                    </div>
                    <div className="form-group">
                        <input onChange={this.onLastNameChangeHandler} type="text" className="form-control"
                               id="lastName" aria-describedby="lastName" placeholder="Enter Last Name"
                               value={this.state.lastName}/>
                    </div>
                    <div className="form-group">
                        <input onChange={this.onPhoneNumberChangeHandler} type="text" className="form-control"
                               id="phoneNumber" aria-describedby="phoneNumber" placeholder="Enter Phone Number"
                               value={this.state.phoneNumber}/>
                    </div>
                    <div className="form-group">
                        <input onChange={this.onAddressChangeHandler} type="text" className="form-control" id="address"
                               aria-describedby="address" placeholder="Enter Address" value={this.state.address}/>
                    </div>
                    <div className="form-group">
                        <input onChange={this.onPasswordChangeHandler} type="text" className="form-control"
                               id="password" aria-describedby="password" placeholder="Enter Password"
                               value={this.state.password}/>
                    </div>
                    <div style={{
                        display: 'inline-block'
                    }}>
                        <LoaderButton
                            clickHandler={this.onSubmitHandler}
                            buttonText='Update'
                            isLoading={this.state.isSpinnerOn}
                            isSubmit={true}
                        />
                    </div>
                </form>
                {this.viewMessage()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps)(withRouter(UpdateAssistant))
