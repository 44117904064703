import axios from './axios'
import endpoints from '../config/endpoints'

export default async (token, skip, searchTerm, queryTerm) => {

  const body = {
    token,
    skip,
    searchTerm: searchTerm || '',
    queryTerm: queryTerm || ''
  }

  try {
    const response = await axios.post(endpoints.CARE_ACTIVITIES, body)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}
