import React from 'react'
import { IconProps } from './interfaces'
import { ReactComponent as MessageIcon } from '../../assets/v2/svg-icons/mdi_message.svg'
import { ReactComponent as Patients } from '../../assets/v2/svg-icons/patients.svg'
import { ReactComponent as CarePresets } from '../../assets/v2/svg-icons/care_presets.svg'
import { ReactComponent as Settings } from '../../assets/v2/svg-icons/settings.svg'
import { ReactComponent as NotificationUnread } from '../../assets/v2/svg-icons/notification_unread.svg'
import { ReactComponent as Dashboard } from '../../assets/v2/svg-icons/mdi_dashboard.svg'
import { ReactComponent as DoctorAssistant } from '../../assets/v2/svg-icons/doctor_assistant.svg'
import { ReactComponent as SevereIcon } from '../../assets/v2/svg-icons/severe.svg'
import { ReactComponent as ScoreOne } from '../../assets/v2/svg-icons/score_01.svg'
import { ReactComponent as ScoreTwo } from '../../assets/v2/svg-icons/score_02.svg'
import { ReactComponent as ScoreThree } from '../../assets/v2/svg-icons/score_03.svg'
import { ReactComponent as ScoreFour } from '../../assets/v2/svg-icons/score_04.svg'
import { ReactComponent as ScoreFive } from '../../assets/v2/svg-icons/score_05.svg'
import { ReactComponent as ArrowDropDownCircle } from '../../assets/v2/svg-icons/arrow_drop_down_circle.svg'

const IconTable = {
  'messageIcon': <MessageIcon />,
  'patients': <Patients />,
  'carePresets': <CarePresets />,
  'settings': <Settings />,
  'notificationUnread': <NotificationUnread />,
  'dashboardIcon': <Dashboard />,
  'doctorAssistant': <DoctorAssistant />,
  'severeIcon': <SevereIcon />,
  'scoreOne': <ScoreOne />,
  'scoreTwo': <ScoreTwo />,
  'scoreThree': <ScoreThree />,
  'scoreFour': <ScoreFour />,
  'scoreFive': <ScoreFive />,
  'arrowDropDownCircle': <ArrowDropDownCircle />,
}

const Icon = (props: IconProps) => {
  let { name, style, ...rest } = props
  return (
    <span {...rest } className={`${props.className} custom-svg-icon`}>
      {IconTable[props.name]}
    </span>
  )
}

export default Icon
