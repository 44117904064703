import axios from "axios";
import { Component } from "react";
import { connect } from "react-redux";
import { addToken } from "../actions/authActions";
import config from "../config/config";

//This component is responsible for intercepting Axios requests
class AxiosInterceptor extends Component {
  componentDidMount() {
    if (this.props.portalMode === config.ECZEMALESS_APP) {
      axios.defaults.baseURL = config.ECZEMALESS_API_BASE_URL;
      this.props.dispatcher(addToken(this.props.tokenEczemaless));
    } else {
      axios.defaults.baseURL = config.PSORIASIS_API_BASE_URL;
      this.props.dispatcher(addToken(this.props.tokenPsoriasis));
    }

    this.axiosInterceptor = axios.interceptors.request.use(
      (config) => {
        const { portalMode } = this.props;
        if (portalMode) {
          config.headers = {
            ...config.headers,
            "Portal-Mode": portalMode,
          };
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  portalMode: state.portalModeReducer.portalMode,
  token: state.authReducer.token,
  tokenPsoriasis: state.authReducer.tokenPsoriasis,
  tokenEczemaless: state.authReducer.tokenEczemaless,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AxiosInterceptor);