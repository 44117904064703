import React from 'react'
import { ItchBadgeProps } from './interfaces'

const ItchBadge = (props: ItchBadgeProps) => {
  if (props.value === undefined) {
    return null
  }
  return (
    <div className={`iga-badge iga-${props.value}`}>
      {props.label}  
    </div>
  )
}

export default ItchBadge
