import React from 'react'
import config from '../../config/config'
import {VerticalMenuProps} from './interfaces'
import {NavLink} from 'react-router-dom'
import Icon from '../Common/Icon'
import Can from '../UI-helper-components/Can'
import { actions } from '../../config/roles'

const VerticalMenu = (props: VerticalMenuProps) => {
  return (
    <div className='vertical-menu'>
      <div className="vertical-menu-inner">
        <ul className='vertical-menu-items'>
          <Can
            action={actions.view_stats}
            yes={() => (
              <React.Fragment>
                <NavLink className='tibot-menu-item-v2' to={config.baseRoute + `dashboard`}><Icon name='dashboardIcon' /> Dashboard</NavLink>
                <NavLink className='tibot-menu-item-v2' to={config.baseRoute + 'cases/page/1'}><Icon name='dashboardIcon' /> Cases</NavLink>
                <NavLink className='tibot-menu-item-v2' to={config.baseRoute + 'activities/page/1'}><Icon name='dashboardIcon' /> Care Activities</NavLink>
                <NavLink className='tibot-menu-item-v2' to={config.baseRoute + 'triggers/page/1'}><Icon name='dashboardIcon' /> Triggers</NavLink>
              </React.Fragment>
            )}
          />
          <Can
            action={actions.view_unread_messages}
            yes={() => (
              <NavLink className='tibot-menu-item-v2' to={config.baseRoute + 'messages'}><Icon name='messageIcon' /> Inbox {!!props.inboxCount && <span style={{ marginLeft: '6px'}} className='vertical-menu-inbox-count'>{props.inboxCount}</span>}</NavLink>
            )}
          />
          <Can
            action={actions.view_consultations}
            yes={() => (
              <NavLink className='tibot-menu-item-v2' to={config.baseRoute + 'consultations'}><Icon name='dashboardIcon' /> Consultations</NavLink>
            )}
          />
          <Can
            action={actions.message_presets}
            yes={() => (
              <NavLink className='tibot-menu-item-v2' to={config.baseRoute + 'message-presets'}><Icon name='dashboardIcon' /> Message Presets</NavLink>
            )}
          />
          <NavLink className='tibot-menu-item-v2' to={config.baseRoute + 'users/page/1'}><Icon name='patients' /> Patients</NavLink>
          <Can
            action={actions.view_doctor_presets}
            yes={() => (
              <NavLink className='tibot-menu-item-v2' to={config.baseRoute + `set-preset/${props.shadowId}`}><Icon
              name='carePresets'/> Set Presets</NavLink>
            )}
          />
          <Can
            action={actions.view_doctor_assistants}
            yes={() => (
              <NavLink className='tibot-menu-item-v2' to={config.baseRoute + 'assistants/page/1'}><Icon
                  name='doctorAssistant'/> Assistants</NavLink>
            )}
          />
          <a className='tibot-menu-item-v2 temp-hide' href='#'><Icon name='settings'/> Settings</a>
        </ul>
      </div>
    </div>
  )
}

export default VerticalMenu
