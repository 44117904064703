import endpoints from '../../config/endpoints'
import { makeCall } from '../apiFactory'

export const getTriggerCategories = ({
  token
}) => {
  return makeCall({
    url: endpoints.TRIGGER_CATEGORIES,
    method: 'get',
    headers: {
      token
    }
  })
}

export const getTriggerListByUser = ({
  token,
  userid,
  triggerCategoryId
}) => {
  return makeCall({
    url: endpoints.TRIGGER_LIST_BY_USER,
    method: 'get',
    headers: {
      token,
      triggerCategoryId,
      userid
    }
  })
}

export const getTriggerListByUserUnconnected = ({
  token,
  triggerCategoryId,
  userEmail
}) => {
  return makeCall({
    url: endpoints.TRIGGER_LIST_BY_USER_UNCONNECTED,
    method: 'get',
    headers: {
      token,
      triggerCategoryId,
      userEmail
    }
  })
}

export const getTriggerTrackedByUser = ({
  token,
  userid
}) => {
  return makeCall({
    url: endpoints.TRIGGER_TRACKED_BY_USER,
    method: 'get',
    headers: {
      token,
      userid
    }
  })
}

export const getTriggerTrackedByUserUnconnected = ({
  token,
  userEmail
}) => {
  return makeCall({
    url: endpoints.TRIGGER_TRACKED_BY_USER_UNCONNECTED,
    method: 'get',
    headers: {
      token,
      userEmail
    }
  })
}

export const postTriggerTracked = ({
  userid,
  triggerId,
  triggerCategoryId,
  token
}) => {
  return makeCall({
    url: endpoints.TRIGGER_TRACKED_BY_USER,
    method: 'post',
    body: {
      userid,
      triggerId,
      triggerCategoryId,
      token
    }
  })
}

export const postTriggerTrackedUnconnected = ({
  userEmail,
  triggerId,
  triggerCategoryId,
  token
}) => {
  return makeCall({
    url: endpoints.TRIGGER_TRACKED_BY_USER_UNCONNECTED,
    method: 'post',
    body: {
      token,
      triggerId,
      triggerCategoryId,
      userEmail
    }
  })
}

export const deleteTriggerTracked = ({
  token,
  userid,
  triggerId,
  triggerCategoryId
}) => {
  return makeCall({
    url: endpoints.TRIGGER_TRACKED_BY_USER,
    method: 'delete',
    headers: {
      token,
      userid,
      triggerId,
      triggerCategoryId
    }
  })
}

export const deleteTriggerTrackedUnconnected = ({
  token,
  userEmail,
  triggerId,
  triggerCategoryId
}) => {
  return makeCall({
    url: endpoints.TRIGGER_TRACKED_BY_USER_UNCONNECTED,
    method: 'delete',
    headers: {
      token,
      userEmail,
      triggerId,
      triggerCategoryId
    }
  })
}

export const postTriggerList = ({
  userid,
  triggerCategoryId,
  triggerLabel,
  token
}) => {
  return makeCall({
    url: endpoints.TRIGGER_LIST_BY_USER,
    method: 'post',
    body: {
      userid,
      triggerCategoryId,
      triggerLabel,
      token
    }
  })
}

export const postTriggerListUnconnected = ({
  userEmail,
  triggerCategoryId,
  triggerLabel,
  token
}) => {
  return makeCall({
    url: endpoints.TRIGGER_LIST_BY_USER_UNCONNECTED,
    method: 'post',
    body: {
      userEmail,
      triggerCategoryId,
      triggerLabel,
      token
    }
  })
}

