import React from 'react'
import ActivitiesSelector from './ActivitiesSelector'
import CurrentCareRoutine from './CurrentCareRoutine'

interface SuggestedCareRoutineProps {
  token: string,
  userId: string,
  disabled: boolean,
  showSnapshot: boolean,
  snapshotActivities: Array<any>
}

const noop = () => {}

const SuggestedCareRoutine = (props: SuggestedCareRoutineProps) => {
  let refetchSelectorData: Function = noop
  let refetchCareRoutineData: Function = noop
  return (
    <div className='suggested-care-routine'>
      <ActivitiesSelector
        setupDataFetcher={(selectorDataFetcher) => {
          refetchSelectorData = selectorDataFetcher
        }}
        disabled={props.disabled}
        token={props.token}
        userId={props.userId}
        afterSelect={() => {
          refetchSelectorData()
          refetchCareRoutineData()
        }}
      />
      {props.disabled?  
        (<CurrentCareRoutine
            token={props.token}
            userId={props.userId}
            showSnapshot={props.showSnapshot}
            snapshotActivities={props.snapshotActivities}
          />)
        : (
            <CurrentCareRoutine
              setupDataFetcher={(careRoutineDataFetcher) => {
                refetchCareRoutineData = careRoutineDataFetcher
              }}
              token={props.token}
              userId={props.userId}
              showRemoveButtons
              showSnapshot={props.disabled}
              afterRemove={() => {
                refetchSelectorData()
                refetchCareRoutineData()
              }}
            />
        )
      }
    </div>
  )
}

export default SuggestedCareRoutine
