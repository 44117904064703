import React, { Component } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosInterceptor from "./Api/axiosInterceptor";
import Main from "./Components/Main";
import Root from "./Components/Root-component/root";
import configureStore from "./store/configureStore";
import config from "./config/config";

const store = configureStore();
// dev only settings
if (process.env.NODE_ENV === "development") {
  if (localStorage.getItem("dev_auth_token")) {
    const tokenPsoriasis = localStorage.getItem("dev_psoriasis_auth_token");
    const tokenEczemaless = localStorage.getItem("dev_eczemaless_auth_token");
    const userRole = localStorage.getItem("dev_user_role");
    const email = localStorage.getItem("email");
    const portalMode = localStorage.getItem("portalMode")
      ? localStorage.getItem("portalMode")
      : config.ECZEMALESS_APP;
    const isAdmin = localStorage.getItem("dev_is_admin") === "true";
    if (portalMode === config.ECZEMALESS_APP) {
      store.dispatch({
        type: "ADD_TOKEN",
        payload: tokenEczemaless,
      });
    } else {
      store.dispatch({
        type: "ADD_TOKEN",
        payload: tokenPsoriasis,
      });
    }
    store.dispatch({
      type: "ADD_TOKEN_PSORIASIS",
      payload: tokenPsoriasis,
    });
    store.dispatch({
      type: "ADD_TOKEN_ECZEMALESS",
      payload: tokenEczemaless,
    });
    store.dispatch({
      type: "SET_IS_AUTHENTICATED",
      payload: true,
    });
    store.dispatch({
      type: "SET_IS_ADMIN",
      payload: isAdmin,
    });
    store.dispatch({
      type: "SET_USER_ROLE",
      payload: userRole,
    });
    store.dispatch({
      type: "SET_USER_EMAIL",
      payload: email,
    });
    store.dispatch({
      type: "SET_PORTAL_MODE",
      payload: portalMode,
    });
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AxiosInterceptor />
        <Main />
        <Root />
        <ToastContainer />
      </Provider>
    );
  }
}

export default App;