import React from 'react';

const DoctorEasi = ({ scores, selectedScore, handleSelect, percentages }) => {
    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <div className='doctorEasiHeader'>
                        Head & Neck
                    </div>
                    <div className='doctorEasiBodySection '>
                        <div className='mx-2'>
                            <div className='row mb-3 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Redness</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.headNeck.redness?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'headNeck.redness')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Thickness</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.headNeck.thickness?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'headNeck.thickness')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Scratching</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.headNeck.scratching?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'headNeck.scratching')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Lichenification</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.headNeck.lichenification?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'headNeck.lichenification')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row my-2'>
                                <div className='col-6'>
                                    <label className='p-3'>Percent Affected</label>
                                </div>
                                <div className='col-6 mt-2'>
                                    <div className="score-selector">
                                        {percentages.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.headNeck.percentAffected?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'headNeck.percentAffected')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='doctorEasiHeader'>
                        Trunk
                    </div>
                    <div className='doctorEasiBodySection '>
                        <div className='mx-2'>
                            <div className='row mb-3 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Redness</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.trunk.redness?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'trunk.redness')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Thickness</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.trunk.thickness?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'trunk.thickness')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Scratching</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.trunk.scratching?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'trunk.scratching')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Lichenification</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.trunk.lichenification?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'trunk.lichenification')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                            <div className='row my-2'>
                                <div className='col-6'>
                                    <label className='p-3'>Percent Affected</label>
                                </div>
                                <div className='col-6 mt-2'>
                                    <div className="score-selector">
                                        {percentages.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.trunk.percentAffected?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'trunk.percentAffected')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6 mt-3'>
                    <div className='doctorEasiHeader'>
                        Upper Limbs
                    </div>
                    <div className='doctorEasiBodySection '>
                        <div className='mx-2'>
                            <div className='row mb-3 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Redness</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.upperLimbs.redness?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'upperLimbs.redness')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Thickness</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.upperLimbs.thickness?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'upperLimbs.thickness')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Scratching</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.upperLimbs.scratching?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'upperLimbs.scratching')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Lichenification</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.upperLimbs.lichenification?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'upperLimbs.lichenification')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row my-2'>
                                <div className='col-6'>
                                    <label className='p-3'>Percent Affected</label>
                                </div>
                                <div className='col-6 mt-2'>
                                    <div className="score-selector">
                                        {percentages.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.upperLimbs.percentAffected?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'upperLimbs.percentAffected')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6 mt-3'>
                    <div className='doctorEasiHeader'>
                        Lower Limbs
                    </div>
                    <div className='doctorEasiBodySection '>
                        <div className='mx-2'>
                            <div className='row mb-3 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Redness</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.lowerLimbs.redness?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'lowerLimbs.redness')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Thickness</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.lowerLimbs.thickness?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'lowerLimbs.thickness')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Scratching</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.lowerLimbs.scratching?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'lowerLimbs.scratching')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 doctorEasiOptionSection'>
                                <div className='col-6'>
                                    <label className='p-3'>Lichenification</label>
                                </div>
                                <div className='col-6 my-2'>
                                    <div className="score-selector">
                                        {scores.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.lowerLimbs.lichenification?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'lowerLimbs.lichenification')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='row my-2'>
                                <div className='col-6'>
                                    <label className='p-3'>Percent Affected</label>
                                </div>
                                <div className='col-6 mt-2'>
                                    <div className="score-selector">
                                        {percentages.map((score) => (
                                            <div
                                                key={score.value.value}
                                                className={` ${selectedScore.lowerLimbs.percentAffected?.value === score.value?.value ? 'score-item-selected' : 'score-item'}`}
                                                onClick={() => handleSelect(score, 'lowerLimbs.percentAffected')}
                                            >
                                                {score.label} <span className="score-value">{score.value.value > 0 && `+`}{score.value.value}</span>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
};

export default DoctorEasi;