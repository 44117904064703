import React from 'react'
import {MessageNoteProps} from './interfaces'

const MessageNote = (props: MessageNoteProps) => {
    return (
        <div className='message-note'>
            <div className='message-note__icon'>
                <div className="message-note_icon-inner">
                    {props.type === 'notification' && <i className="fa fa-flag-o" aria-hidden="true"></i>}
                    {props.type === 'message' && <i className="fa fa-sticky-note-o" aria-hidden="true"></i>}
                </div>
            </div>
            <div className='message-note__content'>
                <pre className="message-note__message">
                    {props.message}
                </pre>
                <div className="message-note__title">
                    By {props.doctorName} @{props.time}
                </div>
            </div>
        </div>
    )
}

export default MessageNote
