import React, { Fragment } from 'react'
import { ListGroupItem } from 'reactstrap'

const ActivitiesListHeader = (props) => {
  let queryClass = ''
  if(props.fromQueryList) {
    queryClass = ' query-list'
  }
  return (
    <Fragment>{props.caseList.length > 0 && <thead>
        
        <tr className='case-list__row case-list__row--heading'>
          <td className='case-date'>Date</td>
          <td className='case-user'>Patient</td>
          <td className='activity-label'>Label</td>
          <td className='activity-category'>Category</td>
          <td className='activity-is-on'>isOn?</td>
      </tr>
    </thead>}
    </Fragment>
  )
}

export default ActivitiesListHeader
