import React, { useMemo, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { ListGroupItem } from 'reactstrap'
import TrackButtonAtom from './Track-button-atom'
import Select from './Select-creatable'
import getProp from '../User/User-details-components/Utils/get'
import { unconnectedAwareQuery } from './utils'
import { actions, check } from '../../config/roles'

// careActivitiesTracked
// careActivityId: "86299w294jd8zsfbp"
// careActivityLabel: "Methotrexate"
// careCategoryId: "70691w2s2jdd6yv9p"
// createDate: "2020-04-22T09:15:33.740Z"
// isActive: true
// startedBy: "DOCTOR"

const useCallback = (func) => {
  return func
}

const createOption = (label) => ({
  label: label,
  value: label
})

function frequencyReducer (state, action) {
  switch (action.type) {
    case 'value':
      return {
        ...state,
        value: action.payload
      }
    case 'options':
      return {
        ...state,
        options: action.payload
      }
    case 'isLoading':
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}

function quantityReducer (state, action) {
  switch (action.type) {
    case 'value':
      return {
        ...state,
        value: action.payload
      }
    case 'options':
      return {
        ...state,
        options: action.payload
      }
    case 'isLoading':
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}

const ActivityItem = (props) => {
  
  const getInitialVal = (field) => {
    const itemInTracked = props.careActivitiesTracked.filter(activity => activity.careActivityId === props.careActivityId)[0]
    if (itemInTracked) {
      const initVal = itemInTracked[field] || undefined
      return initVal
    } else {
      return undefined
    }
  }

  const getInitialOptions = (type) => {
    const trackedMeta = getInitialVal(type)
    if (!trackedMeta) {
      return props[type] ? props[type].map(createOption) : []
    } else {
      if (!Array.isArray(props[type])) {
        return [trackedMeta].map(createOption)
      }
      if (!props[type].includes(trackedMeta)) {
        return [...props[type], trackedMeta].map(createOption)
      } else {
        return props[type] ? props[type].map(createOption) : []
      }
    }
  }

  const initFVal = getInitialVal('frequency')
  const initQVal = getInitialVal('quantity')
  const [fState, fDispatch] = useReducer(frequencyReducer, {
    options: getInitialOptions('frequency'),
    value: initFVal ? createOption(initFVal) : undefined,
    isLoading: false
  })

  const [qState, qDispatch] = useReducer(quantityReducer, {
    options: getInitialOptions('quantity'),
    value: initQVal ? createOption(initQVal) : undefined,
    isLoading: false
  })

  const isChecked = useMemo(() => {
    return props.careActivitiesTracked.some(activity => (activity.careActivityId === props.careActivityId) && activity.isActive)
  }, [props.careActivityId, props.careActivitiesTracked])

  const isCheckedByDoctor = useMemo(() => {
    return isChecked && props.suggestedIdsByDoctor.includes(props.careActivityId)
  }, [isChecked, props.careActivityId, props.careActivitiesTracked])

  const trackActivity = () => {
    props.addCareActivityTracked(props.careActivityId, props.careCategoryId, getProp(fState, 'value.value'), getProp(qState, 'value.value'), props.isDoctorOnly, props.careActivityLabel)
  }

  const unTrackActivity = () => {
    props.deleteCareActivityTracked(props.careActivityId, props.careCategoryId, getProp(fState, 'value.value'), getProp(qState, 'value.value'), props.isDoctorOnly, props.careActivityLabel)
  }

  const handleOnPress = () => {
    if (isDisabled) {
      return
    }
    
    if (isChecked) {
      unTrackActivity()
    } else {
      trackActivity()
    }
  }

  const dispathcerFromType = (type) => {
    return type === 'frequency' ? fDispatch : qDispatch
  }

  const stateFromType = (type) => {
    return type === 'frequency' ? fState : qState
  }

  const handleChange = (newValue, actionMeta, type) => {
    console.group('Value Changed')
    console.log(newValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
    const dispatcher = dispathcerFromType(type)
    dispatcher({
      type: 'value',
      payload: newValue
    })
    uploadChange(type, newValue)
    // this.setState({ value: newValue })
  }

  const handleCreate = (inputValue, type) => {
    const dispatcher = dispathcerFromType(type)
    dispatcher({
      type: 'isLoading',
      payload: true
    })
    // this.setState({ isLoading: true })
    console.group('Option created')
    console.log('Wait a moment...')
    const state = stateFromType(type)
    const { options } = state
    const newOption = createOption(inputValue)
    console.log(newOption)
    console.groupEnd()
    dispatcher({
      type: 'isLoading',
      payload: false
    })
    dispatcher({
      type: 'options',
      payload: [...options, newOption]
    })
    dispatcher({
      type: 'value',
      payload: newOption
    })
    uploadChange(type, newOption)
    // this.setState({
    //   isLoading: false,
    //   options: [...options, newOption],
    //   value: newOption
    // })
  }

  const uploadChange = (type, value) => {
    const obj = {
      frequency: getProp(fState, 'value.value'),
      quantity: getProp(qState, 'value.value'),
      careActivityId: props.careActivityId,
      careActivityLabel: props.careActivityLabel,
      careCategoryId: props.careCategoryId,
      token: props.token,
      startedBy: 'DOCTOR',
      treatmentPlan: props.treatmentPlan,
      userid: props.userId,
      userEmail: props.userEmail
    }
    if (value) {
      obj[type] = value.value
    } else {
      obj[type] = undefined
    }
    const uAwareObj = unconnectedAwareQuery(obj, props.mode, props.userEmail)
    props.updateActivityMeta(uAwareObj)
  }

  const handleFChange = (newValue, actionMeta) => {
    handleChange(newValue, actionMeta, 'frequency')
    trackActivity()
  }

  const handleFCreate = (inputValue) => {
    handleCreate(inputValue, 'frequency')
    trackActivity()
  }

  const handleQChange = (newValue, actionMeta) => {
    handleChange(newValue, actionMeta, 'quantity')
    trackActivity()
  }

  const handleQCreate = (inputValue) => {
    handleCreate(inputValue, 'quantity')
    trackActivity()
  }

  const labelClass = () => {
    return props.type === 'activities'? 'activity-label': 'trigger-label'
  }

  const wrapperClass = () => {
    return props.type === 'activities'? 'activity-wrapper': 'trigger-wrapper'
  }

  const selectWrapperHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    event.nativeEvent.stopPropagation()
  }
  const userRole = useSelector((state) => {
    return state.authReducer.userRole
  })
  const isDisabled = !check(userRole, actions.update_care_activity)

  return (
    <ListGroupItem
      className={`single-activity-wrapper ${wrapperClass()}`}
      style={{ cursor: 'pointer' }}
      onClick={handleOnPress}
    >
      <div className='sub-category-item'>
        <div className={`${labelClass()}`}>{props.careActivityLabel}</div>
        {isCheckedByDoctor && (
          <div className='started-by-doctor-badge'>
          Suggested by doctor <i class='fa fa-user-md' aria-hidden='true' />
          </div>)}
        {props.type === 'activities' && <div className='row-dropdown' onClick={selectWrapperHandler}>
          <Select
            isLoading={fState.isLoading}
            placeholder='Frequency'
            options={fState.options}
            value={fState.value}
            otherValue={qState.value}
            onChange={handleFChange}
            handleCreate={handleFCreate}
          />
        </div>}
        {props.type === 'activities' && <div className='row-dropdown' onClick={selectWrapperHandler}>
          <Select
            isLoading={qState.isLoading}
            placeholder='Quantity'
            options={qState.options}
            value={qState.value}
            otherValue={fState.value}
            onChange={handleQChange}
            handleCreate={handleQCreate}
          />
        </div>}
        <div className='sub-category-item__buttons'>
          <div>
            <TrackButtonAtom
              disabled={isDisabled}
              isTracked={isChecked}
            />
          </div>
        </div>
      </div>
    </ListGroupItem>
  )
}

export default ActivityItem
