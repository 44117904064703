import React from 'react'
import {NotificationNoteProps} from './interfaces'

const MessageNote = (props: NotificationNoteProps) => {
    return (
        <div className='message-note'>
            <div className='message-note__icon'>
                <div className="message-note_icon-inner">
                    {props.type === 'notification' && <i className="fa fa-flag-o" aria-hidden="true"></i>}
                    {props.type === 'message' && <i className="fa fa-sticky-note-o" aria-hidden="true"></i>}
                </div>
            </div>
            <div className='notification-note__content'>
                {props.suggested &&
                <div className="notification-note__message">
                    <label>{'Suggested:'}</label>
                    <span>{props.suggested}</span>
                </div>
                }
                {props.removed &&
                <div className="notification-note__message">
                    <label>{'Removed:'}</label>
                    <span>{props.removed}</span>
                </div>
                }
                {props.appliedPreset &&
                <div className="notification-note__message">
                    <label>{'Applied Preset:'}</label>
                    <span>{props.appliedPreset}</span>
                </div>
                }
                <div className="message-note__title">
                    By {props.doctorName} @{props.time}
                </div>
            </div>
        </div>
    )
}

export default MessageNote
