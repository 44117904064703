import React, { useEffect, useState } from 'react'
import { deleteCareActivitiesTracked, getCareActivitiesTrackedByUser } from '../../Api/Manage-tracking/manage-tracking'

export const CARE_CATEGORIES = {
  '70691w2s2jdd8yo5v': 'Cleaning/Bathing',
  '70691w294jdby9rlv': 'Moisturizer',
  '70691w3x2jef9oxyk': 'Topical Non-Steroids',
  '70691w4e6yef3ygqn': 'Steroids',
  '70691w2s2jdd6yv9p': 'Medicines',
  '70691w2s2jdd9owzk': 'Others'
}

const groupByCategories = (activities = []) => {
  const activeActivities = activities.filter(_ => _.isActive)
  const groupedByCategories = {}
  activeActivities.forEach(activity => {
    if (groupedByCategories[activity.careCategoryId]) {
      groupedByCategories[activity.careCategoryId].push(activity)
    } else {
      groupedByCategories[activity.careCategoryId] = [activity]
    }
  })
  return groupedByCategories
}

const CurrentCareRoutine = ({
  userId,
  token,
  showSnapshot,
  snapshotActivities = [],
  showRemoveButtons = false,
  afterRemove = () => {},
  setupDataFetcher = (func) => {}
}) => {
  const [activities, setActivities] = useState({})
  const loadData = () => {
    if (showSnapshot) return
    getCareActivitiesTrackedByUser({ token: token, userid: userId, treatmentPlan: 0 })
      .then(response => {
        if (Array.isArray(response.data.data)) {
          const groupedByCategories = groupByCategories(response.data.data)
          setActivities(groupedByCategories)
        }
      })
  }
  setupDataFetcher(loadData)
  useEffect(loadData, [])
  const viewableActivities = showSnapshot ? groupByCategories(snapshotActivities) : activities
  return (
    <table className='table'>
      {Object.keys(viewableActivities).map(categoryId => {
        return (
          <tr key={categoryId} className='info-row'>
            <td className='info-row-key'>{CARE_CATEGORIES[categoryId]}</td>
            <td className='info-row-value info-row-value-activities'>
              {viewableActivities[categoryId].map(activity => {
                return (
                  <div className='activity-consultation-pill' key={activity.id}>
                    {activity.careActivityLabel}
                    {showRemoveButtons && (
                      <div
                        onClick={async () => {
                          await deleteCareActivitiesTracked({
                            token,
                            userid: userId,
                            careActivityId: activity.careActivityId,
                            careCategoryId: categoryId,
                            treatmentPlan: 0
                          })
                          afterRemove()
                          loadData()
                        }}
                        className='close-icon-activity'
                      ><svg fill='#c2c2c2' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'><path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z' /></svg>
                      </div>
                    )}
                  </div>
                )
              })}
            </td>
          </tr>
        )
      })}
    </table>
  )
}

export default CurrentCareRoutine
