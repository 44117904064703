import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getConsultations } from '../../Api/Consultations/apis'
import { tokenSelector } from '../../selectors/auth-selectors'
import { useHistory } from 'react-router-dom'
import ConsultationRow from './ConsultationRow'

const ConsultationPage = () => {
  const [consultationsLoaded, setConsultationsLoaded] = useState(false)
  const [consultations, setConsultations] = useState([])
  const token = useSelector(tokenSelector)
  const history = useHistory()
  useEffect(() => {
    getConsultations({ token })
      .then(response => {
        setConsultationsLoaded(true)
        setConsultations(response.data.data)
      })
  }, [])

  const consultationsListBody = () => {
    if (!consultationsLoaded) {
      return (
        <tbody>Loading...</tbody>
      )
    }
    if (consultationsLoaded && consultations.length < 1) {
      return (
        <tbody>No consultations found</tbody>
      )
    }
    return (
      <tbody>
        {consultations.map(consultation => {
          return (
            <ConsultationRow
              history={history}
              key={consultation.id}
              {...consultation}
            />
          )
        })}
      </tbody>
    )
  }

  return (
    <div className='consultations-sections'>
      <h3 class='screen-header__heading'>Consultations</h3>
      <table
        className='table table-bordered table-striped'
        style={{
          backgroundColor: '#fff'
        }}
      >
        <thead>
          <tr>
            <th scope='col'>Created At</th>
            <th scope='col'>Email</th>
            <th scope='col'>Status</th>
            <th scope='col'>Note</th>
            <th scope='col'>prescription</th>
            <th scope='col'>Patient Message</th>
            <th scope='col'>Completed At</th>
            <th />
          </tr>
        </thead>
        {consultationsListBody()}
      </table>
    </div>
  )
}

export default ConsultationPage
