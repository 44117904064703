import React, {Component} from 'react'
import SmallCard from "../Common/Small-card";
import {millisecondsToHuman, renderElapsedString} from "./helpers";
import playButton from "../../assets/play-button.png";
import stopButton from "../../assets/stop-button.png";
import resumeIcon from "../../assets/play-dot.png";
import pauseButton from "../../assets/pause-button.png";

let isRunning
let isPaused

class Timer extends Component {

    componentDidMount() {
        this.forceUpdateInterval = setInterval(() => this.forceUpdate(), 50)
        isRunning = !!this.props.runningSince
        isPaused = this.props.isCurrentSessionPaused
        if(!isRunning && !isPaused) {
            this.props.playTimer()
        }
        else if(isPaused) {
            this.props.resumeTimer()
        }
    }

    componentWillUnmount() {
        clearInterval(this.forceUpdateInterval)
        isRunning = !!this.props.runningSince
        if(isRunning) {
            this.props.pauseTimer()
        }
    }

    render() {

        const elapsedString = renderElapsedString(this.props.elapsed, this.props.runningSince)
        let totalElapsedString = millisecondsToHuman(this.props.totalElapsed)
        isRunning = !!this.props.runningSince
        isPaused = this.props.isCurrentSessionPaused
        const currentDate = new Date()
        const currentMonthName = currentDate.toLocaleString('default', { month: 'short' })
        const year = currentDate.getFullYear()
        totalElapsedString = `${totalElapsedString} (${currentMonthName} ${year})`;

        return (
            <div className="flex-parent flex-parent-row">
                <div>
                    <div className="timers-wrapper">
                        <SmallCard className='card-timer card-timer-total'>
                            <div className="card-timer-label">
                                Total Time
                            </div>
                            <div className="card-timer-header">
                                {totalElapsedString || '00:00:00'}
                            </div>
                        </SmallCard>
                        <SmallCard className='card-timer card-timer-play-pause'>
                            <div className="card-mult-col">
                                <div className='timer-current-session'>
                                    <div className="card-timer-label">
                                        Current session
                                    </div>
                                    <div className="card-timer-header">
                                        {elapsedString || '00:00:00'}
                                    </div>
                                </div>
                                <div className="timer-action-buttons">
                                    <div className="stop-icon-image icon-image" disabled={isRunning || isPaused}>
                                        <img src={playButton} onClick={() => this.props.playTimer()}/>
                                    </div>
                                    <div className="stop-icon-image icon-image" disabled={!isRunning}>
                                        <img src={pauseButton} onClick={() => this.props.pauseTimer()}/>
                                    </div>
                                    <div className="resume-icon-image icon-image" disabled={!isPaused}>
                                        <img src={resumeIcon} onClick={() => this.props.resumeTimer()}/>
                                    </div>
                                    <div className="stop-icon-image icon-image" disabled={!isRunning && !isPaused}>
                                        <img src={stopButton} onClick={() => this.props.stopTimer()}/>
                                    </div>
                                </div>
                            </div>
                        </SmallCard>
                    </div>
                </div>
            </div>
        )
    }
}

export default Timer
