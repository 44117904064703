import config from "./config";

export default {
  REGISTERED_DOCTOR_ECZEMALESS_LOGIN_API: config.ECZEMALESS_API_BASE_URL + "/login",
  REGISTERED_DOCTOR_PSORIASIS_LOGIN_API: config.PSORIASIS_API_BASE_URL + "/login",
  GET_ASI_IMAGE: '/asiImage',  
  PREMIUM_CASE_DETAILS: "/premiumCaseDetails",
  DOCTOR_REPORT: "/doctorReport",
  DISEASE_LIST: "/diseaseList",
  DISEASE_DESCRIPTION: "/diseaseDescription",
  DISEASE_MEDICINE: "/diseaseMedicine",
  OPEN_CASES: "/openCases",
  PENDING_CASES: "/pendingCases",
  CLAIM_CASE: "/claimCase",
  DOCTOR_HISTORY_REPORT: "/doctorHistoryReport",
  REMOVE_CASE_CLAIM: "/removeCaseClaim",
  GET_IMAGE: "/image",
  DOCTOR_PROFILE: "/doctorProfile",
  GET_CHAT: "/doctorChat",
  POST_CHAT: "/doctorChat",
  UPLOAD_IMAGE: "/image",
  COMPLETE_CASES: "/completeCases",
  STATS: "/stats",
  USERS: "/users",
  CASES_BY_USER: "/casesByUser",
  UPDATE_CASE_BY_ID: "/updateCaseById",
  UPDATE_ASI_CASE_BY_ID: "/updateAsiCaseById",
  UPDATE_FITZPATRICK_BY_ID: "/updateUser",
  CARE_ACTIVITY_DETAILS_BY_USER: "/activityDetailsByUser",
  TRIGGER_LOG_DETAILS_BY_USER: "/triggerLogDetailsByUser",
  CASE_DETAILS_BY_ID: "/caseDetailsById",
  USER: "/user",
  CASES: "/cases",
  CARE_ACTIVITIES: "/careActivities",
  TRIGGERS: "/triggers",
  INVITE_PATIENT: "/invitePatient",
  CARE_CATEGORIES: "/careCategories",
  CARE_ACTIVITY_TRACKED_BY_USER: "/careActivitiesTrackedByUser",
  CARE_ACTIVITY_TRACKED_BY_USER_UNCONNECTED:
    "/careActivitiesTrackedByUserUnconnected",
  CARE_ACTIVIIIES_LIST_UNCONNECTED: "/careActivitiesListUnconnected",
  CARE_ACTIVITIES_LIST: "/careActivitiesList",
  CARE_ACTIVIIIES_LIST_ONLY_UNCONNECTED: "/careActivitiesListUnconnected",
  CARE_ACTIVITIES_LIST_UNCONNECTED: "/careActivitiesListByUserUnconnected",
  CARE_ACTIVIIIES_LIST_BY_USER: "/careActivitiesListByUser",
  USER_SUMMARY_REPORT: "/userSummaryReport",
  GET_USER_STATS: "/userStats",
  TRIGGER_CATEGORIES: "/triggerCategories",
  TRIGGER_LIST_BY_USER: "/triggerListByUser",
  TRIGGER_LIST_BY_USER_UNCONNECTED: "/triggerListByUserUnconnected",
  TRIGGER_TRACKED_BY_USER: "/triggerTrackedByUser",
  TRIGGER_TRACKED_BY_USER_UNCONNECTED: "/triggerTrackedByUserUnconnected",
  CONFIRM_DOCTOR_ACCOUNT: "/confirmDoctorAccount",
  UPLOAD_CHAT_IMAGE: "/chatImage",
  MESSAGE_LIST: "/messageList",
  MESSAGE_COUNT: "/messageCount",
  UPDATE_ACTIVITY_META: "/updateActivityMeta",
  UPDATE_ACTIVITY_META_UNCONNECTED: "/updateActivityMetaUnconnected",
  CATEGORY_INSTRUCTION: "/categoryInstruction",
  CATEGORY_INSTRUCTION_UNCONECTED: "/categoryInstructionUnconnected",
  APPLY_PRESET: "/applyPreset",
  APPLY_PRESET_UNCONNECTED: "/applyPresetUnconnected",
  TREATMENT_PLANS: "/preset/treatmentPlan",
  TOGGLE_DOCTOR_CHAT_STATE: "/toggleDoctorChatState",
  DOCTOR_ASSISTANTS: "/doctor/assistants",
  DOCTOR_PATIENT_SESSION: "/doctorPatientSession",
  DOCTOR_NOTE: "/doctorNote",
  ECZEMALESS_CONFIRM_ASSISTANT: config.ECZEMALESS_API_BASE_URL + "/doctor/confirmAssistant", 
  PSORIASIS_CONFIRM_ASSISTANT: config.PSORIASIS_API_BASE_URL + "/doctor/confirmAssistant",  
  REVIEW_USER_DATA: "/reviewUserData",
  CONFIRM_RESET_PASSWORD: "/confirmResetPassword",
  DOWNLOAD_ANON_REPORT: "/anonymisedReport",
  DISCONNECT_PATIENT: "/disconnectPatient",
  CASE_HISTORY: "/caseHistory",
  ASI_CASES_BY_USER: "/asiCasesByUser",
  ASI_CASES_BY_USER_DETAILS: "/asiCasesById",  
  WEATHER_HISTORY: "/weatherHistory",
  CARE_ACTIVITIES_HISTORY: "/careActivitiesHistory",
  TRIGGER_HISTORY: "/triggerHistory",
  INSIHGT_SUMMARY: "/insightsSummary",
  CONSULTATIONS: "/consultations",
  CONSULTATION: "/consultation",
  CONSULTATION_SUMMARY: "/consultationSummary",
  COMPLETE_CONSULTATION: "/completeConsultation",
  CONSULTATION_BY_USER: "/consultationsByUserId",
  DOCTOR_PROFILE_NEW: "/doctor/profile",
  MESSAGE_PRESETS: "/messagePresets",
  CARE_ALL_DATA: "/careAllData",
};