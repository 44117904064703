import axios from './axios'
import endpoints from '../config/endpoints'

export default async (userid, token) => {
  const headers = {
    headers: {
      userid,
      token
    }
  }
  try {
    const response = await axios.get(endpoints.REVIEW_USER_DATA, headers)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}
