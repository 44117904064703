import React, { useEffect, useState } from 'react'
import { tokenSelector } from '../../selectors/auth-selectors'
import { useSelector } from 'react-redux'
import { getAllTreatmentPlan, postApplyPreset } from '../../Api/Manage-tracking/manage-tracking'

interface Option {
  color: string,
  is_active: boolean,
  label: string,
  value: number,
  _id: string
}

type AfterSubmit = () => void

interface ActivitiesPropsSelectorProps {
  afterSubmit: AfterSubmit,
  userid: string,
  disabled: boolean
}

const ActivitiesPresetSelector: React.FC<ActivitiesPropsSelectorProps> = (props) => {
  const [selectedPreset, setSelectedPreset] = useState<string>('')
  const [options, setOptions] = useState<Array<Option> | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const token: string = useSelector(tokenSelector)
  useEffect(() => {
    getAllTreatmentPlan({ token })
      .then(response => {
        setOptions(response.data.data)
      }).catch()
  }, [])
  const applySelctedPreset = async (): Promise<void> => {
    try {
      if (selectedPreset === '') return
      setIsSubmitting(true)
      await postApplyPreset({
        userid: props.userid,
        token,
        treatmentPlan: Number(selectedPreset),
        targetTreatmentPlan: 0
      })
      setIsSubmitting(false)
      props.afterSubmit()
    } catch (error) {
      setIsSubmitting(false)
      console.log(error)
    }
  }
  return (
    <div className='select-template-box'>
      <div className='select-template-text'>Select Template</div>
      {options && <select
        disabled={props.disabled}
        value={selectedPreset}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const presetId = e.target.value
          setSelectedPreset(presetId)
          // const targetPreset = msgPresets.find(preset => preset.id === presetId)
          // setDoctorNote(targetPreset.presetBody)
        }} className='form-control'
      >
        <option disabled value=''>Select a preset</option>
        {options.map(preset => {
          return <option value={preset.value}>{preset.label}</option>
        })}
      </select>}
      {!props.disabled && <div onClick={applySelctedPreset} className='btn btn-primary' style={{ marginLeft: '5px'}}>{isSubmitting? 'Applying...': 'Apply'}</div>}
    </div>
  )
}

export default ActivitiesPresetSelector
