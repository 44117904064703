import React, { Fragment } from 'react'
import { checkIGAValueHide } from './User-details-components/Utils/get'

const CaseListHeader = (props) => {
  let queryClass = ''
  if (props.fromQueryList) {
    queryClass = ' query-list'
  }
  return (
    <Fragment>{props.caseList.length > 0 && <thead>
      <tr>
        <td className='align-middle'>Image</td>
        <td className='align-middle'>Date</td>
        {props.fromQueryList && <td className='align-middle'>Patient</td>}
        <td className='align-middle text-center text-center'>QoL Score</td>
        {checkIGAValueHide(props.state.authReducer.userRole,['STUDY_USER']) && <td className='align-middle text-capitalize text-left'>IGA Value</td>
        }
        <td className='align-middle text-capitalize text-left'>Doctor IGA Value</td>
        <td className='align-middle text-capitalize text-left'>Itch</td>
        <td></td>
      </tr>
    </thead>}
    </Fragment>
  )
}

export default CaseListHeader