import React from 'react'
import { HeaderProfileProps } from './interfaces'
import anonimage from '../../assets/v2/anon.png'
import ProfileDropDown from './Profile-dropdown'

const HeaderProfile = (props: HeaderProfileProps) => {
  return (
    <div className='header-profile-area'>
      <div className='user-photo-section'>
        <img className='user-photo-header' src={anonimage} alt="user photo"/>
      </div>
      <div className='name-dropdown'>
        <ProfileDropDown name={props.name} />
      </div>
    </div>
  )
}

export default HeaderProfile
