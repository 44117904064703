import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import statsApi from '../../Api/get-stats'
import config from '../../config/config'

class Dashboard extends Component {

  state = {
    isSpinnerOn: false,
    userCount: 0,
    casesCount: 0,
    careActivitiesCount: 0,
    triggerLogCount: 0,
    isDataLoaded: false
  }

  componentDidMount () {
    this.toggleSpinner()
    statsApi(this.props.state.authReducer.token)
      .then(res => {
        this.toggleSpinner()
        let data = res.data && res.data.data || {}
        this.setState(() => ({
          userCount: data.userCount,
          careActivitiesCount: data.careActivitiesCount,
          casesCount: data.casesCount,
          triggerLogCount: data.triggerLogCount,
          isDataLoaded: true
        }))
      })
      .catch(err => {
        this.toggleSpinner()
        alert('Error. Please retry')
      })
  }

  toggleSpinner = () => {
    this.setState(({isSpinnerOn}) => {
      return {
        isSpinnerOn: !isSpinnerOn
      }
    })
  }

  viewStats = () => {
    return <div className='stats-wrapper'>
        <div className="row">
          <div className="col-md-6 sm-12 statbox">
            <h4 className='stats-heading'>Total Users</h4>
            <Link className='statbox-count' to={`/users/page/1`}>{this.state.userCount}</Link>
          </div>
          <div className="col-md-6 sm-12 statbox">
          <h4 className='stats-heading'>Total Cases</h4>
            <Link className='statbox-count' to={`/cases/page/1`}>{this.state.casesCount}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 sm-12 statbox">
            <h4 className='stats-heading'>Total Care Activities Logged</h4>
            <Link className='statbox-count' to={`/activities/page/1`}>{this.state.careActivitiesCount}</Link>
          </div>
          <div className="col-md-6 sm-12 statbox">
          <h4 className='stats-heading'>Total Triggers Logged</h4>
            <Link className='statbox-count' to={`/triggers/page/1`}>{this.state.triggerLogCount}</Link>
          </div>
        </div>
    </div>
  }

  render () {
    return (
      <div className='container'>
        {this.state.isSpinnerOn && <div className='square-spinner'></div>}
        {this.state.isDataLoaded && this.viewStats()}
        {/* {this.state.isSpinnerOn && <div className='square-spinner'></div>}
        {this.state.inProgressCases.length > 0 && <div className='doctor-history-row'><h6 className='doctor-history-title'>In progress cases:</h6>
          <ListGroup>
            {
              this.state.inProgressCases.map((singleCase, index) => {
                return <ListGroupItem key={index}>{singleCase._id.year + '-' + singleCase._id.month + '-' + singleCase._id.day} <span className='badge badge-primary'>{singleCase.count}</span></ListGroupItem>
              })
            }
          </ListGroup></div>}
        {this.state.doneCases.length > 0 && <div className='doctor-history-row'><h6  className='doctor-history-title'>Done cases:</h6>
        <ListGroup>
          {
              this.state.doneCases.map((singleCase, index) => {
                return <ListGroupItem key={index}>{singleCase._id.year + '-' + singleCase._id.month + '-' + singleCase._id.day} <span className='badge badge-primary'>{singleCase.count}</span></ListGroupItem>
              })
            }
        </ListGroup></div>} */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state
  }
}

export default connect(mapStateToProps)(Dashboard)
