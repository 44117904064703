import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import isEqual from 'lodash/isEqual'

import getChat from '../../Api/get-chat'
import postChat from '../../Api/post-chat'
import uploadImage from '../../Api/upload-image'
import Renderer from './Renderer'
import InputBox from './Input'
import {fetchUnreadMessages} from '../../actions/uiActions'
import seenChat from '../../Api/seen-chat'
import { repeatAsync } from '../../utilityFunctions/repeatAsync'

class Chat extends Component {

  state = {
    patientChat: [],
    pendingUploads: []
  }

  getUserId = () => {
    return this.props.match.params.userId
  }

  getToken = () => {
    return this.props.state.authReducer.token
  }
  
  getPatientChat = () => {
    return this.state.patientChat.length > 0 ? this.state.patientChat : false
  }
  
  scrollToBottom () {
    console.log('scroll to bottom called')
    if(this.messageEnd) {
      console.log('messagend found')
      this.messageEnd.scrollIntoView({
        behavior: 'auto'
      })
    } else if (document.getElementById('message-end')) {
      console.log('messagend by id found found')
      document.getElementById('message-end').scrollIntoView({
        behavior: 'auto'
      })
    }
  }

  addPendingUpload = (timeStamp) => {
    this.setState(() => ({
      pendingUploads: [...this.state.pendingUploads, timeStamp]
    }))
  }

  removePendingUpload = (timeStamp) => {
    this.setState(() => ({
      pendingUploads: this.state.pendingUploads.filter(item => item !== timeStamp)
    }))
  }

  addTextMessage = (action) => {
    const message = {
      type: 'text',
      content: action.payload,
      origin: 'doctor',
      date: action.date
    }
    this.setState(() => ({
      patientChat: [...this.state.patientChat, message]
    }))
  }

  addImageMessage = (action) => {
    const message = {
      type: 'image',
      content: '',
      origin: 'doctor',
      src: action.payload,
      date: action.date,
      timeStamp: action.timeStamp
    }
    this.setState(() => ({
      patientChat: [...this.state.patientChat, message]
    }))
    this.addPendingUpload(action.timeStamp)
  }

  rootStateChanger = (action) => {
    if(action.type === 'ADD_TEXT_MESSAGE') {
      this.addTextMessage(action)
      postChat(this.getUserId(), 'TibotWebTest', 'text', 'doctor', action.payload, this.getToken())
        .then(res => {
          this.props.dispatcher(fetchUnreadMessages(false))
        })
    }

    if(action.type === 'ADD_IMAGE_MESSAGE') {
      this.addImageMessage(action)
      uploadImage(this.getUserId(), action.uploadPayload, this.getToken())
        .then(res => {
          const fileId = res.data.data
          return postChat(this.getUserId(), 'TibotWebTest', 'image', 'doctor', fileId, this.getToken())
        })
        .then(res => {
          this.props.dispatcher(fetchUnreadMessages(false))
          this.removePendingUpload(action.timeStamp)
        })
        .catch(err => {
          alert('Error. Please retry.')
        })
    }
  }

  componentWillUnmount () {
    if (this.clearLoop) {
      this.clearLoop()
    }
  }

  componentDidMount () {
    this.scrollToBottom()
    getChat(this.getUserId(), this.getToken())
      .then(res => {
        this.setState(() => ({
          patientChat: res.data.data
        }))
      })
      .catch(err => {
        alert('Error. Please retry.')
      })

    seenChat(this.getUserId(), this.getToken())
        .then(res => {
          this.props.dispatcher(fetchUnreadMessages(false))
        })
        .catch(err => {
          alert('Error. Please retry.')
        })
    this.clearLoop = repeatAsync(async () => {
      try {        
        const res = await getChat(this.getUserId(), this.getToken())
        this.setState(() => ({
          patientChat: res.data.data
        }))
        return true
      } catch (error) {
        console.log(error)
      }
    }, 3000)
  }

  componentDidUpdate (prevProps, prevState) {
    if(!isEqual(prevState.patientChat, this.state.patientChat)) {
      this.scrollToBottom()
    }
  }

  render () {
    return (
      <div className='message-wrapper' style={{
        paddingBottom: '20px',
        maxWidth: '640px',
        paddingLeft: '40px'
      }}>
        {/* <UserInfo
          name={getProp(this.props, 'history.location.state.patientName')}
          email={getProp(this.props, 'history.location.state.email')}
        /> */}
        <div className='message-list-wrapper-items'>
          {this.getPatientChat() && 
            <Renderer
              scrollToBottom={this.scrollToBottom}
              pendingUploads={this.state.pendingUploads}
              patientChat={this.getPatientChat()}
              caseId={this.getUserId()}
            />
          }
        </div>
        <InputBox
          scrollToBottom={this.scrollToBottom}
          rootStateChanger={this.rootStateChanger}
        />
        <div onLoad={this.scrollToBottom} id='message-end' style={{float: 'left', clear: 'both'}} ref={(el) => {
          this.messageEnd = el }}></div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatcher : (action) => {
      dispatch(action)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chat))
