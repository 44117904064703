import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import React, { Component } from 'react'
import {DropdownButton, MenuItem} from '@trendmicro/react-dropdown'
import getUserSummaryReport from '../../Api/get-user-summary-report'
import Can from '../UI-helper-components/Can'
import { actions } from '../../config/roles'

class DownloadReport extends Component {

  state = {
    isDownloaded: null
  }

  downloadButtonHandler = (period) => {
    if(this.state.isDownloaded === false) {
      return
    }
    if(this.state.isDownloaded === true) {
      this.setState(() => ({ isDownloaded: null }))
    }
    this.setState(() => ({ isDownloaded: false }))
    getUserSummaryReport(this.props.token, period, this.props.userId)
      .then(res => {
        this.setState(() => ({ isDownloaded: true }))
        setTimeout(() => {
          this.setState(() => ({
            isDownloaded: null
          }))
        }, 1000);
      })
      .catch(err => {
        this.setState(() => ({ isDownloaded: null }))
        alert('Error. Please retry.')    
      })
  }

  render () {
    return (
      <div className='download-report'>
        <Can
          action={actions.download_report}
          yes={() => (
            <DropdownButton
              className='download-report-dd'
              btnSize="lg"
              title={this.state.isDownloaded === null? 'Download Report' : this.state.isDownloaded === false? 'Downloading...' : 'Downloaded'}
              onSelect={(eventKey) => {
              }}
              noCaret={true}
            >
              <MenuItem onClick={() => {
                this.downloadButtonHandler('4Wks') 
              }}>4 Weeks</MenuItem>
              <MenuItem onClick={() => {
                this.downloadButtonHandler('8Wks') 
              }}>8 Weeks</MenuItem>
              <MenuItem onClick={() => {
                this.downloadButtonHandler('All') 
              }}>All Time</MenuItem>
          </DropdownButton>
          )}
        />
      </div>
    )
  }
}

export default DownloadReport
