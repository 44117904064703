import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'querystring'

import UserCaseList from './User-case-list'
import careActivityDetailsByUser from '../../Api/get-care-activities-by-user'
import triggerDetailsByUser from '../../Api/get-trigger-log-details-by-user'
import getCasesByUser from '../../Api/get-cases-by-user'
import getUser from '../../Api/get-user'
import getProp from './User-details-components/Utils/get'
import PatientDetails from './User-details-components/Components/Patient-details'
import CareTracker from './Care-tracker'
import TriggerTracker from '../Trigger-tracker/Trigger-tracker'
import UserStats from './User-stats'
import { getUserStats } from '../../Api/single-user/single-user'
import Tab from '../UI-helper-components/Tab'
import CareplanTab from '../UI-helper-components/Careplan-tab'
import Message from '../Message'
import ScreenHeader from '../Common/Screen-header'
import CTAButton from '../Common/Cta-button'
import Timer from "../Doctor-patient-session/Timer";
import getDoctorPatientSessionApi from "../../Api/Doctor-patient-session/get-doctor-patient-session";
import postDoctorPatientSessionApi from "../../Api/Doctor-patient-session/post-doctor-patient-session";
import SmallCard from '../Common/Small-card'
import Notes from '../Notes/Notes'
import postDoctorNote from "../../Api/Doctor-notes/post-doctor-note";
import getAllNotes from "../../Api/Doctor-notes/get-doctor-notes";
import UserSingleCaseCopy from './User-single-case-tabbed'
import { actions, check } from '../../config/roles'
import Can from '../UI-helper-components/Can'
import ReviewUserData from './Review-user-data'
import caseDetailsById from '../../Api/get-case-details-by-id'
import HistoryAndInsights from "./history-and-insights";
import {
    getCareActivitiesHistory,
    getCaseHistory,
    getTriggerHistory,
    getWeatherHistory
} from "../../Api/History-and-insights/history-and-insights-apis";
import Consultations from '../Consultations/Consultations'
import SingleConsultation from '../Consultations/SingleConsultation'
import { setCurrentSessionUserId } from '../../actions/uiActions'
import AsiCasesData from './Asi-Cases-data'
import AsiCasesDataList from './Asi-Cases-data-list'

class User extends Component {

    state = {
        activityDetails: [],
        triggerLogDetails: [],
        casesList: [],
        userDetails: {},
        userStats: {},
        statsLoaded: false,
        sessionDetails: {
            elapsed: 0,
            totalElapsed: 0,
            runningSince: null,
            isCurrentSessionPaused: false
        },
        userDemographics: {},
        patientSessionDataLoaded: false,
        doctorNotes: {},
        doctorNotesLoaded: false,
        caseDetails: null,
        caseHistory: [],
        patientAvailable: false
    }

    setSessionDetails = (sessionDetails) => {
        this.setState(() => ({
            sessionDetails: sessionDetails
        }))
    }

    mutations = {
        mainPadding: {
            apply: () => {
                const styleTag = document.createElement('style')
                styleTag.setAttribute('id', 'user-padding-mutator')
                styleTag.innerHTML = `.main-wrapper {
            padding-bottom: 0;
          }`
                document.head.appendChild(styleTag)
            },
            revert: () => {
                const styleTag = document.getElementById('user-padding-mutator')
                styleTag.parentNode.removeChild(styleTag)
            }
        }
    }
    idToGender = ['Male', 'Female', 'Others']

    componentDidMount() {
        this.loadData()
        this.mutations.mainPadding.apply()
        if (!check(this.props.state.authReducer.userRole, actions.time_tracker)) {
            return
        }
        //this.loadHistoryAndInsightsData()
    }

    componentWillUnmount() {
        this.mutations.mainPadding.revert()
    }

    queryParamValue = (paramName) => {
        try {
            if (paramName[0] === '?') {
                return qs.parse(this.props.location.search)[paramName] || qs.parse(this.props.location.search)[paramName.replace('?', '')] || 0
            }
            return qs.parse(this.props.location.search)[paramName] || 0
        } catch (error) {
            return 0
        }
    }

    getIcon = (index) => {
        if (index === 0) {
            return <i className="fa fa-book" aria-hidden="true"></i>
        } else if (index === 1) {
            return <i className="fa fa-history" aria-hidden="true"></i>
        } else if (index === 2) {
            return <i className="fa fa-user-md" aria-hidden="true"></i>
        } else if (index === 3) {
            return <i className="fa fa-comment" aria-hidden="true"></i>
        } else {
            return null
        }
    }

    loadData = async () => {
        try {
            const userDetails = await getUser(this.props.match.params.userId, this.props.state.authReducer.token)
            if (userDetails) {
                const casesList = await getCasesByUser(this.props.match.params.userId, this.props.state.authReducer.token)
                const activityDetails = await careActivityDetailsByUser(this.props.match.params.userId, this.props.state.authReducer.token)
                const triggerLogDetails = await triggerDetailsByUser(this.props.match.params.userId, this.props.state.authReducer.token)
                const singleUserStats = await getUserStats({
                    userid: this.props.match.params.userId,
                    token: this.props.state.authReducer.token
                })
                const userStats = getProp(singleUserStats, 'data.data', {})
                this.getDoctorNotes()
                if ((this.props.state.authReducer.userRole === 'DOCTOR') || (this.props.state.authReducer.userRole === 'DOCTOR_ASSISTANT')) {
                    this.getDoctorPatientSession()
                }
                this.setState(() => ({
                    activityDetails: activityDetails.data.data,
                    triggerLogDetails: triggerLogDetails.data.data,
                    casesList: casesList.data.data,
                    userDetails: Array.isArray(userDetails.data.data) && userDetails.data.data[0],
                    userEmail: userDetails.data.data[0].email,
                    userStats: userStats,
                    statsLoaded: true,
                    userDemographics: userDetails.data.data[0].userDemographics,
                    patientAvailable: true
                }))
            } else {
                this.setState(() => ({
                    patientAvailable: false
                }))
            }

        } catch (error) {
            alert('This patient is currently inactive.')
            console.log(error)
            this.props.history.push('/messages');
        }
    }

    loadHistoryAndInsightsData = async () => {
        try {
            const fromDate = '2020-11-01'
            const toDate = '2020-11-30'
            const caseHistory = await getCaseHistory(this.props.match.params.userId, this.props.state.authReducer.token, fromDate, toDate)
            const weatherHistory = await getWeatherHistory(this.props.match.params.userId, this.props.state.authReducer.token, fromDate, toDate)
            const careActivitiesHistory = await getCareActivitiesHistory(this.props.match.params.userId, this.props.state.authReducer.token, fromDate, toDate)
            const triggerDetails = await getTriggerHistory(this.props.match.params.userId, this.props.state.authReducer.token, fromDate, toDate)
            console.log('caseHistory: ', caseHistory)
            console.log('weatherHistory: ', weatherHistory)
            console.log('careActivitiesHistory: ', careActivitiesHistory)
            console.log('triggerDetails: ', triggerDetails)
            this.setState(() => ({
                caseHistory: caseHistory.data
            }))
        } catch (error) {
            console.log(error)
        }
    }

    loadCaseDetails = async (caseId) => {
        try {
            const caseDetails = await caseDetailsById(caseId, this.props.state.authReducer.token)
            this.setState(() => ({
                caseDetails: caseDetails
            }))
        } catch (error) {
            console.log(error)
        }
    }

    singleCaseTabbedUnmount = () => {
        this.setState(() => ({
            caseDetails: null
        }))
    }

    playTimer = async () => {
        await this.postDoctorPatientSession('play')
    }

    pauseTimer = async () => {
        await this.postDoctorPatientSession('pause')
    }

    resumeTimer = async () => {
        await this.postDoctorPatientSession('resume')
    }

    stopTimer = async () => {
        await this.postDoctorPatientSession('stop')
    }

    postDoctorPatientSession = async (action) => {
        this.props.dispatcher(setCurrentSessionUserId(this.props.match.params.userId));
        postDoctorPatientSessionApi(this.props.match.params.userId, this.props.state.authReducer.token, action)
            .then(res => {
                this.getDoctorPatientSession()
            })
            .catch(err => {
                console.log(err)
                alert('Error. Please retry')
            })
    }

    getDoctorPatientSession = () => {
        getDoctorPatientSessionApi(this.props.match.params.userId, this.props.state.authReducer.token)
            .then(res => {
                this.props.dispatcher(setCurrentSessionUserId(this.props.match.params.userId));
                let data = res.data && res.data.data
                this.setState(() => {
                    return {
                        sessionDetails: data,
                        patientSessionDataLoaded: true
                    }
                })
            })
            .catch(err => {
                console.log(err)
                alert('Error. Please retry')
            })
    }

    submitDoctorNote = async (type, content) => {
        postDoctorNote(this.props.state.authReducer.token, this.props.match.params.userId, type, content)
            .then(res => {
                this.getDoctorNotes()
            })
            .catch(err => {
                console.log(err)
                alert('Error. Please retry')
            })
    }

    getDoctorNotes = () => {
        if (!check(this.props.state.authReducer.userRole, actions.view_notes)) {
            return
        }
        getAllNotes(this.props.state.authReducer.token, this.props.match.params.userId)
            .then(res => {
                let data = res.data && res.data.data
                this.setState(() => {
                    return {
                        doctorNotes: data,
                        doctorNotesLoaded: true
                    }
                })
            })
            .catch(err => {
                console.log(err)
                alert('Error. Please retry')
            })
    }

    render() {
        const userDemographics = this.state.userDetails.userDemographics || {}
        const userInformation = () => {
            return (
                <div className='tab-content'>
                    <div className="tab-content-dashboard">
                        <div className="patient-info">
                            <PatientDetails
                                userId={this.props.match.params.userId}
                                token={this.props.state.authReducer.token}
                                email={this.state.userEmail}
                                patientName={userDemographics.name}
                                doctorName={userDemographics.doctorName}
                                gender={this.idToGender[userDemographics.gender] || ''}
                                DOB={userDemographics.DOB}
                                history={this.props.history}
                                setSessionDetails={this.setSessionDetails}
                            />
                            <UserStats
                                statsLoaded={this.state.statsLoaded}
                                actualCases={this.state.userStats.actualCases}
                                daysOpened={this.state.userStats.daysOpened}
                                totalCareActivities={this.state.userStats.totalCareActivities}
                                totalTriggers={this.state.userStats.totalTriggers}
                                adherenceStats={this.state.userStats.adherenceStats}
                            />
                        </div>
                        <div className="row">
                            <Can
                                action={actions.view_notes}
                                yes={() => (
                                    <div className='col-6'>
                                        <div className="doctor-notes">
                                            {this.state.doctorNotesLoaded && <Notes
                                                userId={this.props.match.params.userId}
                                                token={this.props.state.authReducer.token}
                                                submitDoctorNote={this.submitDoctorNote}
                                                getDoctorNotes={this.getDoctorNotes}
                                                doctorNotes={this.state.doctorNotes}>
                                            </Notes>}
                                        </div>
                                    </div>
                                )}
                            />
                            <Can
                                action={actions.view_consultations}
                                yes={() => (
                                    <div className='col-6'>
                                        <div className="consultations">
                                            <Consultations
                                                userId={this.props.match.params.userId}
                                                token={this.props.state.authReducer.token}
                                                history={this.props.history}
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        const reviewUserData = () => {
            return (
                <div className='tab-content'>
                    <div className="tab-content-dashboard">
                        <ReviewUserData
                            token={this.props.state.authReducer.token}
                            userid={this.props.match.params.userId}
                        />
                    </div>
                </div>
            )
        };

        const easiTabData = () => {
            return (
                <div className='tab-content'>
                    <div className="tab-content-dashboard">
                        <AsiCasesDataList
                            token={this.props.state.authReducer.token}
                            userid={this.props.match.params.userId}
                        />
                    </div>
                </div>
            )
        };

        const userCases = () => {
            return (
                <div className="tab-content">
                    <div className="tab-content-cases">
                        <UserCaseList
                            userId={this.props.match.params.userId}
                        />
                    </div>
                </div>
            )
        }

        const historyAndInsight = () => {
            return (
                <div className='tab-content' style={{
                    padding: '30px',
                    paddingBottom: 0
                }}>
                    <div className="row">
                        <div className="col-sm-12">
                            {/*<UserDetailsComponents
                                patientName={getProp(this, 'state.userDetails.userDemographics.name')}
                                userDetails={this.state.userDetails}
                                customDateRange=''
                                timeLength='all'
                                patientNam='testname'
                                careActivitiesHistory={this.state.activityDetails}
                                triggerHistory={this.state.triggerLogDetails}
                                cases={this.state.casesList}
                                includeImages={false}
                            />*/}
                            <HistoryAndInsights
                                token={this.props.state.authReducer.token}
                                userid={this.props.match.params.userId}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        const doctorSuggestion = () => {
            return (<div className='tab-content'>
                <div className='report-section'>
                    <div className="row careplan-tab">
                        <div className="col-sm-12">
                            <CareplanTab
                                navProps={{
                                    className: 'nowrap-nav nav-custom-styles'
                                }}
                                headings={['Suggest Care Activities', 'Track Triggers']}
                                components={[
                                    (
                                        <CareTracker
                                            {...this.props}
                                            heading='Suggest Care Activities'
                                            type='activities'
                                            showPresetSelector={true}
                                            originScreen='user-details'
                                            ignoreTreatmentPlan={true}
                                        />
                                    ),
                                    (
                                        <TriggerTracker
                                            disableWhiteBackground={true}
                                            heading='Track Triggers'
                                        />
                                    )
                                ]}
                                className='tab-wrapper no-max-width'
                            />
                        </div>
                    </div>
                </div>
            </div>)
        }

        const messaging = () => {
            if (!check(this.props.state.authReducer.userRole, actions.view_messages)) {
                return null
            }
            return (
                <div className='tab-content'>
                    <Message {...this.props} />
                </div>
            )
        }

        const caseDetails = () => {
            return (
                <div className='tab-content'>
                    <UserSingleCaseCopy
                        cases={this.state.casesList}
                        loadCaseDetails={this.loadCaseDetails}
                        caseDetails={this.state.caseDetails}
                        singleCaseTabbedUnmount={this.singleCaseTabbedUnmount}
                    />
                </div>
            )
        }

        const easiCaseDetails = () => {
            return (
                <div className='tab-content'>
                    <div className="tab-content-dashboard">
                        <AsiCasesData
                            token={this.props.state.authReducer.token}
                            userid={this.props.match.params.userId}
                        />
                    </div>
                </div>
            )
        }

        const consultationDetails = () => {
            return (
                <div className='tab-content'>
                    <SingleConsultation
                        token={this.props.state.authReducer.token}
                    />
                </div>
            )
        }

        const ctaOnClick = () => {
            this.props.history.push(`/invite-patient`)
        }

        const unreadMessages = this.props.state.uiReducer.unreadMessages || []
        const anyUnreadMessages = unreadMessages.map(state => state.patientId).includes(this.props.match.params.userId)
        const messagingHeading = (
            <div className='messaging-inner'>
                <span>Message</span>
                {anyUnreadMessages && <span className='message-count-tab'>1</span>}
            </div>
        )

        let tabHeadings = ['Dashboard', 'IGA Cases', 'History & Insight', 'Care Plan', 'EASI Analyzer', messagingHeading, 'Single Case'];
        if (!check(this.props.state.authReducer.userRole, actions.view_messages)) {
            tabHeadings = ['Dashboard', 'IGA Cases', 'History & Insight', 'Care Plan', 'EASI Analyzer', 'Single Case']        
        }
        let tabComponents = [
            userInformation,
            userCases,
            historyAndInsight,
            doctorSuggestion,
            easiTabData,
            messaging,
            reviewUserData,
            caseDetails,
            easiCaseDetails,
            consultationDetails
        ]
        if (!check(this.props.state.authReducer.userRole, actions.view_messages)) {
            tabComponents = [
                userInformation,
                userCases,
                historyAndInsight,
                doctorSuggestion,
                easiTabData,                
                () => null,
                caseDetails,
                easiCaseDetails
            ]
        }

        // Check if the logged-in user is a STUDY_USER
        const isStudyUser = this.props.state.authReducer.userRole === 'STUDY' || this.props.state.authReducer.userRole === 'STUDY_USER';

        // Add Survey Data tab if the user is a STUDY_USER
        if (isStudyUser) {
            tabHeadings.splice(5, 0, 'Survey Data');
            tabComponents.splice(5, 0, reviewUserData);
        }

        return (
            <div className='user-area-component'>
                <ScreenHeader heading='' button={(
                    <Can
                        action={actions.create_patient}
                        yes={() => (
                            <CTAButton
                                text='Add New Patient'
                                onClick={this.ctaOnClick}
                                icon={(
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                )}
                            />
                        )}
                    />
                )} />

                {this.state.patientAvailable && <>
                    <div className="flex-parent flex-parent-row">
                        <div>
                            <SmallCard className='patient-details-card'>
                                <div className="card-patient-details-header">
                                    Patient Details:
                                </div>
                                <div className="card-patient-details-info">
                                    {getProp(this.state, 'userDemographics.name', '')}
                                </div>
                            </SmallCard>
                        </div>
                        <div>
                            {this.state.patientSessionDataLoaded && <Timer
                                {...this.state.sessionDetails}
                                playTimer={this.playTimer}
                                pauseTimer={this.pauseTimer}
                                resumeTimer={this.resumeTimer}
                                stopTimer={this.stopTimer}
                                isRunning={this.state.isRunning}
                                isPaused={this.state.isPaused}
                            />}
                        </div>
                    </div>
                    <div className='tab-wrapper-outer' style={{
                        marginTop: '15px'
                    }}>
                        <Tab
                            history={this.props.history}
                            className='user-details-tab-items-wrapper'
                            currTab={Number(this.queryParamValue('?currTab'))}
                            navProps={{
                                className: 'user-details-tab'
                            }}
                            navLinkClass='user-details-tab-item'
                            renderTab={(heading, index, isActive) => {
                                if (index === 6 || index === 7) {
                                    return null
                                }
                                return heading
                            }}
                            components={tabComponents}
                            headings={tabHeadings}
                        />
                    </div></>}

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatcher: (action) => {
            dispatch(action)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User))