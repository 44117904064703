import React from 'react'

const EffectivenessChartTabs = (props) => {
    return (
        <div>
            <div className="chart-tabs">
                {props.btnList.map(btn => (
                    <button
                        key={btn.id}
                        className={btn.id == props.activeCaseFilterId ? 'chart-navigation-tab active' : 'chart-navigation-tab'}
                        value={btn.id}
                        onClick={() => {
                            props.handleButtonClick(btn.id)
                        }}
                    >
                        {btn.title}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default EffectivenessChartTabs