import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  completeConsultation,
  getConsultation,
} from "../../Api/Consultations/apis";
import getCaseDetailsById from "../../Api/get-case-details-by-id";
import { getMessagePresets } from "../../Api/message-presets";
import {
  removeConsultationNote,
  removeConsultationPrescription,
  setConsultationNote,
  setConsultationPrescription,
} from "../../actions/uiActions";
import loadingGif from "../../assets/loading.gif";
import endpoints from "../../config/endpoints";
import { tokenSelector } from "../../selectors/auth-selectors";
import { getParamValue } from "../../utilityFunctions/common";
import imageIdGenerator from "../../utilityFunctions/image-id-generator";
import IGABatch from "../Common/IGA-batch";
import ItchBadge from "../Common/Itch-batch";
import ReactAuthImageLightbox from "../UI-helper-components/react-auth-image-lightbox";
import getProp from "../User/User-details-components/Utils/get";
import ActivitiesPresetSelector from "./ActivitiesPresetSelector";
import CurrentCareRoutine from "./CurrentCareRoutine";
import "./SingleConsultation.scss";
import SuggestedCareRoutine from "./SuggesetdCareRoutine";

const getItchBadge = (obj) => {
  return <ItchBadge {...obj} />;
};

const getNoteSelector = (consultationId) => {
  return (state) => {
    const note = getProp(
      state,
      `uiReducer.consultationNotes.${consultationId}`,
      {}
    );
    if (!note) return {};
    return note;
  };
};

const getPrescriptionSelector = (consultationId) => {
  return (state) => {
    const prescription = getProp(
      state,
      `uiReducer.consultationPrescriptions.${consultationId}`,
      {}
    );
    if (!prescription) return {};
    return prescription;
  };
};

const SingleConsultation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [doctorNote, setDoctorNote] = useState(null)
  // const [prescription, setPrescription] = useState(null)
  const [consultation, setConsultation] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [theCase, setTheCase] = useState(null);
  const token = useSelector(tokenSelector);
  const { userId } = useParams();
  const consultationId = getParamValue("consultationId");
  const noteSelector = getNoteSelector(consultationId);
  const prescriptionSelector = getPrescriptionSelector(consultationId);
  const prescription = useSelector(prescriptionSelector).text;
  const doctorNote = useSelector(noteSelector).text;
  const [msgPresets, setMsgPresets] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState("");
  // key used for reloading a component
  const [suggestedCareRoutineKey, setSuggestedCareRoutineKey] = useState(0);
  const setDoctorNote = (text) => {
    dispatch(
      setConsultationNote({
        consultationId,
        text,
      })
    );
  };
  const setPrescription = (text) => {
    dispatch(
      setConsultationPrescription({
        consultationId,
        text,
      })
    );
  };
  const getInputSetter = (setterFunc) => {
    return (e) => {
      const value = e.target.value;
      setterFunc(value);
    };
  };
  const loadData = () => {
    getConsultation({
      token,
      consultationId,
    }).then((response) => {
      setConsultation(response.data.data);
      getCaseDetailsById(response.data.data.caseId, token).then((res) => {
        setTheCase(res.data.data);
      });
      getMessagePresets({ token }).then((response) => {
        setMsgPresets(response.data.data);
      });
    });
  };
  useEffect(loadData, []);
  if (!consultation || !theCase) {
    return <div className="container">Loading...</div>;
  }
  return (
    <div
      style={{
        maxWidth: "1100px",
      }}
    >
      <div className="container-fluid consultation-container">
        <div className="row">
          <h4 className="consultation-screen-heading">Patient Consultation</h4>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setSubmitting(true);
            try {
              await completeConsultation({
                token: token,
                consultationId: consultationId,
                doctorNote,
                prescription,
              });
              dispatch(removeConsultationNote({ consultationId }));
              dispatch(removeConsultationPrescription({ consultationId }));
              window.alert("Successfully submitted!");
              history.push(`${window.location.pathname}?currTab=0`);
            } catch (error) {
              setSubmitting(false);
              console.log(error);
            }
          }}
        >
          <div className="row">
            {/* LHS starts */}
            <div className="col-6">
              <h6 className="patient-name">{consultation.patientName}</h6>
              {/* patient info table */}
              <table className="table">
                <colgroup>
                  <col
                    style={{
                      width: "30%",
                    }}
                  />
                  <col
                    style={{
                      width: "70%",
                    }}
                  />
                </colgroup>
                <tr className="info-row">
                  <td className="info-row-key">Gender</td>
                  <td className="info-row-value">
                    {consultation.patientGender}
                  </td>
                </tr>
                <tr className="info-row">
                  <td className="info-row-key">Date of Birth</td>
                  <td className="info-row-value">
                    {moment(consultation.patientDOB).format("ll")}
                  </td>
                </tr>
                <tr className="info-row">
                  <td className="info-row-key">Phone</td>
                  <td className="info-row-value">
                    {consultation.patientPhone}
                  </td>
                </tr>
                <tr className="info-row">
                  <td className="info-row-key">Severity</td>
                  <td className="info-row-value">
                    <IGABatch
                      value={getProp(theCase, "finalReplyJson.value")}
                    />
                  </td>
                </tr>
                <tr className="info-row">
                  <td className="info-row-key">Itch</td>
                  <td className="info-row-value">
                    {getItchBadge(getProp(theCase, "discomfort.itch", {}))}
                  </td>
                </tr>
                <tr className="info-row">
                  <td className="info-row-key">Allergies</td>
                  <td className="info-row-value">
                    {consultation.patientAllergies}
                  </td>
                </tr>
                <tr className="info-row">
                  <td className="info-row-key">Status</td>
                  <td className="info-row-value">
                    {consultation.isComplete ? "Complete" : "Incomplete"}
                  </td>
                </tr>
                <tr className="info-row">
                  <td className="info-row-key">Message</td>
                  <td className="info-row-value">
                    {consultation.patientMessage}
                  </td>
                </tr>
                {/* pharmacy info table */}
                {consultation.needPrescriptionMedications && (
                  <>
                    <tr>
                      <h6
                        className="table-heading"
                        style={{ marginTop: "15px" }}
                      >
                        Pharmacy Info
                      </h6>
                    </tr>
                    <tr className="info-row">
                      <td className="info-row-key">Pharmacy Name</td>
                      <td className="info-row-value">
                        {consultation.pharmacyName}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td className="info-row-key">Pharmacy Address</td>
                      <td className="info-row-value">
                        {consultation.pharmacyAddress}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td className="info-row-key">Subscriber Name</td>
                      <td className="info-row-value">
                        {consultation.insuranceSubscriberName}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td className="info-row-key">Insurance Co Name</td>
                      <td className="info-row-value">
                        {consultation.insuranceCoName}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td className="info-row-key">ID Number</td>
                      <td className="info-row-value">
                        {consultation.insuranceIdNumber}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td className="info-row-key">Group Number</td>
                      <td className="info-row-value">
                        {consultation.insuranceGroupNumber}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td className="info-row-key">
                        Primary Insurance Phone No
                      </td>
                      <td className="info-row-value">
                        {consultation.primaryInsurancePhoneNo}
                      </td>
                    </tr>
                  </>
                )}
              </table>
              <div className="lhs-section">
                <h6 className="lhs-section-heading">Current Care Routine</h6>
                <CurrentCareRoutine
                  token={token}
                  userId={userId}
                  showSnapshot={consultation.activitiesSuggested}
                  snapshotActivities={
                    consultation.careActivitiesTrackedSnapshot
                  }
                />
              </div>
              <div className="lhs-section">
                <h6 className="lhs-section-heading">Suggested Care Routine</h6>
                {!consultation.isComplete && (
                  <Link
                    className="setup-care-routine-link"
                    to={`${window.location.pathname}?currTab=3`}
                  >
                    Set up now{" "}
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </Link>
                )}
                <ActivitiesPresetSelector
                  disabled={consultation.isComplete}
                  userid={userId}
                  afterSubmit={() => {
                    setSuggestedCareRoutineKey(Math.random());
                  }}
                />
                <div key={suggestedCareRoutineKey}>
                  <SuggestedCareRoutine
                    disabled={
                      consultation.activitiesSuggested &&
                      consultation.isComplete
                    }
                    token={token}
                    userId={userId}
                    showSnapshot={consultation.activitiesSuggested}
                    snapshotActivities={
                      consultation.careActivitiesTrackedAfterSnapshot
                    }
                  />
                </div>
              </div>
              <div className="submit-button-holder">
                {!consultation.isComplete && (
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value={submitting ? "Submitting..." : "Submit"}
                  />
                )}
              </div>
            </div>
            {/* RHS start s */}
            <div className="col-6">
              {/* images */}
              {theCase && (
                <div className="consultation-case-images">
                  {theCase.imagePath.map((imagePath) => {
                    return (
                      <ReactAuthImageLightbox
                        imageTagProps={{
                          style: {
                            objectFit: "cover",
                            width: "220px",
                            height: "220px",
                            borderRadius: "4px",
                            marginRight: "5px",
                            marginBottom: "10px",
                            cursor: "pointer",
                          },
                        }}
                        key={imagePath}
                        targetUrl={endpoints.GET_IMAGE}
                        headers={{
                          imageId: imageIdGenerator(imagePath.path),
                          token: token,
                          caseId: theCase.id,
                        }}
                        defaultImage={loadingGif}
                      />
                    );
                  })}
                </div>
              )}
              {/* RHS Notes */}
              <div className="notes-section" style={{ marginTop: "15px" }}>
                <div className="form-group">
                  <h6 className="input-heading">Notes:</h6>
                  <div className="select-template-box">
                    <div className="select-template-text">Select Template</div>
                    {msgPresets && (
                      <select
                        disabled={consultation.isComplete}
                        value={selectedPreset}
                        onChange={(e) => {
                          const presetId = e.target.value;
                          setSelectedPreset(presetId);
                          const targetPreset = msgPresets.find(
                            (preset) => preset.id === presetId
                          );
                          setDoctorNote(targetPreset.presetBody);
                        }}
                        className="form-control"
                        id="gender"
                      >
                        <option disabled value="">
                          Select a preset
                        </option>
                        {msgPresets.map((preset) => {
                          return (
                            <option value={preset.id}>
                              {preset.presetName}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                  <textarea
                    rows={7}
                    disabled={consultation.isComplete}
                    onChange={getInputSetter(setDoctorNote)}
                    value={
                      consultation.isComplete
                        ? consultation.doctorNote
                        : doctorNote
                    }
                    className="form-control notes-input"
                    id="notes"
                  />
                </div>
              </div>
              {/* RHS Prescriptions */}
              <div
                className="prescription-section"
                style={{ marginTop: "15px" }}
              >
                <h6 className="input-heading">Prescription:</h6>
                <div className="form-group">
                  <textarea
                    required
                    rows={7}
                    disabled={consultation.isComplete}
                    onChange={getInputSetter(setPrescription)}
                    value={
                      consultation.isComplete
                        ? consultation.prescription
                        : prescription
                    }
                    className="form-control notes-input"
                    id="prescription"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SingleConsultation;