import React from 'react'
import { DayGroupProps } from './interfaces'

const DayGroup = (props: DayGroupProps) => {
  return (
    <div className='notes-day-group'>
      <div className="day-group-title">
        {props.date}
      </div>
      <div className="day-group-content">
        {props.children}
      </div>
    </div>
  )
}

export default DayGroup
